import React, { useState, useEffect, useRef } from 'react';
import { countries, languages } from '../config/AppModes.ts';
import { Box, Select, MenuItem, FormControl } from '@mui/material';
import { useAuthRedirect } from '../components/AuthContext';  // Import useAuth from AuthContext
import { List, ListItem, ListItemIcon, TextField, InputLabel } from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import HelpIcon from '@mui/icons-material/Help';

import TranslateIcon from '@mui/icons-material/Translate';
import LanguageIcon from '@mui/icons-material/Language';
import ConsultantIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import { useAuth } from '../components/AuthContext'; // Import useAuth hook
import { useAppContext } from '../components/AppContext.js'; // Import useAppContext hook
import { useTranslation } from 'react-i18next';

export function SettingsScreen({ setDirection, useDelayedNavigation }) {
    useAuthRedirect(setDirection, useDelayedNavigation);

    const { t, i18n } = useTranslation();
    const { userProfile } = useAuth();

    const [country, setCountry] = useState(localStorage.getItem('currentCountry') || i18n.country);
    const [language, setLanguage] = useState(i18n.language);
    
    const handleCountryChange = (event) => {
        setCountry(event.target.value);
    };

    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };

    useEffect(() => {
        localStorage.setItem('currentCountry', country);
    }, [country]);
    
    useEffect(
        () => {
            i18n.changeLanguage(
                language
            );
        }
        , [language]
    );

    return (
        <Box sx={{
            pt: 4, pb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center',
            height: '100svh', width: '100%',
            background: 'rgba(255, 255, 255, 0.7)',
        }}>

            <Box sx={{
                display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', mt: 0,
            }}>

                <Box
                    sx={{
                        width: '90%',
                        borderRadius: '20px 20px 0 0',
                        textAlign: 'center',
                        padding: 2,
                        position: 'relative',
                    }}
                >
                    <FormControl variant="standard" sx={{ m: 0, minWidth: 120, width: '100%' }}>
                        <List component="nav" sx={{ mt: 1 }}>
                            
                        <ListItem>
                                <ListItemIcon>
                                    <LanguageIcon />
                                </ListItemIcon>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel>{t('settings.userCountry')}</InputLabel>
                                    <Select
                                        label={t('settings.country')}
                                        value={country}
                                        onChange={handleCountryChange}
                                        displayEmpty
                                        fullWidth
                                        required
                                        inputProps={{ 'aria-label': 'Country' }}
                                        style={{ marginLeft: 'auto' }}
                                    >
                                        {/* Пустой пункт выбора страны */}
                                        <MenuItem value="" disabled>
                                            {t('settings.userCountry')}
                                        </MenuItem>

                                        {/* Сначала собираем и сортируем страны */}
                                        {Object.keys(countries)
                                            .map((key) => ({
                                                code: countries[key], 
                                                name: t(`countries.${countries[key]}`)
                                            }))
                                            .sort((a, b) => a.name.localeCompare(b.name)) // Сортируем по названию страны
                                            .map(({ code, name }) => (
                                                <MenuItem key={code} value={code}>
                                                    {name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <TranslateIcon />
                                </ListItemIcon>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel>{t('settings.userLanguage')}</InputLabel>
                                    <Select
                                        label={t('settings.language')}
                                        value={language}
                                        onChange={handleLanguageChange}
                                        displayEmpty
                                        fullWidth
                                        required
                                        inputProps={{ 'aria-label': 'Language' }}
                                        style={{ marginLeft: 'auto' }}
                                    >
                                        {/* Пустой пункт выбора языка */}
                                        <MenuItem value="" disabled>
                                            {t('settings.userLanguage')}
                                        </MenuItem>

                                        {/* Сначала собираем и сортируем языки */}
                                        {Object.keys(languages)
                                            .map((key) => ({
                                                code: languages[key], 
                                                name: t(`languages.${languages[key]}`)
                                            }))
                                            .sort((a, b) => a.name.localeCompare(b.name)) // Сортируем по названию языка
                                            .map(({ code, name }) => (
                                                <MenuItem key={code} value={code}>
                                                    {name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <EmailIcon />
                                </ListItemIcon>
                                {/* email input here */}
                                <TextField
                                    variant="standard"
                                    margin="dense"
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    autoComplete="email"
                                    value={userProfile?.email}
                                    disabled
                                />
                            </ListItem>

                        </List>
                    </FormControl>

                </Box>

            </Box>

        </Box>

    );
}

export default SettingsScreen;