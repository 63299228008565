import React, { useEffect, useState, useRef } from 'react';
import { Box, Grid } from '@mui/material';
import { useAuthRedirect } from '../components/AuthContext.js';  // Import useAuth from AuthContext
import { useAuth } from '../components/AuthContext.js'; // Import useAuth hook
import { useAppContext } from '../components/AppContext.js';
import { ProfileCard } from '../components/ProfileCard.tsx';

import api from '../classes/API.js';

export function SetProfileContextScreen({ setDirection, useDelayedNavigation }) {
    useAuthRedirect(setDirection, useDelayedNavigation);
    const setDelayedNavigation = useDelayedNavigation();

    const { setCurrentProfileId } = useAppContext();
    const { tokenVerificationCompleted } = useAuth();
    const [profiles, setProfiles] = useState([]);
    const isLoaded = useRef(false);

    useEffect(() => {
        // Load all profiles
        if (!tokenVerificationCompleted) return;
        if (isLoaded.current) return;
        isLoaded.current = true;
        api
            .allProfiles()
            .then(p => setProfiles(p));
    }, [
        tokenVerificationCompleted
    ]);

    const handleCardClick = (profile) => {
        // Handle card click event
        setCurrentProfileId(profile.id);
        setDelayedNavigation(() => setDirection('backward'), -1);
    };

    return (
        <Box sx={{
            pt: 0
            , pb: 10
            , display: 'flex'
            , flexDirection: 'column'
            , alignItems: 'center'
            , width: '100%'
            , backgroundColor: 'rgba(255, 255, 255, 0.7)'
            , height: '100svh'
        }}>
            <Box sx={{
                pt: 2
                , display: 'flex'
                , flexDirection: 'column'
                , alignItems: 'center'
                , width: '100%'
            }}>
                <Grid container spacing={3} sx={{
                    px: 1,
                    width: '100%'
                }}>
                    {profiles.map((profile, index) => {
                        return (
                            <Grid item xs={6} md={4} key={index}>
                                <ProfileCard
                                    profile={profile}
                                    handleCardClick={handleCardClick}
                                    ind={index}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        </Box >
    );
}

export default SetProfileContextScreen;
