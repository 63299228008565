import { officeTypes, chatModes, genderTypes } from '../../config/AppModes.ts';

export const de = {
    "send_email_code": "E-Mail an {{email}} wird mit dem Code: {{code}} gesendet",
    "token_valid": "Token ist gültig",
    "token_invalid": "Token ist ungültig",
    "verification_error": "Fehler bei der Token-Überprüfung:",
    "verification_completed": "Überprüfung abgeschlossen. Authentifiziert: ",
    "welcome": {
        "title": "Wixee"
    },
    "splash1": {
        "title": "Intelligente Gesundheit beginnt hier",
        "description": "Stärken Sie das Wohlbefinden Ihrer Familie mit personalisierten Gesundheitsratschlägen einer KI, die auf die individuellen Bedürfnisse jedes Familienmitglieds zugeschnitten sind."
    },
    "splash2": {
        "title": "Gesundheitseinblicke für alle",
        "description": "Entdecken Sie umfassende Gesundheitseinblicke für alle Altersgruppen, um sicherzustellen, dass Ihre Liebsten gesund und glücklich bleiben."
    },
    "splash3": {
        "title": "Ihre Gesundheit, unsere Priorität",
        "description": "Erleben Sie die Zukunft der Familiengesundheitsversorgung mit KI-gestützten Tipps und Empfehlungen, die darauf ausgelegt sind, Sie alle in optimaler Gesundheit zu halten."
    },
    "splash4": {
        "title": "Lassen Sie uns anfangen!",
        "description": "Melden Sie sich an, um die von uns bereitgestellten Funktionen zu genießen, und bleiben Sie gesund!",
        "signInButton": "Anmelden"
    },
    "home": {
        "title": "Startseite",
        "consultations": "Beratungen",
        "consultationsDescription": "Klicken Sie hier, um Ihre Beratungen anzusehen",
        "documents": "Dokumente",
        "documentsDescription": "Klicken Sie hier, um Ihre Dokumente hochzuladen",
        "notifications": "Benachrichtigungen",
        "notificationsDescription": "Klicken Sie hier, um alle Ereignisse anzuzeigen",
        "offices": "Dienste",
        "officesDescription": "Wählen Sie einen Dienst, um eine Leistung zu erhalten",
        "settings": "Einstellungen",
        "settingsDescription": "Sprache ändern, etc.",
        "cardTitle": "Genießen Sie Ihren Tag heute",
        "cardDescription": "Ihre Gesundheitsbedingungen und die Sonnenaktivität deuten darauf hin, dass Sie möglicherweise kognitive Beeinträchtigungen haben könnten. Entspannen Sie sich und genießen Sie den Tag",
        "newConsultation": "Neue Beratung",
        "adTitle": "Eine Anzeige aus der App",
        "learnMore": "Mehr erfahren"
    },
    "calendar": {
        "title": "Benachrichtigungen",
        "created": "Erstellt",
        "source": {
            "consultation": "von der Beratung",
            "document": "vom Dokument"
        }
    },
    "documents": {
        "title": "Meine Dokumente",
        "latest": "Neueste",
        "lastMonth": "Letzter Monat",
        "older": "Älter",
        "upload": "Neues Dokument hochladen",
        "deleted": "Gelöscht"
    },
    "document": {
        "title": "Dokument",
    },
    "header": {
        "youAndYourBeloved": "Sie und Ihre Liebsten"
    },
    "settings": {
        "title": "Einstellungen",
        "language": "Sprache",
        "country": "Land",
        "userCountry": "Mein Land",
        "userLanguage": "Meine Sprache",
        "userEmail": "Meine E-Mail",
        "emailAddress": "E-Mail-Adresse"
    },
    "consultation": {
        "title": "Beratung",
        "description": "Beratungsbeschreibung",
        "selectConsultant": "Berater auswählen",
        "manageConsultation": "Beratung verwalten",
        "consultationDeleted": "Beratung gelöscht am {{date}}",
        "download": "Herunterladen",
        "message": "Nachricht",
        "join": {
            [genderTypes.MALE]: "tritt der Unterhaltung bei",
            [genderTypes.FEMALE]: "tritt der Unterhaltung bei",
            [genderTypes.OTHER]: "tritt der Unterhaltung bei",
        },
    },
    "consultations": {
        "title": "Meine Beratungen",
        "description": "Beratungsbeschreibung",
        "latest": "Neueste",
        "seeAll": "Alle ansehen",
        "lastMonth": "Letzter Monat",
        "older": "Älter",
        "deleted": "Gelöscht",
        "about": "über",
    },
    "profiles": {
        "title": "Meine Liebsten",
    },
    "profile": {
        "title": "Mein Profil",
        "billing": "Mein Tarif",
        "offices": "Meine Dienste",
        "settings": "Einstellungen",
        "general": "Allgemein",
        "create": "Erstellen",
        "logout": "Abmelden",
        "addProfile": "Neues Mitglied hinzufügen",
        "hideStoredData": "Gespeicherte Daten ausblenden",
        "showStoredData": "Gespeicherte Daten anzeigen",
        "officeNoSummary": "Keine Zusammenfassung verfügbar",
        "termsConditions": "AGB",
        "clickToChange": "Zum Ändern klicken",
        "name": "Name",
        "comment": "Kommentar",
        "dateOfBirth": "Geburtsdatum",
        "timeOfBirth": "Geburtszeit (lokal)",
        "placeOfBirth": "Geburtsort",
        "height": "Größe (cm)",
        "weight": "Gewicht (kg)",
        "cm": "cm",
        "kg": "kg"
    },
    "billing": {
        "title": "Abrechnung",
        "currentBalance": "Aktuelles Guthaben",
        "subscriptionPlan": "Abonnementplan",
        "subscription": {
            "free": "Kostenlos",
            "premium": "Premium",
            "exclusive": {
                "title": "Exklusiv",
                "description": "Ein exklusiver Abonnementplan bietet Mitgliedern Premium-Zugang zu einzigartigen Inhalten, speziellen Funktionen und personalisierten Diensten, die normalen Nutzern nicht zur Verfügung stehen."
            }
        },
        "topUp": "Aufladen",
        "changePlan": "Plan ändern"
    },
    "offices": {
        "title": "Dienste",
        [officeTypes.HEALTH]: {
            "name": "Gesundheit",
            "description": "Ein KI-gestützter Gesundheitsdiagnose-Service, der die vom Benutzer bereitgestellten Symptome und die Krankengeschichte analysiert, um erste Einschätzungen zu Gesundheitszuständen vorzunehmen. Er bietet personalisierte Einblicke und Empfehlungen und leitet die Benutzer zu den nächsten Schritten in der medizinischen Versorgung, einschließlich wann professionelle Hilfe gesucht werden sollte.",
            "disclaimer": "Dieser KI-gestützte Service dient nur zu Informationszwecken und ersetzt keine medizinischen Werkzeuge oder Dienstleistungen. Er bietet keine medizinischen Diagnosen, Behandlungen oder Verschreibungen. Alle gesundheitsbezogenen Entscheidungen sollten in Absprache mit einem lizenzierten Gesundheitsdienstleister getroffen werden. Die Entwickler und Eigentümer dieser Anwendung sind nicht verantwortlich für gesundheitliche Ergebnisse, die auf der Nutzung dieses Tools basieren. Im Falle eines medizinischen Notfalls rufen Sie bitte den Notruf 112 oder Ihren örtlichen Notdienst."
        },
        [officeTypes.ALTMED]: {
            "name": "Alternative Medizin",
            "description": "Ein KI-gestützter Service für alternative Medizin, der eine Reihe von nicht-traditionellen Behandlungen wie Akupunktur, Kräutermedizin und Homöopathie anbietet. Mithilfe fortschrittlicher Algorithmen bietet er personalisierte Empfehlungen basierend auf den Prinzipien alternativer Heilpraktiken. Der Service zielt darauf ab, konventionelle medizinische Behandlungen durch ganzheitliche Einblicke in die Gesundheit und das Wohlbefinden des Benutzers zu ergänzen.",
            "disclaimer": "Dieser KI-gestützte Service für alternative Medizin ist nur zu allgemeinen Wellness- und Informationszwecken bestimmt. Er ersetzt keine professionelle medizinische Diagnose oder Behandlung. Wenden Sie sich immer an einen qualifizierten Gesundheitsdienstleister, bevor Sie gesundheitliche Entscheidungen treffen. Die Entwickler sind nicht verantwortlich für gesundheitliche Ergebnisse, die aus der Nutzung dieses Dienstes resultieren."
        },
        [officeTypes.PSYCHO]: {
            "name": "Psychologie",
            "description": "Ein KI-gestützter psychologischer Beratungsdienst, der Unterstützung in der psychischen Gesundheit bietet, indem er die von Benutzern gemeldeten Emotionen, Gedanken und Verhaltensweisen analysiert. Er bietet personalisierte Ratschläge, Bewältigungsstrategien und Selbstverbesserungstechniken, um Benutzern zu helfen, Stress, Angstzustände und andere psychologische Herausforderungen zu bewältigen. Dieser Dienst ist darauf ausgelegt, die traditionelle Therapie zu ergänzen, indem er sofortige und zugängliche Unterstützung bietet.",
            "disclaimer": "Dieser KI-gestützte psychologische Beratungsdienst dient der allgemeinen Unterstützung der psychischen Gesundheit und ersetzt keine professionelle psychologische oder psychiatrische Beratung, Diagnose oder Behandlung. Für eine genaue psychische Gesundheitsversorgung konsultieren Sie immer einen lizenzierten Psychologen. Wenn Sie sich in einer Krise oder einem Notfall befinden, wenden Sie sich an Ihren lokalen Notdienst oder die Notruf-Hotline für psychische Gesundheit. Die Entwickler und Eigentümer dieser Anwendung übernehmen keine Verantwortung für Ergebnisse, die auf der Nutzung dieses Dienstes basieren."
        },
        [officeTypes.VET]: {
            "name": "Haustiere",
            "description": "Ein KI-gestützter tierärztlicher Dienst, der Tierbesitzern hilft, die Gesundheitszustände ihrer Tiere zu verstehen, indem er die vom Benutzer bereitgestellten Symptome und die Krankengeschichte analysiert. Er bietet erste Einblicke in mögliche Gesundheitsprobleme und Empfehlungen für die nächsten Schritte, wie die Vereinbarung eines Tierarztbesuchs oder die Pflege zu Hause.",
            "disclaimer": "Dieser KI-gestützte tierärztliche Dienst bietet erste Einblicke in die Gesundheit Ihres Haustieres basierend auf den von Ihnen bereitgestellten Informationen. Er ersetzt keine professionelle tierärztliche Versorgung, Diagnose oder Behandlung. Für genaue Diagnosen und Behandlungspläne konsultieren Sie immer einen lizenzierten Tierarzt. Im Falle eines dringenden oder Notfalls mit Ihrem Haustier wenden Sie sich an den örtlichen tierärztlichen Notdienst. Die Entwickler und Eigentümer dieser Anwendung sind nicht haftbar für gesundheitliche Ergebnisse Ihres Haustieres, die auf der Nutzung dieses Tools basieren."
        }
    },
    "consultants": {
        "title": "Berater",
        [chatModes.CHAT_HEALTH_RECEPTIONIST]: {
            "name": "KI-Rezeptionist",
            "description": "Hilft Ihnen, den richtigen Spezialisten auszuwählen"
        },
        [chatModes.CHAT_HEALTH_ALLERGIST]: {
            "name": "KI-Allergologe",
            "description": "Spezialist für die Diagnose und Behandlung von Allergien und verwandten Erkrankungen"
        },
        [chatModes.CHAT_HEALTH_CARDIOLOGIST]: {
            "name": "KI-Kardiologe",
            "description": "Spezialist für Herzgesundheit, Diagnose und Behandlung von Herzerkrankungen"
        },
        [chatModes.CHAT_HEALTH_PEDIATRICIAN]: {
            "name": "KI-Kinderarzt",
            "description": "Spezialist für die Gesundheit von Kindern, medizinische Betreuung für Babys und Kinder"
        },
        [chatModes.CHAT_VET_RECEPTIONIST]: {
            "name": "KI-Tierarzt-Rezeptionist",
            "description": "Hilft Ihnen, den richtigen tierärztlichen Spezialisten auszuwählen"
        },
        [chatModes.CHAT_VET_DENTIST]: {
            "name": "KI-Tierarzt-Zahnarzt",
            "description": "Spezialist für Zahnpflege und Mundgesundheit bei Tieren"
        },
        [chatModes.CHAT_HOUSE_FENGSHUI]: {
            "name": "KI-Feng-Shui-Berater",
            "description": "Spezialist für Feng Shui, bietet Beratung, wie Sie Ihren Raum harmonisieren können, um den Energiefluss zu verbessern"
        }
    },
    "consultationDetails": {
        "title": "Beratungsdetails",
        "deleteConfirmation": "Sind Sie sicher, dass Sie diese Beratung löschen möchten?",
        "deleteConsultation": "Löschen",
        "resumeConsultation": "Fortsetzen",
        "restoreConsultation": "Wiederherstellen",
        "restoreConfirmation": "Sind Sie sicher, dass Sie diese Beratung wiederherstellen möchten?"
    },
    "documentDetails": {
        "title": "Dokumentdetails",
        "manage": "Dokument verwalten",
        "download": "Herunterladen"
    },
    "documentManage": {
        "title": "Dokument verwalten",
        "share": "Teilen",
        "delete": "Löschen",
        "restore": "Wiederherstellen",
        "rename": "Umbenennen",
        "changeProfile": "Zugehörige Person ändern",
        "download": "Herunterladen",
        "documentDeleted": "Dokument gelöscht am {{date}}",
        "deleteConfirmation": "Sind Sie sicher, dass Sie dieses Dokument löschen möchten?",
        "restoreConfirmation": "Sind Sie sicher, dass Sie dieses Dokument wiederherstellen möchten?"
    },
    "deleteConfirmation": {
        "title": "Möchten Sie diese Beratung wirklich löschen?",
        "cancel": "Abbrechen",
        "delete": "Löschen"
    },
    "restoreConfirmation": {
        "title": "Möchten Sie diese Beratung wiederherstellen?",
        "cancel": "Abbrechen",
        "restore": "Wiederherstellen"
    },
    "uploadOptions": {
        "takePhoto": "Foto machen",
        "photoGallery": "Fotogalerie",
        "uploadFile": "Dokument hochladen"
    },
    "languages": {
        "af": "Afrikaans",
        "ar": "Arabisch",
        "az": "Aserbaidschanisch",
        "be": "Weißrussisch",
        "bg": "Bulgarisch",
        "bn": "Bengalisch",
        "bs": "Bosnisch",
        "ca": "Katalanisch",
        "cs": "Tschechisch",
        "da": "Dänisch",
        "de": "Deutsch",
        "de-AT": "Deutsch (Österreich)",
        "el": "Griechisch",
        "en": "Englisch",
        "en-GB": "Englisch (Großbritannien)",
        "en-US": "Englisch (USA)",
        "es": "Spanisch",
        "et": "Estnisch",
        "fa-IR": "Persisch (Iran)",
        "fi": "Finnisch",
        "fr": "Französisch",
        "fr-CA": "Französisch (Kanada)",
        "fr-CH": "Französisch (Schweiz)",
        "he": "Hebräisch",
        "hi": "Hindi",
        "hu": "Ungarisch",
        "is": "Isländisch",
        "it": "Italienisch",
        "ja": "Japanisch",
        "ko": "Koreanisch",
        "nl": "Niederländisch",
        "pl": "Polnisch",
        "pt": "Portugiesisch",
        "pt-BR": "Portugiesisch (Brasilien)",
        "ro": "Rumänisch",
        "ru": "Russisch",
        "sv": "Schwedisch",
        "tr": "Türkisch",
        "uk": "Ukrainisch",
        "vi": "Vietnamesisch",
        "zh-CN": "Chinesisch (vereinfacht)",
        "zh-TW": "Chinesisch (traditionell)"
    }
};
