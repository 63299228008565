import React, { useEffect, useState } from 'react';
import ReactMarkdown from "react-markdown";
import { Box, Typography, IconButton, Fade, Card, CardContent, Grid, Button, Dialog, DialogContent } from '@mui/material';
import Avatar from '@mui/material/Avatar';
//import DeleteIcon from '@mui/icons-material/Delete';
import { ReactComponent as DeleteIcon } from '../img/icon-delete.svg'
import '../assets/markdown.css';
import { useAppContext } from '../components/AppContext.js';
import { useTranslation } from 'react-i18next';
import { chatModes, genderTypes } from '../config/AppModes.ts';
import iconPDF from '../img/icon-pdf.svg';

import defaultImage from '../img/default-avatar.webp';

const ChatMessage = ({ index, message, onLastMessageDelete, lastUserMessageIndex, length, consultant, onButtonClick, consultantsArray }) => {
    const { t } = useTranslation();
    const { showAlert } = useAppContext();
    const { documentsManager } = useAppContext();
    const [imageUrl, setImageUrl] = useState(null);

    const downloadDocument = async (documentId) => {
        documentsManager.downloadDocument(documentId, showAlert);
    };

    const [openImagePreview, setOpenImagePreview] = useState(false);

    const handleImageClick = () => {
        console.log("Image clicked");
        setOpenImagePreview(true);
    };

    const handleClose = () => {
        setOpenImagePreview(false);
    };


    useEffect(() => {

        if (message && message.role === 'image') {
            if (message.documentId) {
                documentsManager.loadDocumentContent(message.documentId)
                    .then(imageResponse => setImageUrl(imageResponse.url));
            }
        }
    }, [message]);

    return (
        <Fade sx={{ width: '100%', p: 0, m: 0, BorderColor: 'black' }} in={true} timeout={500}>

            <Grid item xs={12} md={12} >
                <Card sx={{ p: 0, pb: 0, overflow: 'hidden', }} elevation={0}>

                    <CardContent sx={{ p: 0, m: 0 }}>
                        <Box sx={{
                            width: '100%',
                            justifyContent: 'center',
                            position: 'relative', overflow: 'hidden',

                        }}>

                            {(message.role === 'assistant' || message.role === 'internal' || message.role === 'document') ? (

                                <Box key={`${"assistant-message"}-${index}`} sx={{
                                    height: '100%', width: 'auto', justifyContent: 'left', display: 'flex',
                                    flexDirection: 'row', alignItems: 'flex-start'
                                }}>

                                    <Box sx={{ width: 'auto', height: '40px', overflow: 'hidden', borderRadius: 2, pl: 2, pr: 1, display: 'flex', alignItems: 'flex-start', position: 'relative' }}>
                                        <Avatar sx={{ width: '40px', height: 'auto', transform: 'scale(1.2)', transformOrigin: 'top' }}
                                            src={consultantsArray[index]?.photo || defaultImage} />
                                    </Box>
                                    <Box sx={{
                                        minHeight: '50px', width: 'auto', height: '100%',
                                        textAlign: 'left', maxWidth: '70%',
                                        position: 'relative', borderRadius: '0px 11px 11px', overflow: 'hidden',
                                    }}>
                                        {/* Gradient overlay */}
                                        <Box sx={{
                                            position: 'absolute', // Position over the image
                                            width: '100%', // Cover entire area
                                            height: '100%', // Cover entire area
                                            background: 'linear-gradient(to right, #CBE6FF, #CBE6FF)', // Gradient from left to right
                                        }} />
                                        <Box sx={{ p: 1.3, height: '100%', textAlign: 'left', position: 'relative', my: -2, }} variant="body2">
                                            {/* variant attribute doesn't seem to affect text style, but why? */}
                                            <ReactMarkdown variant="body2" className="markdown-content">{message.content}</ReactMarkdown>
                                        </Box>

                                        {message.role === 'document' && (
                                            <Box sx={{ p: 2 }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => downloadDocument(message.id)}
                                                >
                                                    {t('consultation.download')}
                                                </Button>
                                            </Box>
                                        )}

                                    </Box>
                                </Box>

                            ) : (message.role === 'uploaded') ? (

                                <Box key={`${"assistant-message"}-${index}`} sx={{
                                    height: '100%', width: 'auto', justifyContent: 'left', display: 'flex',
                                    flexDirection: 'row', alignItems: 'flex-start'
                                }}>

                                    <Box sx={{
                                        minHeight: '50px', width: '100%', height: '100%',
                                        textAlign: 'left',
                                        position: 'relative', borderRadius: '11px', overflow: 'hidden',
                                        mx: 2,
                                        p: 1,
                                        border: '3px solid #CBE6FF'
                                    }}>

                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Box sx={{
                                                width: '4rem', height: 'auto', flexShrink: 0, overflow: 'hidden', borderRadius: 0, pr: 1, display: 'flex', alignItems: 'flex-start', position: 'relative'
                                            }}>
                                                <Avatar sx={{ width: '4rem', height: 'auto', transform: 'scale(1.2)', transformOrigin: 'top', borderRadius: 0 }}
                                                    src={iconPDF} />
                                            </Box>
                                            <Box sx={{ p: 1.3, height: '100%', textAlign: 'left', position: 'relative', my: -2, }} variant="body2">
                                                <Typography sx={{ px: 1, height: '100%', textAlign: 'left', position: 'relative', }} variant="h3">
                                                    {message.title}
                                                </Typography>
                                                <Typography sx={{ px: 1, height: '100%', textAlign: 'left', position: 'relative', }} variant="body2">
                                                    {message.long_summary}
                                                </Typography>
                                                <Typography sx={{ px: 1, height: '100%', textAlign: 'left', position: 'relative', }} variant="body2">
                                                    #{message.docId}
                                                </Typography>
                                            </Box>
                                        </Box>

                                    </Box>
                                </Box>

                            ) : (message.role === 'joined') ? (

                                <Box key={`${"assistant-message"}-${index}`} sx={{
                                    height: '100%', width: 'auto', justifyContent: 'left', display: 'flex',
                                    flexDirection: 'row', alignItems: 'flex-start'
                                }}>

                                    <Box sx={{
                                        minHeight: '50px', maxWidth: '500px', height: '100%',
                                        textAlign: 'left',
                                        position: 'relative', borderRadius: '11px', overflow: 'hidden',
                                        mx: 2,
                                        p: 1,
                                        border: '3px solid #CBE6FF'
                                    }}>

                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Box sx={{ width: 'auto', height: '4rem', overflow: 'hidden', borderRadius: 2, pr: 0.5, display: 'flex', alignItems: 'flex-start', position: 'relative' }}>
                                                <Avatar sx={{ width: '4rem', height: 'auto', transform: 'scale(1.2)', transformOrigin: 'top' }}
                                                    src={consultantsArray[index]?.photo || defaultImage} />
                                            </Box>
                                            <Box sx={{ p: 1.3, height: '100%', textAlign: 'left', position: 'relative', my: -2, }} variant="body2">
                                                <Typography sx={{ pr: 1, pt: 0.5, height: '100%', textAlign: 'left', position: 'relative', }} variant="h3">
                                                    {t(`consultants.${consultantsArray[index]?.key || chatModes.CHAT_UNKNOWN}.name`)}
                                                </Typography>
                                                <Typography sx={{ pr: 1, height: '100%', textAlign: 'left', position: 'relative', }} variant="body2">
                                                    {t(`consultation.join.${consultantsArray[index]?.gender || genderTypes.MALE}`)}
                                                </Typography>
                                            </Box>
                                        </Box>

                                    </Box>
                                </Box>

                            ) : (message.role === 'image') ? (

                                <Box key={`${"assistant-message"}-${index}`} sx={{
                                    height: '100%', width: 'auto', justifyContent: 'left', display: 'flex',
                                    flexDirection: 'row', alignItems: 'flex-start'
                                }}>

                                    <Box sx={{ width: 'auto', height: '40px', overflow: 'hidden', borderRadius: 2, pl: 2, pr: 1, display: 'flex', alignItems: 'flex-start', position: 'relative' }}>
                                        <Avatar sx={{ width: '40px', height: 'auto', transform: 'scale(1.2)', transformOrigin: 'top' }}
                                            src={consultantsArray[index]?.photo || defaultImage} />
                                    </Box>
                                    <Box sx={{
                                        minHeight: '50px', width: 'auto', height: '100%',
                                        textAlign: 'left', maxWidth: '70%',
                                        position: 'relative', borderRadius: '0px 11px 11px', overflow: 'hidden',
                                    }}>
                                        {/* Gradient overlay */}
                                        <Box sx={{
                                            position: 'absolute', // Position over the image
                                            width: '100%', // Cover entire area
                                            height: '100%', // Cover entire area
                                            background: 'linear-gradient(to right, #CBE6FF, #CBE6FF)', // Gradient from left to right
                                        }} />
                                        <Box sx={{ p: 1.3, height: '100%', textAlign: 'left', position: 'relative', my: -2, }} variant="body2">
                                            {/* variant attribute doesn't seem to affect text style, but why? */}
                                            <ReactMarkdown variant="body2" className="markdown-content">{message.description}</ReactMarkdown>

                                            {/* Conditionally render the image if imageUrl is present */}
                                            {imageUrl && (
                                                <Box sx={{ mt: 0 }}>
                                                    <img
                                                        src={imageUrl}
                                                        alt="Generated visual content"
                                                        style={{ maxWidth: '100%', borderRadius: '10px', cursor: 'pointer' }}
                                                        onClick={handleImageClick}
                                                    />
                                                    <Button
                                                        sx={{ mt: 1, mb: 2 }}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => downloadDocument(message.documentId)}
                                                    >
                                                        {t('consultation.download')}
                                                    </Button>
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>

                                    {/* Dialog for image preview */}
                                    <Dialog open={openImagePreview} onClose={handleClose} maxWidth="md" fullWidth>
                                        <DialogContent>
                                            <img
                                                src={imageUrl}
                                                alt="Preview"
                                                style={{ width: '100%', height: 'auto' }}
                                            />
                                        </DialogContent>
                                    </Dialog>

                                </Box>

                            ) : (

                                <Box key={`${"user-message"}-${index}`} sx={{
                                    height: '100%', width: 'auto', justifyContent: 'right', display: 'flex',
                                    flexDirection: 'row', alignItems: 'flex-start',
                                }}>

                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        minHeight: '50px', width: 'auto', height: '100%', textAlign: 'left',
                                        maxWidth: '70%', mr: 2,
                                        position: 'relative', borderRadius: '16px 0px 16px 16px', overflow: 'hidden',
                                    }}>
                                        {/* Gradient overlay */}
                                        <Box sx={{
                                            position: 'absolute', // Position over the image
                                            width: '100%', // Cover entire area
                                            height: '100%', // Cover entire area
                                            background: '#E5E7EB', // Gradient from left to right
                                        }} />
                                        <Typography sx={{ p: 1.7, height: '100%', textAlign: 'left', position: 'relative', }} variant="body2">
                                            {message.content}
                                        </Typography>
                                        {message.role === "user" && index === lastUserMessageIndex && ([
                                            <Box flexGrow={1} />,
                                            < IconButton onClick={onLastMessageDelete} size="small" sx={{ ml: 1, mt: 1 }}>
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>]
                                        )}
                                    </Box>
                                </Box>
                            )}

                        </Box>
                    </CardContent>

                </Card>
            </Grid>


        </Fade >
    );
};

export default ChatMessage