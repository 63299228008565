// src/screens/ConsultationScreen.js
import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Collapse, Typography } from '@mui/material';
import { useAuthRedirect } from '../components/AuthContext.js';
import { useAuth } from '../components/AuthContext.js';
import { useTranslation } from 'react-i18next';
import { useAppContext, } from '../components/AppContext.js';
import { useSearchParams } from 'react-router-dom';
import { AppRoutes } from '../config/AppModes.ts';
import { formatDate, formatDateDistance } from '../utils/dateUtils';
import ConsultationCard from '../components/ConsultationCard';
import PopupDeleteDialog from '../components/PopupDeleteDialog.js';
import PopupRestoreDialog from '../components/PopupRestoreDialog.js';

export function ConsultationDetailsScreen({ setDirection, useDelayedNavigation }) {
    useAuthRedirect(setDirection, useDelayedNavigation);

    const { userProfile, tokenVerificationCompleted } = useAuth();
    const { t } = useTranslation();
    const setDelayedNavigation = useDelayedNavigation();

    const userPhoto = userProfile?.picture || null;

    const [searchParams] = useSearchParams();
    const consId = searchParams.get('consid');

    const messageEndRef = useRef(null);
    const { showAlert, consultationsManager } = useAppContext();
    const [deletedDate, setDeletedDate] = useState(null);
    const [consultation, setConsultation] = useState(null);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogOpenRestore, setDialogOpenRestore] = useState(false);
    const [restored, setRestored] = useState(false);
    const isLoaded = useRef(false);

    useEffect(() => {

        if (isLoaded.current) return;

        if (tokenVerificationCompleted)
            if (consId) {
                // load chat messages by consultation id
                loadConsultation(consId);
                isLoaded.current = true;
            }
    }, [tokenVerificationCompleted, consId, restored]);

    async function loadConsultation(consId) {
        const data = await consultationsManager.getConsultation(consId)
        setDeletedDate(data?.deleted_date_time || null);
        setConsultation(data)
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDeleteConsultation = async () => {
        try {
            setDialogOpen(false);
            showAlert('info', 'Deleting...');
            await consultationsManager.deleteConsultation(consId);
            // Perform any additional actions after deletion, such as redirecting
            showAlert('success', 'Consultation deleted successfully');
            // Optionally, navigate back or show a success message
            setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_CONSULTATIONS.path);
        } catch (error) {
            showAlert('error', 'Error deleting consultation');
            console.error('Error deleting consultation:', error);
            // Handle the error appropriately, e.g., show an error message
        }
    };

    const handleRestoreConsultation = async () => {
        try {
            setDialogOpenRestore(false);
            await consultationsManager.restoreConsultation(consId);
            setRestored(true);
            showAlert('success', 'Consultation restored successfully');
            // Perform any additional actions after deletion, such as redirecting
            // Optionally, navigate back or show a success message
            // setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_CONSULTATIONS.path);
        } catch (error) {
            console.error('Error deleting consultation:', error);
            // Handle the error appropriately, e.g., show an error message
        }
    };

    const handleDialogOpenRestore = () => {
        setDialogOpenRestore(true);
    };

    const handleDialogCloseRestore = () => {
        setDialogOpenRestore(false);
    };

    return (
        <Box sx={{
            p: 0, m: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',
            flex: 1, height: '100%', background: 'rgba(255, 255, 255, 0.7)',
        }}>

            <Box sx={{ m: { md: 3, xs: 2 }, minWidth: { md: '25em' } }}>
                <ConsultationCard
                    index={1}
                    content={consultation}
                    expanded={true}
                    userPhoto={userPhoto}
                />
            </Box>

            <Collapse sx={{ width: '100%' }} in={deletedDate !== null} timeout={200}>
                <Box sx={{ width: 'auto', mx: 2, px: 2, py: 1, mb: 2, textAlign: 'center' }}>
                    <Typography variant="body1" color="error">
                        {t('consultation.consultationDeleted', { date: formatDateDistance(new Date(deletedDate || Date.now())) })}
                    </Typography>
                </Box>
            </Collapse>
            <Collapse sx={{ width: '100%' }} in={deletedDate !== null} timeout={200}>
                <Box sx={{ width: 'auto', mx: 2, px: 10, pt: 0, pb: 0, mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Button sx={{ position: 'relative', width: '100%', px: 2, maxWidth: '20em' }}
                        variant="outlined" color="primary"
                        onClick={handleDialogOpenRestore}>
                        {t('consultationDetails.restoreConsultation')}
                    </Button>
                </Box>
            </Collapse>

            <Collapse sx={{ width: '100%' }} in={!deletedDate && consultation} timeout={200}>
                <Box sx={{ width: 'auto', mx: 2, px: 10, pt: 0, pb: 0, mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Button sx={{ position: 'relative', width: '100%', px: 2, maxWidth: '20em' }}
                        variant="outlined" color="primary"
                        onClick={() => setDelayedNavigation(() => setDirection('backward'), -1)}>
                        {t('consultationDetails.resumeConsultation')}
                    </Button>
                </Box>
                <Box sx={{ width: 'auto', mx: 2, px: 10, pt: 0, pb: 0, mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Button sx={{ position: 'relative', width: '100%', px: 2, maxWidth: '20em' }}
                        variant="danger" color="primary"
                        onClick={handleDialogOpen}>
                        {t('consultationDetails.deleteConsultation')}
                    </Button>
                </Box>
            </Collapse>

            <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',
                overflowY: 'auto', flex: 1,
            }}></Box>

            <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',
                overflowY: 'auto', flexShrink: 0,
            }}>

            </Box>

            <PopupDeleteDialog
                open={dialogOpen}
                setOpen={setDialogOpen}
                onDelete={handleDeleteConsultation}
                Title={t('consultationDetails.deleteConfirmation')}
                Description={consultation?.description}
            />

            <PopupRestoreDialog
                open={dialogOpenRestore}
                setOpen={setDialogOpenRestore}
                onRestore={handleRestoreConsultation}
                Title={t('consultationDetails.restoreConfirmation')}
                Description={consultation?.description}
            />

            <div ref={messageEndRef} />
        </Box >
    );
}

export default ConsultationDetailsScreen;
