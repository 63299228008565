import serviceHA from '../img/services/service-health.webp';
import serviceFT from '../img/services/service-tarot.webp';
import serviceVET from '../img/services/service-vet.webp';
import serviceHOROSCOPE from '../img/services/service-horoscope.webp';
import serviceTALISMAN from '../img/services/service-talisman.webp';
import serviceASTROLOGY from '../img/services/service-astrology.webp';
import servicePSYCHO from '../img/services/service-psychologist.webp';
import { officeTypes, countries } from '../config/AppModes.ts';

const medical_restricted_countries = [
    countries.COUNTRY_AT, countries.COUNTRY_AU, countries.COUNTRY_BE, countries.COUNTRY_BG, countries.COUNTRY_BR, countries.COUNTRY_CA, countries.COUNTRY_CN,
    countries.COUNTRY_CY, countries.COUNTRY_CZ, countries.COUNTRY_DE, countries.COUNTRY_DK, countries.COUNTRY_EE, countries.COUNTRY_ES, countries.COUNTRY_FI,
    countries.COUNTRY_FR, countries.COUNTRY_GB, countries.COUNTRY_GR, countries.COUNTRY_HR, countries.COUNTRY_HU, countries.COUNTRY_IE, countries.COUNTRY_IN,
    countries.COUNTRY_IT, countries.COUNTRY_JP, countries.COUNTRY_KR, countries.COUNTRY_LT, countries.COUNTRY_LU, countries.COUNTRY_LV, countries.COUNTRY_MT,
    countries.COUNTRY_NL, countries.COUNTRY_NZ, countries.COUNTRY_PL, countries.COUNTRY_PT, countries.COUNTRY_RO, countries.COUNTRY_SE, countries.COUNTRY_SI,
    countries.COUNTRY_SK, countries.COUNTRY_US
];

const officesData = [
    { type: officeTypes.TAROT, id: 2, image: serviceFT, enabled: false, restricted_countries: [] },
    { type: officeTypes.TALISMAN, id: 4, image: serviceTALISMAN, enabled: true, restricted_countries: [] },
    { type: officeTypes.ASTROLOGY, id: 5, image: serviceASTROLOGY, enabled: false, restricted_countries: [] },
    { type: officeTypes.HOROSCOPE, id: 6, image: serviceHOROSCOPE, enabled: true, restricted_countries: [] },
    { type: officeTypes.HEALTH, id: 1, image: serviceHA, enabled: true, restricted_countries: medical_restricted_countries },
    { type: officeTypes.PSYCHO, id: 3, image: servicePSYCHO, enabled: true, restricted_countries: medical_restricted_countries },
    { type: officeTypes.ALTMED, id: 7, image: serviceHA, enabled: false, restricted_countries: medical_restricted_countries },
    { type: officeTypes.VET, id: 8, image: serviceHA, enabled: true, restricted_countries: [] },
    { type: officeTypes.TEACHER, id: 9, image: servicePSYCHO, enabled: false, restricted_countries: [] },
    { type: officeTypes.BUSINESS, id: 10, image: servicePSYCHO, enabled: false, restricted_countries: [] },
    { type: officeTypes.LEGAL, id: 11, image: servicePSYCHO, enabled: false, restricted_countries: [] },
    { type: officeTypes.LIFE, id: 12, image: servicePSYCHO, enabled: false, restricted_countries: [] },
    { type: officeTypes.HOUSE, id: 13, image: servicePSYCHO, enabled: false, restricted_countries: [] },
    { type: officeTypes.CAR, id: 14, image: servicePSYCHO, enabled: false, restricted_countries: [] },
    { type: officeTypes.RELIGION, id: 15, image: serviceFT, enabled: false, restricted_countries: [] },
    { type: officeTypes.ESOTERIC, id: 16, image: serviceFT, enabled: false, restricted_countries: [] },
];

class OfficeManager {
    constructor(offices) {
        this.offices = offices;
        this.currentOffice = this.loadCurrentOfficeFromLocalStorage() || officeTypes.HEALTH;
        this.currentOfficeData = this.getOfficeByType(this.currentOffice);
        this.setCurrentOffice(this.currentOffice); // Ensure the current office is set properly
    }

    getAllOffices() {
        return this.offices;
    }

    getActiveOffices(currentCountry) {
        // Возвращаем офисы, у которых enabled = true и текущая страна не находится в restricted_countries
        return this.offices.filter(office =>
            office && office.enabled === true && (!office.restricted_countries || !office.restricted_countries.includes(currentCountry))
        );
    }

    isOfficeRestricted(type, currentCountry) {
        const office = this.getOfficeByType(type);
        return !office || !office.restricted_countries || office.restricted_countries.includes(currentCountry);
    }

    getOfficeByType(type) {
        return this.offices.find(office => office.type === type);
    }

    getOfficeImageByType(type) {
        const office = this.getOfficeByType(type);
        return office ? office.image : null;
    }

    setCurrentOffice(type, currentCountry) {
        let office = this.getOfficeByType(type);
        const isRestricted = this.isOfficeRestricted(type, currentCountry);

        if (isRestricted)
            office = this.getActiveOffices(currentCountry)[0];

        if (office && this.currentOffice !== office.type) {
            this.currentOffice = office.type;
            this.currentOfficeData = office;
            this.saveCurrentOfficeToLocalStorage(office.type); // Сохраняем в локальное хранилище
        }
    }

    getCurrentOffice(currentCountry) {
        //console.log('getCurrentOffice', this.currentOffice);
        let office = this.getOfficeByType(this.currentOffice);
        const isRestricted = this.isOfficeRestricted(office.type, currentCountry);

        if (isRestricted) {
            office = this.getActiveOffices(currentCountry)[0];
            this.setCurrentOffice(office.type, currentCountry);
        }

        return office;
    }

    getCurrentOfficeId() {
        //console.log('getCurrentOffice', this.currentOffice);
        return this.currentOfficeData.id;
    }

    saveCurrentOfficeToLocalStorage(type) {
        localStorage.setItem('currentOffice', type);
    }

    loadCurrentOfficeFromLocalStorage() {
        return localStorage.getItem('currentOffice');
    }

}

const officeManager = new OfficeManager(officesData);
export default officeManager;
