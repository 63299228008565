import React, { useState, useEffect, useRef } from 'react';
import { Button, Box, Fade, CircularProgress, Typography } from '@mui/material';
import logo from '../img/wixee-magistral.svg';
import { AppRoutes } from '../config/AppModes.ts';
import api from '../classes/API';
import { useAuth } from '../components/AuthContext'; // Import useAuth hook
import { get_photo_url, set_photo_url } from '../classes/PhotoStore';


export function GoogleLoginScreen({ setDirection, useDelayedNavigation }) {
    const setDelayedNavigation = useDelayedNavigation();

    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState(null);
    const { login } = useAuth();
    const loginAttempted = useRef(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        console.log('Params:', params);
        console.log('Params string:', params.toString());
        const codeParam = params.get('code') || 'no code';
        console.log('codeParam:', codeParam);
        console.log('codeParam string:', codeParam.toString());
        setCode(codeParam);
    }, []);

    async function onSuccess(code) {

        setLoading(true);
        console.log('Google Login...');
        console.log('Auth code:', code);

        const origin = window.location.origin;
        const path = window.location.pathname;
        const redirect = origin + path;

        // Send the token to the server
        try {
            const data = await api.apiGoogleLogin({ code: code, redirect_uri: redirect });
            // Handle server response
            console.log('Server response:', data);

            // Store JWT token in local storage
            console.log('Token:', data.token);
            console.log('Google Login Success.');
            login(data.token);

            // Update user imge to avoid google's requestper hour limitation
            data.user.picture
            && get_photo_url(data.user.profile_id)
                .catch(() =>
                    fetch(data.user.picture)
                    .then(x => x.blob())
                    .then(x => set_photo_url(data.user.profile_id, x))
                );

            // redirect to home
            setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_HOME.path);
        } catch (error) {

            // FIXME
            // SHow error message and show button to try again
            setError(error.message);
            console.error('Error:', error);

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        console.log('Code:', code);
        if (code && !loginAttempted.current) {
            loginAttempted.current = true;
            onSuccess(code);
        }
    }, [code]);

    return (
        <Box sx={{
            background: 'linear-gradient(to bottom, #7838FF, #1F0061)',
            height: '100vh',
            display: 'flex',
            flex: 1,
            flexDirection: 'column', padding: 0
        }}>
            <Box sx={{ background: 'white', height: '100%' }}>
                <Box
                    sx={{
                        pt: 10,
                        pb: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        color: 'black',
                        height: '100%',
                    }}
                >

                    <img src={logo} alt="Wixee Logo" style={{ width: '200px' }} />

                    {/* <Typography sx={{ mx: 4, mt: 4, textAlign: 'center' }} variant="h2">Welcome!</Typography>
                    <Typography sx={{ mx: 4, mt: 1, textAlign: 'left' }} variant="body1">Signin to continue</Typography> */}
                    <Box sx={{ mt: 10, mx: 4, pt: 4, pb: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                        <Fade in={loading} unmountOnExit><CircularProgress sx={{ ml: 1 }} size={24} /></Fade>

                        <Fade in={error !== null} unmountOnExit>
                            <Box sx={{ mt: 0, mx: 4, pt: 4, pb: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography sx={{ mx: 4, mt: 4, textAlign: 'center' }} variant="h2">
                                    Error
                                </Typography>
                                <Typography sx={{ mx: 4, mt: 4, textAlign: 'center' }} variant="h4">
                                    {error}
                                </Typography>
                                <Button variant="outlined" sx={{ mt: 2 }} onClick={() => { setDelayedNavigation(() => setDirection('backward'), AppRoutes.ROUTE_LOGIN.path); }}>
                                    Try Again
                                </Button>
                            </Box>
                        </Fade>

                    </Box>
                </Box>
            </Box>
        </Box>
    );
}


export default GoogleLoginScreen;