import { officeTypes, chatModes, genderTypes } from '../../config/AppModes.ts';

export const es ={
    "send_email_code": "Enviando un correo electrónico a {{email}} con el código: {{code}}",
    "token_valid": "El token es válido",
    "token_invalid": "El token es inválido",
    "verification_error": "Error al verificar el token:",
    "verification_completed": "Verificación completada. Autenticado: ",
    "welcome": {
      "title": "Wixee"
    },
    "splash1": {
      "title": "Tu Salud Inteligente Comienza Aquí",
      "description": "Empodera el bienestar de tu familia con consejos de salud personalizados por IA, adaptados a las necesidades únicas de cada miembro"
    },
    "splash2": {
      "title": "Información de Salud para Todos",
      "description": "Descubre información integral sobre salud para todas las edades, asegurando que tus seres queridos se mantengan sanos y felices"
    },
    "splash3": {
      "title": "Tu Salud, Nuestra Prioridad",
      "description": "Experimenta el futuro del cuidado de la salud familiar con recomendaciones impulsadas por IA diseñadas para mantenerte en óptima salud"
    },
    "splash4": {
      "title": "¡Empecemos!",
      "description": "Inicia sesión para disfrutar de las funciones que hemos proporcionado y ¡mantente saludable!",
      "signInButton": "Iniciar Sesión"
    },
    "home": {
      "title": "Inicio",
      "consultations": "Consultas",
      "consultationsDescription": "Haz clic aquí para ver tus consultas",
      "documents": "Documentos",
      "documentsDescription": "Haz clic aquí para cargar tus documentos",
      "notifications": "Notificaciones",
      "notificationsDescription": "Haz clic aquí para ver todos los eventos",
      "offices": "Oficinas",
      "officesDescription": "Selecciona una oficina para obtener un servicio",
      "settings": "Configuraciones",
      "settingsDescription": "Cambia el idioma, etc.",
      "cardTitle": "Disfruta tu día de hoy",
      "cardDescription": "Tus condiciones de salud y la actividad solar sugieren que podrías tener complicaciones con las habilidades cognitivas. Relájate y disfruta",
      "newConsultation": "Nueva Consulta",
      "adTitle": "Un anuncio desde la aplicación",
      "learnMore": "Saber más"
    },
    "calendar": {
      "title": "Notificaciones",
      "created": "Creado",
      "source": {
        "consultation": "de consulta",
        "document": "de documento"
      }
    },
    "documents": {
      "title": "Mis Documentos",
      "latest": "Recientes",
      "lastMonth": "Mes pasado",
      "older": "Antiguos",
      "upload": "Cargar Nuevo Documento",
      "deleted": "Eliminados"
    },
    "document": {
      "title": "Documento",
    },
    "header": {
      "youAndYourBeloved": "Tú y tus seres queridos"
    },
    "settings": {
        "title": "Configuración",
        "language": "Idioma",
        "country": "País",
        "userCountry": "Mi País",
        "userLanguage": "Mi Idioma",
        "userEmail": "Mi Correo Electrónico",
        "emailAddress": "Dirección de Correo Electrónico"
    },
    "consultation": {
      "title": "Consulta",
      "description": "Descripción de la consulta",
      "selectConsultant": "Selecciona Consultor",
      "manageConsultation": "Gestionar Consulta",
      "consultationDeleted": "Consulta eliminada {{date}}",
      "download": "Descargar",
      "message": "Mensaje",
      "join": {
        [genderTypes.MALE]: "Se une a la conversación",
        [genderTypes.FEMALE]: "Se une a la conversación",
        [genderTypes.OTHER]: "Se une a la conversación",
      },
    },
    "consultations": {
      "title": "Mis consultas",
      "description": "Descripción de la consulta",
      "latest": "Recientes",
      "seeAll": "Ver todo",
      "lastMonth": "Mes pasado",
      "older": "Antiguas",
      "deleted": "Eliminadas",
      "about": "sobre",
    },
    "profiles": {
      "title": "Mis seres queridos",
    },
    "profile": {
      "title": "Mi perfil",
      "billing": "Mi tarifa",
      "offices": "Mis oficinas",
      "settings": "Configuraciones",
      "general": "General",
      "create": "Crear",
      "logout": "Cerrar sesión",
      "addProfile": "Añadir nuevo ser querido",
      "hideStoredData": "Ocultar datos almacenados",
      "showStoredData": "Mostrar datos almacenados",
      "officeNoSummary": "No hay resumen disponible",
      "termsConditions": "Términos y Condiciones",
      "clickToChange": "Haz clic para cambiar",
      "name": "Nombre",
      "comment": "Comentario",
      "dateOfBirth": "Fecha de nacimiento",
      "timeOfBirth": "Hora de nacimiento (local)",
      "placeOfBirth": "Lugar de nacimiento",
      "height": "Altura (cm)",
      "weight": "Peso (kg)",
      "cm": "cm",
      "kg": "kg"
    },
    "billing": {
      "title": "Facturación",
      "currentBalance": "Saldo Actual",
      "subscriptionPlan": "Plan de Suscripción",
      "subscription": {
        "free": "Gratis",
        "premium": "Premium",
        "exclusive": {
          "title": "Exclusivo",
          "description": "Un plan de suscripción exclusivo ofrece a los miembros acceso premium a contenido único, funciones especiales y servicios personalizados que no están disponibles para usuarios regulares"
        }
      },
      "topUp": "Recargar",
      "changePlan": "Cambiar Plan"
    },
    "offices": {
      "title": "Oficinas",
      [officeTypes.HEALTH]: {
        "name": "Salud",
        "description": "Un servicio de diagnóstico de salud por IA analiza los síntomas y el historial médico proporcionados por el usuario para ofrecer evaluaciones preliminares de las condiciones de salud. Proporciona recomendaciones e información personalizada, orientando a los usuarios sobre los próximos pasos para recibir atención médica, incluyendo cuándo buscar ayuda profesional.",
        "disclaimer": "Este servicio impulsado por IA es solo con fines informativos y no es una herramienta o servicio médico. No proporciona diagnósticos, tratamientos ni recetas médicas. Todas las decisiones relacionadas con la salud deben basarse en la consulta con un profesional médico con licencia. Los desarrolladores y propietarios de esta aplicación no son responsables de ningún resultado de salud derivado del uso de esta herramienta. Si experimentas una emergencia médica, llama al 911 o al servicio de emergencia local inmediatamente."
      },
      [officeTypes.ALTMED]: {
        "name": "Medicina Alternativa",
        "description": "Un servicio de medicina alternativa impulsado por IA que ofrece una gama de tratamientos no tradicionales, incluyendo acupuntura, medicina herbal y homeopatía. Utilizando algoritmos avanzados, proporciona recomendaciones personalizadas basadas en los principios de prácticas curativas alternativas. Este servicio complementa los tratamientos médicos convencionales, ofreciendo una visión holística del bienestar del usuario.",
        "disclaimer": "Este servicio de medicina alternativa impulsado por IA está destinado únicamente a fines de bienestar general e información. No sustituye el diagnóstico o tratamiento médico profesional. Siempre busca el consejo de un proveedor de atención médica calificado antes de tomar decisiones relacionadas con la salud. Los desarrolladores no son responsables de los resultados de salud derivados del uso de este servicio."
      },
      [officeTypes.PSYCHO]: {
        "name": "Psicología",
        "description": "Un servicio de asesoramiento psicológico impulsado por IA que brinda apoyo de salud mental al analizar las emociones, pensamientos y comportamientos reportados por el usuario. Ofrece consejos personalizados, estrategias de afrontamiento y técnicas de auto-mejora para ayudar a los usuarios a manejar el estrés, la ansiedad y otros desafíos psicológicos. Este servicio complementa la terapia tradicional brindando a los usuarios apoyo accesible e inmediato.",
        "disclaimer": "Este servicio de asesoramiento psicológico impulsado por IA está diseñado para ofrecer apoyo general en salud mental y no sustituye el asesoramiento, diagnóstico o tratamiento psicológico o psiquiátrico profesional. Para obtener atención precisa en salud mental, siempre consulta con un profesional de salud mental con licencia. Si estás en crisis o experimentas una emergencia, contacta con un servicio de emergencia local o una línea de ayuda en crisis. Los desarrolladores y propietarios de esta aplicación no asumen responsabilidad por los resultados derivados del uso de este servicio."
      },
      [officeTypes.VET]: {
        "name": "Mascotas",
        "description": "Un servicio veterinario impulsado por IA ayuda a los dueños de mascotas a entender las condiciones de salud de sus animales analizando los síntomas y el historial médico proporcionados por los usuarios. Ofrece ideas preliminares sobre posibles problemas de salud junto con recomendaciones sobre los próximos pasos, como programar una visita al veterinario o manejar el cuidado en casa.",
        "disclaimer": "Este servicio veterinario impulsado por IA está diseñado para proporcionar ideas preliminares sobre la salud de tu mascota basadas en la información proporcionada. No sustituye el cuidado veterinario profesional, el diagnóstico o el tratamiento. Para diagnósticos y planes de tratamiento precisos, consulta siempre a un veterinario con licencia. En caso de una situación urgente o de emergencia con tu mascota, contacta a tu servicio de emergencia veterinaria local. Los desarrolladores y propietarios de esta aplicación no son responsables de los resultados relacionados con la salud de tu mascota basados en el uso de esta herramienta."
      },
      [officeTypes.TEACHER]: {
        "name": "Educación",
        "description": "Un servicio de apoyo educativo impulsado por IA que ofrece tutoría y asistencia docente en una variedad de materias, desde matemáticas hasta artes. El servicio proporciona planes de aprendizaje personalizados y respuestas a las preguntas de los usuarios, simulando la orientación de educadores experimentados. Su objetivo es mejorar los resultados de aprendizaje ofreciendo apoyo educativo adaptado.",
        "disclaimer": "Este servicio educativo impulsado por IA está destinado a complementar la educación formal. No sustituye a los maestros certificados ni a los programas educativos acreditados. Los usuarios deben buscar orientación profesional para la educación formal y las certificaciones. Los desarrolladores no son responsables de los resultados académicos derivados de este servicio."
      },
      [officeTypes.BUSINESS]: {
        "name": "Negocios",
        "description": "Un servicio de consultoría empresarial impulsado por IA, diseñado para proporcionar estrategias e ideas a emprendedores, startups y empresas establecidas. Aprovechando el análisis de datos, el servicio ofrece orientación sobre marketing, operaciones, finanzas y estrategias de crecimiento, simulando la experiencia de un consultor empresarial experimentado. Ayuda a los usuarios a superar desafíos empresariales con recomendaciones y herramientas de planificación impulsadas por IA.",
        "disclaimer": "Este servicio de consultoría empresarial impulsado por IA es solo con fines informativos y de planificación estratégica. No sustituye el asesoramiento profesional financiero, legal u operativo. Los usuarios deben consultar a profesionales calificados para cualquier decisión empresarial crítica. Los desarrolladores no son responsables de los resultados empresariales derivados del uso de este servicio."
      },
      [officeTypes.LEGAL]: {
        "name": "Legal",
        "description": "Un servicio de asesoramiento legal impulsado por IA que proporciona información general sobre una amplia gama de temas legales, desde contratos hasta propiedad intelectual. Utilizando algoritmos avanzados, ofrece sugerencias e ideas basadas en las consultas de los usuarios, simulando la experiencia legal. El servicio ayuda a los usuarios a entender mejor sus situaciones legales, pero no sustituye el asesoramiento legal personalizado.",
        "disclaimer": "Este servicio de asesoramiento legal impulsado por IA es solo con fines informativos y no constituye asesoramiento legal. Los usuarios deben consultar a un abogado con licencia para cualquier preocupación legal específica. Los desarrolladores no son responsables de las acciones legales tomadas basadas en la información proporcionada por este servicio."
      },
      [officeTypes.LIFE]: {
        "name": "Vida",
        "description": "Un servicio de desarrollo personal y coaching de vida impulsado por IA destinado a ayudar a las personas a navegar por varios aspectos de sus vidas, incluyendo la planificación de carreras, el auto-mejoramiento, los viajes y la reubicación. El servicio utiliza IA para ofrecer orientación personalizada y estrategias para alcanzar metas de vida y mejorar el bienestar general.",
        "disclaimer": "Este servicio de coaching de vida impulsado por IA está destinado para la auto-reflexión y el asesoramiento general. No sustituye el asesoramiento profesional psicológico, financiero o legal. Los usuarios deben consultar a profesionales calificados para cualquier decisión que cambie la vida. Los desarrolladores no son responsables de las decisiones tomadas basadas en las recomendaciones de este servicio."
      },
      [officeTypes.HOUSE]: {
        "name": "Hogar",
        "description": "Un servicio de consultoría de servicios para el hogar impulsado por IA que proporciona orientación sobre reparaciones, mantenimiento y mejoras en el hogar. Desde plomería y trabajos eléctricos hasta jardinería y seguridad, el servicio ofrece recomendaciones adaptadas para proyectos domésticos. Al simular el asesoramiento experto, ayuda a los usuarios a abordar tareas y renovaciones diarias en el hogar.",
        "disclaimer": "Este servicio de consultoría de servicios para el hogar impulsado por IA está destinado únicamente con fines informativos. No sustituye la experiencia de profesionales certificados en reparaciones, construcción o mantenimiento del hogar. Los usuarios deben contratar contratistas con licencia para cualquier proyecto importante en el hogar. Los desarrolladores no son responsables de los resultados derivados del uso de este servicio."
      },
      [officeTypes.CAR]: {
        "name": "Automóviles",
        "description": "Un servicio de consultoría automotriz impulsado por IA que proporciona ideas y recomendaciones para el mantenimiento del automóvil, reparaciones y consejos sobre compra/venta. Al simular la experiencia de profesionales automotrices, el servicio ayuda a los usuarios a tomar decisiones informadas sobre sus vehículos, desde el mantenimiento de rutina hasta reparaciones mayores y compras.",
        "disclaimer": "Este servicio de consultoría automotriz impulsado por IA es solo con fines informativos. No sustituye a los mecánicos certificados ni al asesoramiento profesional automotriz. Los usuarios deben consultar a profesionales con licencia para cualquier decisión crítica relacionada con vehículos. Los desarrolladores no son responsables de los resultados relacionados con el mantenimiento o transacciones de vehículos."
      }
    },
    "consultants": {
      "title": "Consultores",
      [chatModes.CHAT_HEALTH_RECEPTIONIST]: {
        "name": "Recepcionista de IA",
        "description": "Te ayuda a elegir el especialista adecuado"
      },
      [chatModes.CHAT_HEALTH_ALLERGIST]: {
        "name": "Alergólogo de IA",
        "description": "Especialista en el diagnóstico y manejo de alergias y afecciones relacionadas"
      },
      [chatModes.CHAT_HEALTH_ANESTHESIOLOGIST]: {
        "name": "Anestesiólogo de IA",
        "description": "Experto en la administración de anestesia y el manejo del dolor durante cirugías"
      },
      [chatModes.CHAT_HEALTH_CARDIOLOGIST]: {
        "name": "Cardiólogo de IA",
        "description": "Experto en salud cardiovascular, especializado en diagnosticar y tratar afecciones cardíacas"
      },
      [chatModes.CHAT_HEALTH_COLOPROCTOLOGIST]: {
        "name": "Coloproctólogo de IA",
        "description": "Especialista en el diagnóstico y tratamiento de trastornos del colon, recto y ano"
      },
      [chatModes.CHAT_HEALTH_COSMETOLOGIST]: {
        "name": "Cosmetólogo de IA",
        "description": "Experto en el cuidado de la piel, tratamientos de belleza y procedimientos estéticos"
      },
      [chatModes.CHAT_HEALTH_DENTIST]: {
        "name": "Dentista de IA",
        "description": "Especialista en salud bucal, incluyendo el cuidado de los dientes y las encías"
      },
      [chatModes.CHAT_HEALTH_DERMATOLOGIST]: {
        "name": "Dermatólogo de IA",
        "description": "Experto en el diagnóstico y tratamiento de afecciones y enfermedades de la piel"
      },
      [chatModes.CHAT_HEALTH_DIETITIAN]: {
        "name": "Dietista de IA",
        "description": "Experto en nutrición, te ayuda a tomar decisiones dietéticas informadas para mejorar la salud"
      },
      [chatModes.CHAT_HEALTH_ENDOCRINOLOGIST]: {
        "name": "Endocrinólogo de IA",
        "description": "Especialista en trastornos hormonales y manejo del metabolismo"
      },
      [chatModes.CHAT_HEALTH_ENDOSCOPIST]: {
        "name": "Endoscopista de IA",
        "description": "Experto en procedimientos endoscópicos para diagnosticar y tratar afecciones internas"
      },
      [chatModes.CHAT_HEALTH_GASTROENTEROLOGIST]: {
        "name": "Gastroenterólogo de IA",
        "description": "Experto en trastornos del sistema digestivo, incluyendo el estómago y los intestinos"
      },
      [chatModes.CHAT_HEALTH_GYNECOLOGIST]: {
        "name": "Ginecólogo de IA",
        "description": "Especialista en salud reproductiva femenina, incluyendo menstruación, embarazo y menopausia"
      },
      [chatModes.CHAT_HEALTH_IMMUNOLOGIST]: {
        "name": "Inmunólogo de IA",
        "description": "Experto en diagnosticar y tratar trastornos del sistema inmunológico"
      },
      [chatModes.CHAT_HEALTH_INFECTIOLOGIST]: {
        "name": "Infectólogo de IA",
        "description": "Especialista en el diagnóstico y tratamiento de enfermedades infecciosas"
      },
      [chatModes.CHAT_HEALTH_NEPHROLOGIST]: {
        "name": "Nefrólogo de IA",
        "description": "Experto en salud renal y tratamiento de enfermedades relacionadas con los riñones"
      },
      [chatModes.CHAT_HEALTH_NEUROLOGIST]: {
        "name": "Neurólogo de IA",
        "description": "Especialista en trastornos del cerebro, la médula espinal y el sistema nervioso"
      },
      [chatModes.CHAT_HEALTH_ONCOLOGIST]: {
        "name": "Oncólogo de IA",
        "description": "Especialista en el tratamiento del cáncer, enfocado en diagnosticar y manejar el cáncer"
      },
      [chatModes.CHAT_HEALTH_OPHTHALMOLOGIST]: {
        "name": "Oftalmólogo de IA",
        "description": "Especialista en salud ocular, diagnosticando y tratando problemas de visión y salud ocular"
      },
      [chatModes.CHAT_HEALTH_PEDIATRICIAN]: {
        "name": "Pediatra de IA",
        "description": "Especialista en la salud infantil, brindando atención médica a bebés y niños"
      },
      [chatModes.CHAT_HEALTH_SURGEON]: {
        "name": "Cirujano General de IA",
        "description": "Especialista en atención quirúrgica para una amplia gama de condiciones médicas"
      },
      [chatModes.CHAT_HEALTH_THERAPIST]: {
        "name": "Terapeuta de IA",
        "description": "Experto en salud general, ofreciendo consejos sobre problemas médicos comunes"
      },
      [chatModes.CHAT_VET_RECEPTIONIST]: {
        "name": "Recepcionista Veterinario de IA",
        "description": "Te ayuda a elegir el especialista veterinario adecuado"
      },
      [chatModes.CHAT_VET_ALLERGIST]: {
        "name": "Alergólogo Veterinario de IA",
        "description": "Especialista en diagnosticar y tratar alergias en animales"
      },
      [chatModes.CHAT_VET_DENTIST]: {
        "name": "Dentista Veterinario de IA",
        "description": "Especialista en cuidado dental y salud bucal para animales"
      },
      [chatModes.CHAT_HOUSE_FENGSHUI]: {
        "name": "Consultor de Feng Shui de IA",
        "description": "Especialista en Feng Shui, ofrece consejos para armonizar tu espacio vital para mejorar el flujo de energía"
      }
    },
    "consultationDetails": {
      "title": "Detalles de la Consulta",
      "deleteConfirmation": "¿Estás seguro de que deseas eliminar esta consulta?",
      "deleteConsultation": "Eliminar",
      "resumeConsultation": "Reanudar",
      "restoreConsultation": "Restaurar",
      "restoreConfirmation": "¿Estás seguro de que deseas restaurar esta consulta?"
    },
    "documentDetails": {
      "title": "Detalles del Documento",
      "manage": "Gestionar Documento",
      "download": "Descargar"
    },
    "documentManage": {
      "title": "Gestionar Documento",
      "share": "Compartir",
      "delete": "Eliminar",
      "restore": "Restaurar",
      "rename": "Renombrar",
      "changeProfile": "Cambiar Persona Relacionada",
      "download": "Descargar",
      "documentDeleted": "Documento eliminado {{date}}",
      "deleteConfirmation": "¿Estás seguro de que deseas eliminar este documento?",
      "restoreConfirmation": "¿Estás seguro de que deseas restaurar este documento?"
    },
    "deleteConfirmation": {
      "title": "¿Realmente quieres eliminar esta consulta?",
      "cancel": "Cancelar",
      "delete": "Eliminar"
    },
    "restoreConfirmation": {
      "title": "¿Quieres restaurar esta consulta?",
      "cancel": "Cancelar",
      "restore": "Restaurar"
    },
    "uploadOptions": {
      "takePhoto": "Tomar Foto",
      "photoGallery": "Galería de Fotos",
      "uploadFile": "Subir Documento"
    },
    "languages": {
      "af": "Afrikáans",
      "ar": "Árabe",
      "az": "Azerí",
      "be": "Bielorruso",
      "bg": "Búlgaro",
      "bn": "Bengalí",
      "bs": "Bosnio",
      "ca": "Catalán",
      "cs": "Checo",
      "da": "Danés",
      "de": "Alemán",
      "de-AT": "Alemán (Austria)",
      "el": "Griego",
      "en": "Inglés",
      "en-GB": "Inglés (Reino Unido)",
      "en-US": "Inglés (EE.UU.)",
      "es": "Español",
      "et": "Estonio",
      "fa-IR": "Persa (Irán)",
      "fi": "Finlandés",
      "fr": "Francés",
      "fr-CA": "Francés (Canadá)",
      "fr-CH": "Francés (Suiza)",
      "he": "Hebreo",
      "hi": "Hindi",
      "hu": "Húngaro",
      "is": "Islandés",
      "it": "Italiano",
      "ja": "Japonés",
      "ko": "Coreano",
      "nl": "Neerlandés",
      "pl": "Polaco",
      "pt": "Portugués",
      "pt-BR": "Portugués (Brasil)",
      "ro": "Rumano",
      "ru": "Ruso",
      "sv": "Sueco",
      "tr": "Turco",
      "uk": "Ucraniano",
      "vi": "Vietnamita",
      "zh-CN": "Chino (Simplificado)",
      "zh-TW": "Chino (Tradicional)"
    }
  }
  