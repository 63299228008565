// src/screens/ConsultationScreen.js
import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, Button, Fade, Collapse, Typography } from '@mui/material';
import { useAuthRedirect } from '../components/AuthContext.js';
import { useAuth } from '../components/AuthContext';
import { useTranslation } from 'react-i18next';
import { useAppContext, } from '../components/AppContext.js';
import { useChat } from '../hooks/useChat.js'; // Import the custom hook
import { useSearchParams } from 'react-router-dom'; // Import useParams

import ConsultantCard from '../components/ConsultantCard.js';
import OptionButtons from "../components/ChatOptionButtons.js";
import MessageList from '../components/MessageList.js';
import { AppRoutes } from '../config/AppModes.ts';
import { formatDate, formatDateDistance } from '../utils/dateUtils';
import DocumentGenerator from '../classes/DocumentGenerator';
import ImageGenerator from '../classes/ImageGenerator';
import CircularProgressWithLabel from '../elements/CircularProgress';
import { safeParseJson } from '../utils/utils.js';
import { Description } from '@mui/icons-material';

export function ConsultationScreen({ setDirection, useDelayedNavigation }) {
    useAuthRedirect(setDirection, useDelayedNavigation);

    const { tokenVerificationCompleted } = useAuth();
    const { t, i18n } = useTranslation();
    const setDelayedNavigation = useDelayedNavigation();

    const [searchParams] = useSearchParams();
    const consId = searchParams.get('consid');

    const messageEndRef = useRef(null);
    const { userMessage,
        setUserMessage,
        uploadedDocument,
        consultationId: currentConsultationId,
        setConsultationId: setCurrentConsultationId,
        consultationsManager,
        consultantManager,
        setUploadedDocument,
        currentProfileId,
        officeManager,
        showAlert,
        setCurrentProfileId
    }
        = useAppContext();
    //const [currentConsultationId, setCurrentConsultationId] = useState(consId);
    const [currentConsultant, setCurrentConsultant] = useState(null);

    const [initialMessages, setInitialMessages] = useState([]);
    const [deletedDate, setDeletedDate] = useState(null);
    const [systemOptions, setSystemOptions] = useState([]);

    const [progress, setProgress] = useState(0);
    const [documentId, setDocumentId] = useState(null);
    const [generatingDocument, setGeneratingDocument] = useState(false);

    const isLoaded = useRef(false);

    const {
        chatMessages,
        isLoading,
        jsonOptions,
        sendAnswer,
        handleDeleteLastUserMessage,
        handleOptionSelect,
        scrollToBottom,
        consultationId,
        totalCost,
        addChatMessage,
        removeMessagesByRole,
    } = useChat(
        initialMessages,
        messageEndRef,
        currentConsultant?.key,
        currentConsultationId,
        onLastMessageDelete);

    useEffect(() => {
        // When consultationId received for new consultation
        if (consultationId) {
            setCurrentConsultationId(consultationId);
            setParamToUrl('consid', consultationId);
        }
    }, [consultationId, setCurrentConsultationId]);

    function setParamToUrl(key, value) {
        const url = new URL(window.location);
        url.searchParams.set(key, value);
        // Use replaceState to update the URL without adding a new history entry
        window.history.replaceState({}, '', url);
    }

    useEffect(() => {
        if (uploadedDocument?.docId) {
            console.log('Uploaded document:', uploadedDocument);
            addChatMessage('uploaded',
                'Document uploaded.',
                {
                    docId: uploadedDocument.docId,
                    title: uploadedDocument.title,
                    short_summary: uploadedDocument.short_summary,
                    long_summary: uploadedDocument.long_summary,
                }, true);
            // get chat response
            setTimeout(() => { sendAnswer(''); }, 500);
        }
    }, [uploadedDocument]);


    useEffect(() => {
        // Load chat messages
        if (isLoaded.current) return;

        if (tokenVerificationCompleted && consultationsManager)
            if (consId) {
                // load chat messages by consultation id
                setCurrentConsultationId(consId);
                loadChatMessagesByConsultationId(consId);
                isLoaded.current = true;
            }
            else {
                console.log('Initialize new consultation');
                //console.log('advisor:', selectedConsultant)
                setCurrentConsultationId(null);
                setUploadedDocument(null);
                setCurrentConsultant(consultantManager.getSelectedConsultant());
                isLoaded.current = true;
            }
    }, [tokenVerificationCompleted, consultationsManager, consId]);


    useEffect(() => {
        if (jsonOptions) {
            //console.log(jsonOptions);
            // check if jsonOptions contains doc-ready string
            if (jsonOptions.includes('{doc-ready}')) {
                addGenerateDocumentOption();
            }
            if (jsonOptions.includes('speciality')) {
                inviteDoctor();
            }
            if (jsonOptions.includes('{talisman-ready}')) {
                generateTalismanImage();
            }
        }

    }, [jsonOptions]);

    const generateTalismanImage = async () => {
        try {
            const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
            const currentDateTime = new Date();
            const imageGenerator = new ImageGenerator();
            console.log('Starting image generation...');
            setGeneratingDocument(true);

            // Remove all messages except assistant and user
            const filteredMessages = chatMessages.filter((message) => {
                if (message.role === 'assistant' || message.role === 'user') {
                    return true;
                } else {
                    //console.log(`Message with role ${role} removed:`, message);
                    return false;
                }
            });

            const messagebody = JSON.stringify(filteredMessages);

            const { documentId, description } = await imageGenerator.generateImage(
                {
                    profileId: currentProfileId,
                    officeId: officeManager.getCurrentOfficeId(),
                    //consultation: JSON.stringify(chatMessages),
                    consultationId: currentConsultationId,
                    message: messagebody,
                    timezone: timeZone,
                    datetime: currentDateTime,
                    advisor: currentConsultant?.key,
                    language: i18n.language,
                },
                (progressData) => {
                    setProgress(progressData);
                }
            );

            setGeneratingDocument(false);
            removeMessagesByRole('internal');
            addChatMessage('image', 'Your talisman is ready.', { documentId, description }, true);
            console.log('Image generated successfully, url:', documentId);
        } catch (error) {
            console.error('Image generation failed:', error);
            showAlert('error', 'Image generation failed. Please try again later.');
            setGeneratingDocument(false);
            removeMessagesByRole('internal');
            //addGenerateDocumentOption();
        }
    }

    function addGenerateDocumentOption() {
        removeMessagesByRole('internal');
        addChatMessage('internal', 'Do you want to create document?');
        setSystemOptions('{"options_list": ["Create document", "Create extended document", "I need clarifications"]}');
    }

    function inviteDoctor() {
        const parsedJson = safeParseJson(jsonOptions);
        console.log(parsedJson);
        if (parsedJson) {
            removeMessagesByRole('internal');
            addChatMessage('internal', `Inviting doctor "${parsedJson.speciality}", goal: "${parsedJson.goal}"`);

            // Update assitant for the consultation
            const consultantKey = "chat-doctor-" + parsedJson.speciality;
            const consultant = consultantManager.getConsultantByKey(consultantKey);
            setCurrentConsultant(consultant);

            addChatMessage('joined', 'Doctor invited', { data: { speciality: consultantKey, goal: parsedJson.goal } }, true);

            // Initiate the chat message
            sendAnswer('');

        }
    }

    const handleGenerateDocument = async (extended = false) => {
        try {
            const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
            const currentDateTime = new Date();
            const documentGenerator = new DocumentGenerator();
            console.log('Starting document generation...');
            setGeneratingDocument(true);

            // Remove all messages except assistant and user
            const filteredMessages = chatMessages.filter((message) => {
                if (message.role === 'assistant' || message.role === 'user') {
                    return true;
                } else {
                    //console.log(`Message with role ${role} removed:`, message);
                    return false;
                }
            });

            const messagebody = JSON.stringify(filteredMessages);
            const docId = await documentGenerator.generateDocument(
                {
                    profileId: currentProfileId,
                    officeId: officeManager.getCurrentOfficeId(),
                    //consultation: JSON.stringify(chatMessages),
                    consultationId: currentConsultationId,
                    message: messagebody,
                    timezone: timeZone,
                    datetime: currentDateTime,
                    advisor: currentConsultant?.key,
                    extended: extended,
                    language: i18n.language,
                },
                (progressData) => {
                    setProgress(progressData);
                }
            );

            setDocumentId(docId);
            setGeneratingDocument(false);
            removeMessagesByRole('internal');
            addChatMessage('document', 'The report is ready.', { id: docId }, true);
            console.log('Document generated successfully, ID:', docId);
        } catch (error) {
            console.error('Document generation failed:', error);
            showAlert('error', 'Document generation failed. Please try again later.');
            setGeneratingDocument(false);
            removeMessagesByRole('internal');
            addGenerateDocumentOption();
        }
    };

    function onLastMessageDelete() {
        setSystemOptions('');
        removeMessagesByRole('internal');
    }

    const handleSystemOptionSelected = (option, index) => {
        console.log('option selected', index, option);
        if (index === 0) {
            // Generate document
            setSystemOptions('');
            removeMessagesByRole('internal');
            addChatMessage('internal', 'Generating document...');
            handleGenerateDocument(false);
        }
        else if (index === 1) {
            // Generate extended document
            setSystemOptions('');
            removeMessagesByRole('internal');
            addChatMessage('internal', 'Generating extended document...');
            handleGenerateDocument(true);
        }
        else {
            setSystemOptions('');
            removeMessagesByRole('internal');
            handleOptionSelect(option);
        }
    }


    async function loadChatMessagesByConsultationId(consId) {
        const { profileId, consultant, messages, deleted_date } = await consultationsManager.loadChatMessagesByConsultationId(consId);
        if (profileId && profileId !== currentProfileId) {
            if (currentProfileId) showAlert('info', `Switching to profile of current consiltation`);
            setCurrentProfileId(profileId);
        }
        setCurrentConsultant(consultant || {});
        setInitialMessages(messages);
        setDeletedDate(deleted_date);
    }

    useEffect(() => {
        if (userMessage) {
            removeMessagesByRole('internal');
            sendAnswer(userMessage);
            setUserMessage('');
        }
    }, [userMessage, sendAnswer, setUserMessage]);

    const handleCardClick = (profile) => {
        setDelayedNavigation(() => setDirection('backward'), -1);
    };

    return (
        <Box sx={{
            p: 0, m: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',
            flex: 1, height: '100%', background: 'white'
        }}>
            <Fade in={currentConsultant !== null} timeout={500}>
                <Box sx={{
                    p: 2, m: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',
                    overflowY: 'auto', flexShrink: 0,
                }}>
                    <Grid container spacing={3} sx={{ width: '100%' }}>
                        <Grid item xs={12} md={12}>
                            <ConsultantCard consultant={currentConsultant} handleCardClick={handleCardClick} />
                        </Grid>
                    </Grid>
                </Box>
            </Fade>

            <Fade in={currentConsultationId !== null && currentConsultant !== null} timeout={1000}>
                <Box sx={{ width: '100%', }}>
                    <Collapse sx={{ width: '100%' }} in={deletedDate !== null} timeout={500}>
                        <Box sx={{ width: 'auto', mx: 2, px: 2, py: 1, mb: 2, textAlign: 'center' }}>
                            <Typography variant="body1" color="error">{t('consultation.consultationDeleted', { date: formatDateDistance(new Date(deletedDate)) })}</Typography>
                        </Box>
                    </Collapse>
                    <Box sx={{ width: '100%', mx: 2, px: 0, pt: 0, pb: 0.5, mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                        <Button sx={{ position: 'relative', width: '70%', px: 2, maxWidth: '20em' }}
                            variant="outlined" color="primary"
                            onClick={() => { setDelayedNavigation(() => setDirection('forward'), (AppRoutes.ROUTE_CONSULTATION_DETAILS.path) + '?consid=' + currentConsultationId) }}>
                            {t('consultation.manageConsultation')}
                        </Button>
                    </Box>
                </Box>
            </Fade>

            <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',
                overflowY: 'auto', flex: 1,
            }}></Box>

            <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',
                overflowY: 'auto', flexShrink: 0,
            }}>
                <MessageList messages={chatMessages} consultant={currentConsultant} handleDeleteLastUserMessage={handleDeleteLastUserMessage} />
            </Box>

            <OptionButtons jsonString={jsonOptions} onOptionSelect={handleOptionSelect} scrollToBottom={scrollToBottom} />

            <OptionButtons jsonString={systemOptions} onOptionSelect={handleSystemOptionSelected} scrollToBottom={scrollToBottom} />

            <Box sx={{ pb: 3, width: '100%', background: 'white', display: 'flex', justifyContent: 'center', }} >
                <Collapse in={generatingDocument}>
                    <CircularProgressWithLabel value={progress} />
                </Collapse>
            </Box>

            <div ref={messageEndRef} />
        </Box >
    );
}

export default ConsultationScreen;
