// ./components/GoogleLoginButton.js
import React, { useRef } from 'react';
import { Button } from '@mui/material';
import { isPlatform } from '@ionic/react';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';


// resources
import GoogleIcon from '@mui/icons-material/Google';

const clientId = '557288960939-i8vv20fuvj00kbqj76l6slfso9qaoja5.apps.googleusercontent.com';

const GoogleLoginButtonComponent = ({ onSuccess, onFailure, onValidate }) => {
    const isLoggingIn = useRef(false); // useRef to track login status

    const handleLogin = async () => {
        if (!onValidate()) return false;

        if (isLoggingIn.current) return; // Prevent double-clicks
        isLoggingIn.current = true;

        if (isPlatform('capacitor')) {
            try {
                // Sign in using the GoogleAuth plugin for mobile platforms
                const result = await GoogleAuth.signIn();
                console.log('Google Logged in!');
                //console.log('Google Auth result:', result);

                // Extract the serverAuthCode from the result
                const serverAuthCode = result.serverAuthCode;
                console.log('Server Auth Code:', serverAuthCode);

                // Check if serverAuthCode is available and handle it accordingly
                if (serverAuthCode) {
                    onSuccess(serverAuthCode);
                } else {
                    console.error('Server Auth Code not found');
                    onFailure('Server Auth Code not found');
                }

                onSuccess(result.authentication);
            } catch (error) {
                console.error('Google Auth error:', error);
                onFailure(error);
            } finally {
                isLoggingIn.current = false; // Reset the ref after the process is done
            }
        } else {
            const origin = window.location.origin;
            const redirectUri = `${origin}/googlelogin`;
            console.log('Redirect:', redirectUri);

            // Construct the query parameters
            const params = new URLSearchParams({
                client_id: clientId,
                redirect_uri: redirectUri,
                response_type: 'code',
                scope: 'openid profile email',
                prompt: 'select_account',
            });

            // Construct the full URL
            const url = `https://accounts.google.com/o/oauth2/v2/auth?${params.toString()}`;
            window.location.href = url;
            isLoggingIn.current = false;
        }

    };

    React.useEffect(() => {
        if (isPlatform('capacitor')) {
            GoogleAuth.initialize();
        }
    }, []);

    return (

        <Button
            variant="outlined"
            startIcon={<GoogleIcon style={{ fontSize: 30 }} />}

            sx={{
                mb: 2, width: '100%',
                justifyContent: 'flex-start',  // Ensures text and icon are aligned more to the left
                '.MuiButton-startIcon': {      // Target the startIcon specifically for any additional styling
                    marginRight: 2,           // Adjust spacing between the icon and the text
                },
                '&:hover .MuiCircularProgress-root': {
                    color: 'white', // Change CircularProgress color on hover
                },
            }}
            onClick={() => { console.log('Starting google Login.'); handleLogin() }}
        >
            Login with Google
        </Button>

    );
};


// Wrap the GoogleLoginButtonComponent with GoogleOAuthProvider
function GoogleLoginButton({ onSuccess, onFailure, onValidate }) {

    return (

        <GoogleLoginButtonComponent onSuccess={onSuccess} onFailure={onFailure} onValidate={onValidate} />
    );
}

export default GoogleLoginButton;