// App.js
import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import theme from './assets/Theme.js';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Box, ThemeProvider } from '@mui/material';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { AuthProvider, } from './components/AuthContext.js';  // Import AuthContext
import { AppProvider } from './components/AppContext.js'; // Import AppProvider
import { AppRoutes } from './config/AppModes.ts';

// i18n
import { I18nextProvider } from 'react-i18next';
import i18n from './utils/i18n'; // Import the i18n configuration
import { loadAllLocales } from './utils/dateUtils'; // импорт вашей функции для загрузки локалей

// Screens
import WelcomeScreen from './screens/WelcomeScreen.tsx';
import SplashScreen1 from './screens/SplashScreen1.js';
import SplashScreen2 from './screens/SplashScreen2.js';
import SplashScreen3 from './screens/SplashScreen3.js';
import SplashScreen4 from './screens/SplashScreen4.js';
import LoginScreen from './screens/LoginScreen.js';
import HomeScreen from './screens/HomeScreen.js';
import HomeOfficeScreen from './screens/ConsultationsScreen.js';
import ProfileScreen from './screens/ProfileScreen.js';
import SettingsScreen from './screens/SettingsScreen.js';
import BillingScreen from './screens/BillingScreen.js';
import DocumentsScreen from './screens/DocumentsScreen.js';
import CalendarScreen from './screens/CalendarScreen.js';
import LoginByEmailScreen from './screens/LoginByEmailScreen.js';
import SelectProfileScreen from './screens/SelectProfileScreen.js';
import SetProfileContextScreen from './screens/SetProfileContextScreen.js';
import SelectConsultantScreen from './screens/SelectConsultantScreen.js';
import ConsultationScreen from './screens/ConsultationScreen.js';
import SelectOfficeScreen from './screens/SelectOfficeScreen.js';
import Profile2Screen from './screens/ProfileEditScreen.tsx';
import ProfileOfficesScreen from './screens/ProfileOfficesScreen.js';
import ConsultationDetailsScreen from './screens/ConsultationDetailsScreen.js';
import DocumentDetailsScreen from './screens/DocumentDetailsScreen.js';
import DocumentManageScreen from './screens/DocumentManageScreen.js';
import GoogleLoginScreen from './screens/GoogleLoginScreen.js';

import Footer from './footer/Footer.js';  // Import the Footer component
import Header from './header/Header.js';  // Import the Header component 

import outerBG from './img/outer-bg.webp';  // Import the Header component 

function App() {

  const location = useLocation();
  const [direction, setDirection] = useState('forward');

  function useDelayedNavigation() {
    const navigate = useNavigate();
    const setDelayedNavigation = (directionSetter, path, delay = 100) => {
      directionSetter();
      //console.log(`Navigating to ${path} in ${delay}ms...`);
      setTimeout(() => navigate(path), delay);
    };
    return setDelayedNavigation;
  }

  function getDirectionClassName(direction) {
    //console.log(direction);
    switch (direction) {
      case 'forward':
        return 'slide';
      case 'backward':
        return 'slide-back';
      case 'fade':
        return 'slide-fade';
      default:
        return '';
    }
  }


  // Create a ref for each route
  const nodeRefs = useRef({});

  if (!nodeRefs.current[location.key]) {
    nodeRefs.current[location.key] = React.createRef();
  }

  return (
    <I18nextProvider i18n={i18n}>
      <AuthProvider>
        <AppProvider> {/* Wrap with AppProvider */}
          <ThemeProvider theme={theme}>

            <Box sx={{
              // background: 'linear-gradient(to bottom, lightgrey, grey)',
              // backgroundImage: 'linear-gradient(180deg, #fff 4.32%, rgba(255, 255, 255, 0) 100%), linear-gradient(270deg, rgba(0, 255, 253, 0.2) 0, rgba(236, 0, 255, .2) 51.26%, rgba(255, 103, 0, 0.2) 100%)',
              backgroundImage: `url(${outerBG})`,
              backgroundSize: 'cover', // This will make the image cover the available space
              backgroundPosition: 'center', // Centers the background image
              //height: `${viewportHeight}px`,
              //maxHeight: `${viewportHeight}px`,
              //height: '420px',
              height: '100svh',
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              overflow: 'hidden',
              alignItems: 'center',
            }}>
              <Box maxWidth='md'
                sx={{
                  // background: 'white',
                  width: '100%',
                  display: 'flex', // Add flex display to ensure full height usage
                  flexDirection: 'column', // Ensure children are stacked vertically
                  justifyContent: 'center', // Center vertically
                  //height: `${viewportHeight}px`,
                  //maxHeight: `${viewportHeight}px`,
                  //height: '420px',
                  height: '100%',
                  flex: 1,
                  overflow: 'hidden',
                }}>
                <Header setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} /> {/* Header component */}
                <Box
                  sx={{
                    width: '100%',
                    position: 'relative',
                    flex: 1,
                    height: '100%',
                    padding: 0,
                    //border: '1px solid #ccc', // Add border
                    display: 'flex', // Add flex display to ensure full height usage
                    flexDirection: 'column', // Ensure children are stacked vertically
                    overflowX: 'hidden',
                    overflowY: 'auto',
                  }}>

                  <TransitionGroup component={null}>
                    <CSSTransition
                      key={location.key}
                      classNames={getDirectionClassName(direction)}
                      timeout={300}
                      nodeRef={nodeRefs.current[location.key]}
                      sx={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%', }}
                    >

                      <Box sx={{
                        flex: 1,
                        height: '100%',
                      }}
                        ref={nodeRefs.current[location.key]}>

                        <Routes location={location}>

                          <Route path={AppRoutes.ROUTE_GOOGLELOGIN.path} element={<GoogleLoginScreen setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_WELCOME.path} element={<WelcomeScreen setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_SPLASH1.path} element={<SplashScreen1 setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_SPLASH2.path} element={<SplashScreen2 setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_SPLASH3.path} element={<SplashScreen3 setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_SPLASH4.path} element={<SplashScreen4 setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_LOGIN.path} element={<LoginScreen setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_LOGIN_BY_EMAIL.path} element={<LoginByEmailScreen setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_HOME.path} element={<HomeScreen setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_SELECT_OFFICE.path} element={<SelectOfficeScreen setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_PROFILE.path} element={<ProfileScreen setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_SETTINGS.path} element={<SettingsScreen setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_BILLING.path} element={<BillingScreen setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_OFFICES.path} element={<ProfileOfficesScreen setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_SELECT_PROFILE.path} element={<SelectProfileScreen setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_SET_PROFILE_CONTEXT.path} element={<SetProfileContextScreen setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_PROFILE_EDIT.path} element={<Profile2Screen setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_DOCUMENTS.path} element={<DocumentsScreen setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_CALENDAR.path} element={<CalendarScreen setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_CONSULTATIONS.path} element={<HomeOfficeScreen setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_SELECT_CONSULTANT.path} element={<SelectConsultantScreen setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_CONSULTATION_ID.path} element={<ConsultationScreen setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_CONSULTATION_DETAILS.path} element={<ConsultationDetailsScreen setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_DOCUMENT_DETAILS.path} element={<DocumentDetailsScreen setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path={AppRoutes.ROUTE_DOCUMENT_MANAGE.path} element={<DocumentManageScreen setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />} />
                          <Route path="*" element={<Navigate to="/" />} />

                        </Routes>

                      </Box>

                    </CSSTransition>
                  </TransitionGroup>

                </Box>

                <Box>
                  <Footer setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />
                </Box>


              </Box>
            </Box>

          </ThemeProvider>
        </AppProvider>
      </AuthProvider >
    </I18nextProvider >
  );
}

export default App;
