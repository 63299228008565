import React from 'react';
import { Typography, Box, Button, Grid } from '@mui/material';
import { useAuthRedirect } from '../components/AuthContext';  // Import useAuth from AuthContext
import { Card, CardContent, CardActionArea } from '@mui/material';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { AppRoutes } from '../config/AppModes.ts';

import { ReactComponent as ConsultationsIcon } from '../img/icons/home/icon-consultations.svg';
import { ReactComponent as OfficeIcon } from '../img/icons/home/icon-offices.svg';
import { ReactComponent as DocumentsIcon } from '../img/icons/home/icon-documents.svg';
import { ReactComponent as NotificationsIcon } from '../img/icons/home/icon-notifications.svg';
import { ReactComponent as SettingsIcon } from '../img/icons/home/icon-settings.svg';
import HeaderInitial from '../header/HeaderInitial';

import imageAd from '../img/image-ad1.png';

export function HomeScreen({ setDirection, useDelayedNavigation }) {
    useAuthRedirect(setDirection, useDelayedNavigation);
    const setDelayedNavigation = useDelayedNavigation();
    const { t } = useTranslation(); // Initialize translation function

    const cards = [
        {
            icon: <ConsultationsIcon sx={{ fontSize: 50 }} />, title: t('home.consultations'),
            description: t('home.consultationsDescription'), path: AppRoutes.ROUTE_CONSULTATIONS.path
        },
        {
            icon: <DocumentsIcon sx={{ fontSize: 50 }} />, title: t('home.documents'),
            description: t('home.documentsDescription'), path: AppRoutes.ROUTE_DOCUMENTS.path
        },
        /*
        {
            icon: <NotificationsIcon sx={{ fontSize: 50 }} />, title: t('home.notifications'),
            description: t('home.notificationsDescription'), path: AppRoutes.ROUTE_CALENDAR.path
        },
        */
        {
            icon: <OfficeIcon sx={{ fontSize: 50 }} />, title: t('home.offices'),
            description: t('home.officesDescription'), path: AppRoutes.ROUTE_SELECT_OFFICE.path
        },
        {
            icon: <SettingsIcon sx={{ fontSize: 50 }} />, title: t('home.settings'),
            description: t('home.settingsDescription'), path: AppRoutes.ROUTE_SETTINGS.path
        },
    ];

    function handleCardClick(card) {
        console.log('Card clicked:', card);
        setDelayedNavigation(() => setDirection('forward'), card.path);
    }

    function handleSelectSpecialist() {
        setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_SELECT_CONSULTANT.path);
    }

    return (
        <Box sx={{
            p: 0, m: 0, display: 'flex', flexDirection: 'column',
            alignItems: 'center', width: '100%', overflowY: 'auto',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            flex: 1,
        }}>

            <Box sx={{
                pt: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',
                flexShrink: 0,
            }}>

                <HeaderInitial setDirection={setDirection} useDelayedNavigation={useDelayedNavigation} />

                <Box sx={{ width: '70%', maxWidth: '20em', mx: 2, px: 2, pt: 3, pb: 1 }}>
                    <Button sx={{ position: 'relative', width: '100%', px: 2 }}
                        variant="contained" color="primary"
                        onClick={() => { setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_SELECT_CONSULTANT.path) }}>
                        {t('home.newConsultation')}
                    </Button>
                </Box>

                <Card sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    width: 'auto',
                    mt: 3, mb: 0, mx: { xs: 2, md: 3 },
                    borderRadius: '11px',
                    border: '0px solid #14B23C' // no border for orange and green
                }} elevation={0}>
                    <CardActionArea>
                        <CardContent sx={{ p: 0, }}>
                            <Box sx={{
                                width: '100%',
                                borderRadius: '11px',
                                justifyContent: 'left',
                                display: 'flex',
                                position: 'relative',
                                overflow: 'hidden',
                            }}>
                                {/* <Box sx={{
                                    width: '7%',
                                    minWidth: '1rem',
                                    backgroundColor: '#DA4100'
                                }}></Box> */}

                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center',
                                    background: "repeating-radial-gradient(ellipse farthest-corner at left top, rgba(110,223,0,1) 0%, rgba(35,186,47,1) 32%, rgba(0,226,48,1) 63%, rgba(35,186,47,1) 78%, rgba(0,152,71,1) 99%)",
                                    // background: "linear-gradient(132deg, rgba(196,84,0,1) 2%, rgba(254,185,78,1) 87%)" warning background 
                                }}>
                                    <Typography sx={{ px: 2, pt: 1, width: '80%', textAlign: 'left', position: 'relative', color: "white" }} variant="h3">
                                        {t('home.cardTitle')}
                                    </Typography>
                                    <Typography sx={{ px: 2, pb: 2, width: '80%', textAlign: 'left', position: 'relative', color: "white" }} variant="body2">
                                        {t('home.cardDescription')}
                                    </Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </CardActionArea>
                </Card>

                <Box sx={{ p: 0, mt: 1, width: '100%', }}>
                    <Grid container spacing={2} sx={{
                        pl: { xs: 2, md: 3 }, py: { xs: 2, md: 3 }, width: '100%'
                    }} >
                        {
                            cards.map((card, index) => (
                                <Grid item xs={6} sm={6} md={4} key={index}>
                                    <Card sx={{
                                        p: 0, width: '100%',
                                        height: '100%',
                                        borderRadius: '11px',
                                        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                                        background: 'linear-gradient(209deg, rgba(255, 255, 255, 1) 28%, rgb(251 242 255) 100%)'
                                    }} elevation={0}>
                                        <CardActionArea onClick={() => { handleCardClick(card) }}>

                                            <CardContent sx={{ p: 0, position: 'relative', overflow: 'hidden', }}>
                                                {/* Gradient overlay */}
                                                <Box sx={{
                                                    position: 'absolute', // Position over the image
                                                    width: '100%', // Cover entire area
                                                    height: '100%', // Cover entire area
                                                    // background: 'linear-gradient(21deg, rgba(255, 255, 0, 0) 0%, rgba(200, 245, 250, 1) 38%, rgba(106, 211, 255, 1) 100%)'
                                                    // background: 'linear-gradient(15deg, rgba(255, 255, 255, 1) 28%, rgba(200, 245, 250, 1) 100%)', // Gradient from left to right
                                                }} />
                                                <Box sx={{ px: 2, py: 3 }}>
                                                    <Box sx={{ position: 'relative' }} align="center">
                                                        {card.icon}
                                                    </Box>
                                                    <Typography variant="h3" color="black" component="div" position="relative" align="center">
                                                        {card.title}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary" position="relative">
                                                        {card.description}
                                                    </Typography>
                                                </Box>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>

                {/* Advertisment
                    <Card sx={{ mb: 2, mt: 0, position: 'relative', overflow: 'hidden', width: '100%', background: 'transparent' }} elevation={0}>
                    <CardActionArea >
                        <CardContent sx={{ p: 3, m: 0 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                <Box sx={{
                                    p: 0,
                                    backgroundColor: '#CBE6FF',
                                    width: '100%', borderRadius: '11px',
                                    justifyContent: 'left',
                                    position: 'relative', overflow: 'hidden',
                                }}>

                                    <Grid container sx={{ height: '100%' }}>
                                        <Grid item xs={7}>
                                            <Typography sx={{ mx: 5, mt: 5, mb: 3, width: '80%', textAlign: 'left', position: 'relative' }} variant="h3">
                                                {t('home.adTitle')}
                                            </Typography>
                                            <Button sx={{ mt: 1, mb: 4, ml: 5 }} variant="contained" color="primary" onClick={handleSelectSpecialist}>
                                                {t('home.learnMore')}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Box
                                                component="img"
                                                src={imageAd}
                                                alt="Ad"
                                                sx={{
                                                    height: '100%',
                                                    width: '100%',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </CardContent>
                    </CardActionArea>
                </Card>
                */}

            </Box>
        </Box >
    );
}

export default HomeScreen;