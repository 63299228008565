import { officeTypes, chatModes, genderTypes, countries, languages } from '../../config/AppModes.ts';

export const en = {
    "send_email_code": "Sending email to {{email}} with code: {{code}}",
    "token_valid": "Token is valid",
    "token_invalid": "Token is invalid",
    "verification_error": "Error verifying token:",
    "verification_completed": "Verification completed. Authenticated: ",
    "welcome": {
        "title": "Wixee"
    },
    "splash1": {
        "title": "Smart Health Starts Here",
        "description": "Empower your family's wellness journey with personalized AI health advice tailored to each member's unique needs"
    },
    "splash2": {
        "title": "Health Insights for Everyone",
        "description": "Discover comprehensive health insights that cater to all ages, ensuring your loved ones stay healthy and happy"
    },
    "splash3": {
        "title": "Your Health, Our Priority",
        "description": "Experience the future of family healthcare with AI-driven tips and recommendations designed to keep you all in optimal health"
    },
    "splash4": {
        "title": "Let's get started!",
        "description": "Login to enjoy the features we’ve provided, and stay healthy!",
        "signInButton": "Sign In"
    },
    "login": {
        "loginWithFacebook": "Login with Facebook",
        "loginWithGoogle": "Login with Google",
        "loginWithApple": "Login with Apple",
        "loginWithEmail": "Login with Email",
        "welcome": "Welcome!",
        "signIn": "Signin to continue",
        "switchTerms": "Agree with the terms of service",
        "textTerms": "You must agree to continue using the service. The terms of service outline your rights and responsibilities while using the application. Please read and understand these terms before proceeding.",
        "switchPersonalData": "Agree to the processing of personal data",
        "textPersonalData": "Allows us to process and store your personal data. This includes data like your name, contact information, and login history. We will use this information to provide a personalized experience and improve our services.",
        "switchMedicalData": "Agree to the processing of medical data",
        "textMedicalData": "Allows us to process and share anonymized medical data with external AI systems. This helps us improve our medical insights and provide better recommendations. Your medical data will be anonymized and securely transmitted to ensure privacy."
    },
    "home": {
        "title": "Home",
        "consultations": "Consultations",
        "consultationsDescription": "Click here to see your consultations",
        "documents": "Documents",
        "documentsDescription": "Click here to upload your documents",
        "notifications": "Notifications",
        "notificationsDescription": "Click here to see all events",
        "offices": "Offices",
        "officesDescription": "Select an office to get a service",
        "settings": "Settings",
        "settingsDescription": "Change language, etc",
        "cardTitle": "Enjoy your day today",
        "cardDescription": "Your health conditions and solar activity suggest you may have complications with cognitive abilities. Relax and enjoy",
        "newConsultation": "New Consultation",
        "adTitle": "Some ad from the app",
        "learnMore": "Learn more"
    },
    "calendar": {
        "title": "Notifications",
        "created": "Created",
        "source": {
            "consultation": "from consultation",
            "document": "from document"
        }
    },
    "documents": {
        "title": "My Documents",
        "latest": "Latest",
        "lastMonth": "Last Month",
        "older": "Older",
        "upload": "Upload New Document",
        "deleted": "Deleted"
    },
    "document": {
        "title": "Document",
    },
    "header": {
        "youAndYourBeloved": "You and your beloved",
        "clickToChangeOffice": "Click to change office"
    },
    "settings": {
        "title": "Settings",
        "language": "Language",
        "country": "Country",
        "userCountry": "My Country",
        "userLanguage": "My Language",
        "userEmail": "My Email",
        "emailAddress": "Email Address",
    },
    "consultation": {
        "title": "Consultation",
        "description": "Consultation description",
        "selectConsultant": "Select Consultant",
        "manageConsultation": "Manage Consultation",
        "consultationDeleted": "Consultation deleted {{date}}",
        "download": "Download",
        "message": "Message",
        "join": {
            [genderTypes.MALE]: "Joins the conversation",
            [genderTypes.FEMALE]: "Joins the conversation",
            [genderTypes.OTHER]: "Joins the conversation",
        },
    },
    "consultations": {
        "title": "My consultations",
        "description": "Consultation description",
        "latest": "Latest",
        "seeAll": "See all",
        "lastMonth": "Last Month",
        "older": "Older",
        "deleted": "Deleted",
        "about": "about",
    },
    "profiles": {
        "title": "My loved ones",
    },
    "profile": {
        "title": "My profile",
        "billing": "My tariff",
        "offices": "My offices",
        "settings": "Settings",
        "general": "General",
        "create": "Create",
        "logout": "Logout",
        "addProfile": "Add new loved",
        "hideStoredData": "Hide stored data",
        "showStoredData": "Show stored data",
        "officeNoSummary": "No summary available",
        "termsConditions": "Terms and Conditions",
        "clickToChange": "Click to change",
        "name": "Name",
        "comment": "Comment",
        "dateOfBirth": "Date of birth",
        "timeOfBirth": "Time of birth (local)",
        "placeOfBirth": "Place of birth",
        "height": "Height (cm)",
        "weight": "Weight (kg)",
        "cm": "cm",
        "kg": "kg"
    },
    "billing": {
        "title": "Billing",
        "currentBalance": "Current Balance",
        "subscriptionPlan": "Subscription Plan",
        "subscription": {
            "free": "Free",
            "premium": "Premium",
            "exclusive": {
                "title": "Exclusive",
                "description": "An exclusive subscription plan offers members premium access to unique content, special features, and personalized services not available to regular users"
            }
        },
        "topUp": "Top Up",
        "changePlan": "Change Plan"
    },
    "offices": {
        "title": "Offices",
        [officeTypes.HEALTH]: {
            "name": "Health",
            "description": "An AI health diagnostic service analyzes user-provided symptoms and medical history to offer preliminary health condition assessments. It provides personalized insights and recommendations, guiding users on potential next steps for medical care, including when to seek professional help",
            "disclaimer": "This AI-driven service is for informational purposes only and is not a medical tool or service. It does not provide medical diagnoses, treatments, or prescriptions. All health-related decisions and actions should be based on consultation with a licensed medical professional. The developers and owners of this application are not responsible for any health outcomes based on the use of this tool. If you are experiencing a medical emergency, please call 911 or your local emergency service immediately."
        },
        [officeTypes.ALTMED]: {
            "name": "Alternative Medicine",
            "description": "An AI-driven alternative medicine service offering a range of non-traditional treatments including acupuncture, herbal medicine, and homeopathy. Using advanced algorithms, it provides personalized recommendations based on the principles of alternative healing practices. The service aims to complement conventional medical treatments by offering holistic insights into the user’s health and wellness",
            "disclaimer": "This AI-driven alternative medicine service is intended for general wellness and informational purposes only. It is not a substitute for professional medical diagnosis or treatment. Always seek advice from a qualified healthcare provider before making any health-related decisions. The developers are not responsible for any health outcomes resulting from the use of this service."
        },
        [officeTypes.PSYCHO]: {
            "name": "Psychology",
            "description": "An AI-driven psychological advisory service provides mental health support by analyzing user-reported emotions, thoughts, and behaviors. It offers personalized advice, coping strategies, and self-improvement techniques to help users manage stress, anxiety, and other psychological challenges. This service is designed to complement traditional therapy by providing users with immediate, accessible support",
            "disclaimer": "This AI-driven psychological advisory service is designed to offer general mental health support and is not a substitute for professional psychological or psychiatric advice, diagnosis, or treatment. For accurate mental health care, always consult with a licensed mental health professional. If you are in crisis or experiencing an emergency, contact a local emergency service or mental health crisis hotline. The developers and owners of this application do not assume responsibility for any outcomes based on the use of this service."
        },
        [officeTypes.VET]: {
            "name": "Pets",
            "description": "An AI-driven veterinary service helps pet owners understand their animals' health conditions by analyzing symptoms and medical history provided by users. It offers preliminary insights into potential health issues, along with recommendations for next steps, such as scheduling a vet visit or managing care at home",
            "disclaimer": "This AI-driven veterinary service is designed to provide preliminary insights into your pet’s health based on the information you provide. It is not a replacement for professional veterinary care, diagnosis, or treatment. For accurate diagnosis and treatment plans, always consult a licensed veterinarian. In case of an urgent or emergency situation with your pet, contact your local veterinary emergency service. The developers and owners of this application are not liable for any outcomes related to your pet’s health based on the use of this tool."
        },
        [officeTypes.TEACHER]: {
            "name": "Education",
            "description": "An AI-powered educational support service that offers tutoring and teaching assistance in a variety of subjects, from mathematics to the arts. The service provides personalized learning plans and answers to user questions, simulating the guidance of experienced educators. It aims to enhance learning outcomes by offering tailored educational support",
            "disclaimer": "This AI-driven teaching service is intended to complement formal education. It is not a replacement for certified teachers or accredited educational programs. Users should seek professional guidance for formal education and certifications. The developers are not responsible for any academic outcomes resulting from this service."
        },
        [officeTypes.BUSINESS]: {
            "name": "Business",
            "description": "An AI-powered business consultancy service designed to provide insights and strategies for entrepreneurs, startups, and established businesses. Leveraging data analytics, the service offers guidance on marketing, operations, finance, and growth strategies, simulating the expertise of a seasoned business consultant. It helps users navigate business challenges with AI-driven recommendations and planning tools",
            "disclaimer": "This AI-driven business consultancy service is for informational and strategic planning purposes only. It does not replace professional financial, legal, or operational advice. Users should consult qualified professionals for any critical business decisions. The developers are not liable for any business outcomes resulting from the use of this service."
        },
        [officeTypes.LEGAL]: {
            "name": "Legal",
            "description": "An AI-powered legal advisory service that provides general information on a wide range of legal topics, from contracts to intellectual property. Using advanced algorithms, it offers suggestions and insights based on user queries, simulating legal expertise. The service helps users better understand their legal situations, but it is not a substitute for personalized legal advice",
            "disclaimer": "This AI-driven legal advisory service is for informational purposes only and does not constitute legal advice. Users should consult a licensed attorney for any specific legal concerns. The developers are not responsible for any legal actions taken based on information provided by this service."
        },
        [officeTypes.LIFE]: {
            "name": "Life",
            "description": "An AI-driven personal development and life coaching service aimed at helping individuals navigate various aspects of their lives, including career planning, self-improvement, travel, and relocation. The service uses AI to offer personalized guidance and strategies for achieving life goals and improving overall well-being",
            "disclaimer": "This AI-driven life coaching service is intended for self-reflection and general advice. It is not a substitute for professional psychological, financial, or legal advice. Users should consult qualified professionals for any life-changing decisions. The developers are not liable for any decisions made based on the recommendations from this service."
        },
        [officeTypes.HOUSE]: {
            "name": "House",
            "description": "An AI-driven home services consultancy that provides guidance on home repairs, maintenance, and improvements. From plumbing and electrical work to gardening and security, the service offers tailored recommendations for home projects. By simulating expert advice, it helps users tackle everyday household tasks and renovations",
            "disclaimer": "This AI-driven home services consultancy is intended for informational purposes only. It does not replace the expertise of certified professionals in home repair, construction, or maintenance. Users should hire licensed contractors for any significant home projects. The developers are not responsible for any outcomes resulting from the use of this service."
        },
        [officeTypes.CAR]: {
            "name": "Car",
            "description": "An AI-powered automotive consultancy service that provides insights and recommendations for car maintenance, repairs, and buying/selling advice. By simulating the expertise of automotive professionals, the service helps users make informed decisions about their vehicles, from routine maintenance to major repairs and purchases",
            "disclaimer": "This AI-driven automotive consultancy service is for informational purposes only. It is not a substitute for certified mechanics or professional automotive advice. Users should consult licensed professionals for any critical vehicle-related decisions. The developers are not responsible for any outcomes related to vehicle maintenance or transactions."
        },
        [officeTypes.RELIGION]: {
            "name": "Religion",
            "description": "An AI-driven religious and spiritual guidance service that offers insights into various faiths and spiritual practices. By simulating the knowledge of religious scholars and spiritual leaders, it provides users with answers to their questions about theology, practices, and personal beliefs. The service is designed to support personal spiritual growth and understanding",
            "disclaimer": "This AI-driven religious and spiritual guidance service is intended for personal reflection and informational purposes. It does not replace the advice of religious leaders or spiritual mentors. Users should seek guidance from recognized religious authorities for any formal religious or spiritual decisions. The developers are not responsible for any actions taken based on this service."
        },
        [officeTypes.ESOTERIC]: {
            "name": "Esoteric",
            "description": "An AI-powered esoteric and mystical guidance service that provides insights into various esoteric practices, such as astrology, numerology, and energy healing. By simulating expert knowledge in these fields, it offers users personalized readings and interpretations to help them explore alternative spiritual and mystical beliefs",
            "disclaimer": "This AI-driven esoteric guidance service is intended for entertainment and personal exploration. It does not predict future events with certainty nor offer medical, psychological, or legal advice. Users should consult qualified professionals for critical life decisions. The developers are not responsible for any actions taken based on this service."
        },
        [officeTypes.TAROT]: {
            "name": "Tarot",
            "description": "An AI-driven Tarot reading service uses sophisticated algorithms to interpret Tarot card spreads and provide personalized insights into users’ questions or life situations. By simulating the wisdom of traditional Tarot, it offers guidance and clarity, delivering detailed readings and interpretations online",
            "disclaimer": "This AI-driven Tarot reading service is intended for entertainment and personal reflection purposes only. It does not predict future events with certainty, nor does it offer professional psychological, legal, or financial advice. The developers and owners of this application are not responsible for any decisions made based on Tarot readings provided by this service."
        },
        [officeTypes.ASTROLOGY]: {
            "name": "Astrology",
            "description": "An AI-driven astrology service delivers personalized astrological insights by analyzing users’ birth charts, planetary alignments, and astrological transits. It provides daily, weekly, and monthly forecasts, helping users understand their potential challenges and opportunities. This service combines traditional astrological wisdom with modern data analysis to offer accurate and meaningful predictions",
            "disclaimer": "This AI-driven astrology service offers astrological insights based on traditional interpretations of astrological charts and data. It does not provide professional psychological, financial, or legal advice. Astrology should be used for personal reflection and entertainment purposes. The developers and owners of this application are not responsible for any decisions made based on astrological insights provided."
        },
        [officeTypes.HOROSCOPE]: {
            "name": "Horoscope",
            "description": "An AI-driven horoscope service offers daily, weekly, and monthly horoscopes based on users' zodiac signs. By utilizing advanced algorithms and astrological data, the service provides personalized insights into love, career, and life events. Users receive tailored advice and predictions that align with their astrological profile, helping them navigate life's uncertainties with confidence",
            "disclaimer": "This AI-driven horoscope service provides daily, weekly, and monthly predictions based on astrological data. It is intended for entertainment and personal insight purposes only. Horoscopes do not provide guaranteed outcomes or professional advice in legal, financial, or health matters. The developers and owners of this application are not liable for any actions or decisions based on the horoscopes provided."
        },
        [officeTypes.TALISMAN]: {
            "name": "Talismans",
            "description": "An AI-driven talisman service generates personalized talisman recommendations based on users’ individual traits, desires, and spiritual needs. By analyzing astrological data, numerology, and symbolic interpretations, the service suggests talismans designed to attract positive energy, protection, or specific outcomes, offering users a modern approach to ancient mystical practices",
            "disclaimer": "This AI-driven talisman service provides recommendations for personal and spiritual exploration. It is based on symbolic interpretations and does not offer guaranteed results or professional advice in any form. The developers and owners of this application are not responsible for any outcomes related to the use of suggested talismans."
        },
    },
    "consultants": {
        "title": "Consultants",
        [chatModes.CHAT_HEALTH_RECEPTIONIST]: {
            "name": "AI Receptionist",
            "description": "Helps you choose the right specialist"
        },
        [chatModes.CHAT_HEALTH_ALLERGIST]: {
            "name": "AI Allergist",
            "description": "Specialist in diagnosing and managing allergies and related conditions"
        },
        [chatModes.CHAT_HEALTH_ANESTHESIOLOGIST]: {
            "name": "AI Anesthesiologist",
            "description": "Expert in administering anesthesia and managing pain during surgery"
        },
        [chatModes.CHAT_HEALTH_CARDIOLOGIST]: {
            "name": "AI Cardiologist",
            "description": "Heart health expert, specializing in diagnosing and treating heart conditions"
        },
        [chatModes.CHAT_HEALTH_COLOPROCTOLOGIST]: {
            "name": "AI Coloproctologist",
            "description": "Specialist in diagnosing and treating disorders of the colon, rectum, and anus"
        },
        [chatModes.CHAT_HEALTH_COSMETOLOGIST]: {
            "name": "AI Cosmetologist",
            "description": "Expert in skin care, beauty treatments, and aesthetic procedures"
        },
        [chatModes.CHAT_HEALTH_DENTIST]: {
            "name": "AI Dentist",
            "description": "Specialist in oral health, including teeth and gum care"
        },
        [chatModes.CHAT_HEALTH_DERMATOLOGIST]: {
            "name": "AI Dermatologist",
            "description": "Expert in diagnosing and treating skin conditions and diseases"
        },
        [chatModes.CHAT_HEALTH_DIETITIAN]: {
            "name": "AI Dietitian",
            "description": "Nutrition expert, helping you make informed dietary choices for better health"
        },
        [chatModes.CHAT_HEALTH_ENDOCRINOLOGIST]: {
            "name": "AI Endocrinologist",
            "description": "Specialist in hormone-related disorders and metabolism management"
        },
        [chatModes.CHAT_HEALTH_ENDOSCOPIST]: {
            "name": "AI Endoscopist",
            "description": "Expert in endoscopic procedures to diagnose and treat internal conditions"
        },
        [chatModes.CHAT_HEALTH_GASTROENTEROLOGIST]: {
            "name": "AI Gastroenterologist",
            "description": "Expert in digestive system disorders, including the stomach and intestines"
        },
        [chatModes.CHAT_HEALTH_GYNECOLOGIST]: {
            "name": "AI Gynecologist",
            "description": "Specialist in women's reproductive health, including menstruation, pregnancy, and menopause"
        },
        [chatModes.CHAT_HEALTH_MAMMOLOGIST]: {
            "name": "AI Mammologist",
            "description": "Specialist in diagnosing and treating breast-related health conditions, including breast cancer"
        },
        [chatModes.CHAT_HEALTH_IMMUNOLOGIST]: {
            "name": "AI Immunologist",
            "description": "Expert in diagnosing and treating immune system disorders"
        },
        [chatModes.CHAT_HEALTH_INFECTIOLOGIST]: {
            "name": "AI Infectiologist",
            "description": "Specialist in diagnosing and treating infectious diseases"
        },
        [chatModes.CHAT_HEALTH_NEPHROLOGIST]: {
            "name": "AI Nephrologist",
            "description": "Expert in kidney health and treating kidney-related diseases"
        },
        [chatModes.CHAT_HEALTH_NEUROLOGIST]: {
            "name": "AI Neurologist",
            "description": "Specialist in brain, spinal cord, and nervous system disorders"
        },
        [chatModes.CHAT_HEALTH_ONCOLOGIST]: {
            "name": "AI Oncologist",
            "description": "Cancer treatment specialist, focusing on diagnosing and managing cancer"
        },
        [chatModes.CHAT_HEALTH_OPHTHALMOLOGIST]: {
            "name": "AI Ophthalmologist",
            "description": "Eye specialist, diagnosing and treating vision and eye health issues"
        },
        [chatModes.CHAT_HEALTH_ORTHOPEDIST]: {
            "name": "AI Orthopedist",
            "description": "Expert in bone, joint, and muscle disorders and injuries"
        },
        [chatModes.CHAT_HEALTH_OTOLARYNGOLOGIST]: {
            "name": "AI Otolaryngologist",
            "description": "Specialist in ear, nose, and throat conditions"
        },
        [chatModes.CHAT_HEALTH_PEDIATRICIAN]: {
            "name": "AI Pediatrician",
            "description": "Child health expert, specializing in infant and child medical care"
        },
        [chatModes.CHAT_HEALTH_PHLEBOLOGIST]: {
            "name": "AI Phlebologist",
            "description": "Expert in diagnosing and treating vein disorders, including varicose veins"
        },
        [chatModes.CHAT_HEALTH_PHYSIOTHERAPIST]: {
            "name": "AI Physiotherapist",
            "description": "Specialist in physical rehabilitation and recovery from injury"
        },
        [chatModes.CHAT_HEALTH_PLASTIC_SURGEON]: {
            "name": "AI Plastic Surgeon",
            "description": "Expert in cosmetic and reconstructive surgery"
        },
        [chatModes.CHAT_HEALTH_PSYCHIATRIST]: {
            "name": "AI Psychiatrist",
            "description": "Mental health specialist, diagnosing and treating psychiatric disorders"
        },
        [chatModes.CHAT_HEALTH_PSYCHOTHERAPIST]: {
            "name": "AI Psychotherapist",
            "description": "Therapist focusing on emotional and psychological well-being through counseling"
        },
        [chatModes.CHAT_HEALTH_PULMONOLOGIST]: {
            "name": "AI Pulmonologist",
            "description": "Expert in lung and respiratory health, diagnosing and treating breathing disorders"
        },
        [chatModes.CHAT_HEALTH_REHABILITOLOGIST]: {
            "name": "AI Rehabilitologist",
            "description": "Specialist in rehabilitation medicine and recovery from illnesses"
        },
        [chatModes.CHAT_HEALTH_RHEUMATOLOGIST]: {
            "name": "AI Rheumatologist",
            "description": "Specialist in arthritis, joint, and autoimmune diseases"
        },
        [chatModes.CHAT_HEALTH_SEXOLOGIST]: {
            "name": "AI Sexologist",
            "description": "Sexual health expert, addressing sexual function and relationships"
        },
        [chatModes.CHAT_HEALTH_SOMNOLOGIST]: {
            "name": "AI Somnologist",
            "description": "Expert in diagnosing and treating sleep disorders"
        },
        [chatModes.CHAT_HEALTH_SURGEON]: {
            "name": "AI General Surgeon",
            "description": "Specialist in surgical care for a wide range of medical conditions"
        },
        [chatModes.CHAT_HEALTH_THERAPIST]: {
            "name": "AI Therapist",
            "description": "General health expert, offering advice on common medical issues"
        },
        [chatModes.CHAT_HEALTH_TRAUMATOLOGIST]: {
            "name": "AI Traumatologist",
            "description": "Specialist in diagnosing and treating injuries and trauma"
        },
        [chatModes.CHAT_HEALTH_UROLOGIST]: {
            "name": "AI Urologist",
            "description": "Specialist in urinary tract health and male reproductive system"
        },
        [chatModes.CHAT_HEALTH_VASCULAR_SURGEON]: {
            "name": "AI Vascular Surgeon",
            "description": "Expert in treating disorders of the blood vessels"
        },
        [chatModes.CHAT_HEALTH_PHARMACIST]: {
            "name": "AI Pharmacist",
            "description": "Expert in selection and use of medicinal products"
        },
        [chatModes.CHAT_ALTMED_ACUPUNCTURE]: {
            "name": "AI Acupuncturist",
            "description": "Specialist in acupuncture, using fine needles to stimulate energy points in the body to promote healing and balance."
        },
        [chatModes.CHAT_ALTMED_HERBAL_MEDICINE]: {
            "name": "AI Herbal Medicine Specialist",
            "description": "Expert in the use of medicinal plants and herbs to support health and treat various conditions naturally."
        },
        [chatModes.CHAT_ALTMED_HOMEOPATHY]: {
            "name": "AI Homeopath",
            "description": "Homeopathic expert offering treatments based on the principle of 'like cures like' using highly diluted substances."
        },
        [chatModes.CHAT_ALTMED_REFLEXOLOGY]: {
            "name": "AI Reflexologist",
            "description": "Specialist in reflexology, focusing on applying pressure to specific areas of the feet, hands, and ears to promote healing in corresponding body parts."
        },
        [chatModes.CHAT_ALTMED_REIKI]: {
            "name": "AI Reiki Practitioner",
            "description": "Expert in Reiki, a Japanese energy healing technique, using gentle touch or hand positions to balance energy and promote relaxation."
        },
        [chatModes.CHAT_ALTMED_CHIROPRACTOR]: {
            "name": "AI Chiropractor",
            "description": "Chiropractic specialist who focuses on diagnosing and treating musculoskeletal disorders, particularly spine-related issues, through manual adjustments."
        },
        [chatModes.CHAT_ALTMED_NATUROPATHY]: {
            "name": "AI Naturopath",
            "description": "Specialist in naturopathy, combining natural therapies such as nutrition, herbal remedies, and lifestyle changes to support health and prevent disease."
        },
        [chatModes.CHAT_ALTMED_AROMATHERAPY]: {
            "name": "AI Aromatherapist",
            "description": "Expert in aromatherapy, using essential oils from plants to improve emotional and physical well-being through inhalation or topical application."
        },
        [chatModes.CHAT_ALTMED_AYURVEDA]: {
            "name": "AI Ayurveda Specialist",
            "description": "Practitioner of Ayurveda, an ancient Indian system of medicine that promotes balance between mind, body, and spirit through diet, herbal treatments, and physical practices."
        },
        [chatModes.CHAT_ALTMED_TCM]: {
            "name": "AI Traditional Chinese Medicine Specialist",
            "description": "Expert in Traditional Chinese Medicine (TCM), using a holistic approach involving acupuncture, herbal medicine, and Qi regulation to restore balance and health."
        },
        [chatModes.CHAT_ALTMED_HYDROTHERAPY]: {
            "name": "AI Hydrotherapist",
            "description": "Specialist in hydrotherapy, using water treatments like baths, steam, and compresses to promote healing, reduce pain, and improve circulation."
        },
        [chatModes.CHAT_ALTMED_BIOENERGETICS]: {
            "name": "AI Bioenergetics Specialist",
            "description": "Expert in bioenergetics, focusing on the body’s energy systems to release stored emotional and physical tension through movement and breathing exercises."
        },
        [chatModes.CHAT_ALTMED_HYPNOTHERAPY]: {
            "name": "AI Hypnotherapist",
            "description": "Hypnotherapy specialist, using guided relaxation and focused attention to help individuals access their subconscious mind and change habits or reduce stress."
        },
        [chatModes.CHAT_ALTMED_CRANIOSACRAL]: {
            "name": "AI Craniosacral Therapist",
            "description": "Practitioner of craniosacral therapy, using gentle manipulations of the skull and spine to relieve tension and improve overall health."
        },
        [chatModes.CHAT_ALTMED_SOUND_THERAPY]: {
            "name": "AI Sound Therapist",
            "description": "Specialist in sound therapy, using sounds, vibrations, and music to promote relaxation, reduce stress, and balance the body’s energy."
        },
        [chatModes.CHAT_ALTMED_THERMOTHERAPY]: {
            "name": "AI Thermotherapy Specialist",
            "description": "Expert in thermotherapy, utilizing heat treatments such as hot packs, infrared therapy, and thermal baths to relieve pain and improve circulation."
        },
        [chatModes.CHAT_ALTMED_FITOTHERAPY]: {
            "name": "AI Phytotherapist",
            "description": "Specialist in phytotherapy, focusing on the use of plant-based medicines to prevent and treat a wide range of health issues."
        },
        [chatModes.CHAT_PSYCHO_REGULAR]: {
            "name": "AI General Psychologist",
            "description": "Psychologist specializing in general mental health issues, offering support for a wide range of emotional and psychological concerns"
        },
        [chatModes.CHAT_PSYCHO_FAMILY]: {
            "name": "AI Family Psychologist",
            "description": "Specialist in family dynamics and relationships, providing therapy to improve communication and resolve conflicts within families"
        },
        [chatModes.CHAT_PSYCHO_COUPLES]: {
            "name": "AI Couples Psychologist",
            "description": "Expert in relationship counseling, helping couples address challenges and improve their emotional connection"
        },
        [chatModes.CHAT_PSYCHO_CHILD]: {
            "name": "AI Child Psychologist",
            "description": "Specialist in child psychology, focused on emotional, social, and behavioral development in children"
        },
        [chatModes.CHAT_PSYCHO_SCHOOL]: {
            "name": "AI School Psychologist",
            "description": "Expert in educational and developmental psychology, supporting students with academic, social, and emotional challenges in school"
        },
        [chatModes.CHAT_PSYCHO_COGNITIVE_BEHAVIORAL]: {
            "name": "AI Cognitive Behavioral Therapist",
            "description": "Psychologist using Cognitive Behavioral Therapy (CBT) to help individuals change negative thought patterns and behaviors"
        },
        [chatModes.CHAT_PSYCHO_GESTALT]: {
            "name": "AI Gestalt Psychologist",
            "description": "Specialist in Gestalt therapy, focusing on self-awareness and the present moment to improve emotional well-being"
        },
        [chatModes.CHAT_PSYCHO_PSYCHODYNAMIC]: {
            "name": "AI Psychodynamic Psychologist",
            "description": "Expert in psychodynamic therapy, exploring unconscious thoughts and emotions to understand current behaviors"
        },
        [chatModes.CHAT_PSYCHO_CLINICAL]: {
            "name": "AI Clinical Psychologist",
            "description": "Clinical psychologist specializing in diagnosing and treating serious mental health disorders and providing therapeutic interventions"
        },
        [chatModes.CHAT_PSYCHO_SPORTS]: {
            "name": "AI Sports Psychologist",
            "description": "Specialist in sports psychology, helping athletes improve performance and mental resilience in competitive environments"
        },
        [chatModes.CHAT_PSYCHO_ORGANIZATIONAL]: {
            "name": "AI Organizational Psychologist",
            "description": "Expert in workplace psychology, providing insights on employee well-being, organizational behavior, and productivity"
        },
        [chatModes.CHAT_PSYCHO_TRAUMA]: {
            "name": "AI Trauma Psychologist",
            "description": "Specialist in trauma therapy, helping individuals recover from emotional and psychological trauma"
        },
        [chatModes.CHAT_PSYCHO_NEUROPSYCHOLOGIST]: {
            "name": "AI Neuropsychologist",
            "description": "Expert in neuropsychology, focusing on how brain function affects behavior, cognition, and emotions"
        },
        [chatModes.CHAT_PSYCHO_EATING_DISORDER]: {
            "name": "AI Eating Disorder Psychologist",
            "description": "Psychologist specializing in treating eating disorders such as anorexia, bulimia, and binge eating"
        },
        [chatModes.CHAT_PSYCHO_NARCOLOGIST]: {
            "name": "AI Addiction Psychologist",
            "description": "Expert in treating addictions, including substance abuse and addictive behaviors"
        },
        [chatModes.CHAT_PSYCHO_ADDICTION]: {
            "name": "AI Addiction Specialist",
            "description": "Specialist in addiction therapy, providing support for overcoming substance abuse and behavioral addictions"
        },
        [chatModes.CHAT_PSYCHO_GRIEF]: {
            "name": "AI Grief Psychologist",
            "description": "Expert in grief counseling, helping individuals process loss and cope with bereavement"
        },
        [chatModes.CHAT_PSYCHO_WORKPLACE]: {
            "name": "AI Workplace Psychologist",
            "description": "Psychologist focused on mental health issues related to work environments, including stress and work-life balance"
        },
        [chatModes.CHAT_PSYCHO_SEXUALITY]: {
            "name": "AI Sexuality Psychologist",
            "description": "Specialist in sexual health and relationships, addressing concerns related to sexuality and sexual well-being"
        },
        [chatModes.CHAT_PSYCHO_LGBTQ]: {
            "name": "AI LGBTQ+ Psychologist",
            "description": "Psychologist providing support for LGBTQ+ individuals, focusing on identity, relationships, and mental health"
        },
        [chatModes.CHAT_VET_RECEPTIONIST]: {
            "name": "AI Veterinary Receptionist",
            "description": "Helps you choose the right veterinary specialist"
        },
        [chatModes.CHAT_VET_ALLERGIST]: {
            "name": "AI Veterinary Allergist",
            "description": "Specialist in diagnosing and treating allergies in animals"
        },
        [chatModes.CHAT_VET_ANESTHESIOLOGIST]: {
            "name": "AI Veterinary Anesthesiologist",
            "description": "Expert in providing anesthesia and pain management for animals during surgery"
        },
        [chatModes.CHAT_VET_CARDIOLOGIST]: {
            "name": "AI Veterinary Cardiologist",
            "description": "Specialist in heart and cardiovascular health for pets"
        },
        [chatModes.CHAT_VET_COLOPROCTOLOGIST]: {
            "name": "AI Veterinary Coloproctologist",
            "description": "Expert in diagnosing and treating colon and rectal conditions in animals"
        },
        [chatModes.CHAT_VET_COSMETOLOGIST]: {
            "name": "AI Veterinary Cosmetologist",
            "description": "Specialist in animal skin care and aesthetic treatments"
        },
        [chatModes.CHAT_VET_DENTIST]: {
            "name": "AI Veterinary Dentist",
            "description": "Specialist in dental care and oral health for animals"
        },
        [chatModes.CHAT_VET_DERMATOLOGIST]: {
            "name": "AI Veterinary Dermatologist",
            "description": "Expert in diagnosing and treating skin conditions in pets"
        },
        [chatModes.CHAT_VET_DIETITIAN]: {
            "name": "AI Veterinary Dietitian",
            "description": "Specialist in animal nutrition and dietary needs for pets"
        },
        [chatModes.CHAT_VET_ENDOCRINOLOGIST]: {
            "name": "AI Veterinary Endocrinologist",
            "description": "Expert in hormonal and metabolic disorders in animals"
        },
        [chatModes.CHAT_VET_ENDOSCOPIST]: {
            "name": "AI Veterinary Endoscopist",
            "description": "Specialist in performing endoscopic procedures to diagnose internal conditions in animals"
        },
        [chatModes.CHAT_VET_GASTROENTEROLOGIST]: {
            "name": "AI Veterinary Gastroenterologist",
            "description": "Expert in diagnosing and treating digestive system disorders in pets"
        },
        [chatModes.CHAT_VET_GYNECOLOGIST]: {
            "name": "AI Veterinary Gynecologist",
            "description": "Specialist in reproductive health and care for female animals"
        },
        [chatModes.CHAT_VET_IMMUNOLOGIST]: {
            "name": "AI Veterinary Immunologist",
            "description": "Expert in diagnosing and managing immune system disorders in pets"
        },
        [chatModes.CHAT_VET_INFECTIOLOGIST]: {
            "name": "AI Veterinary Infectiologist",
            "description": "Specialist in diagnosing and treating infectious diseases in animals"
        },
        [chatModes.CHAT_VET_NEPHROLOGIST]: {
            "name": "AI Veterinary Nephrologist",
            "description": "Expert in kidney health and treating renal disorders in animals"
        },
        [chatModes.CHAT_VET_NEUROLOGIST]: {
            "name": "AI Veterinary Neurologist",
            "description": "Specialist in treating brain, spinal cord, and nervous system conditions in animals"
        },
        [chatModes.CHAT_VET_ONCOLOGIST]: {
            "name": "AI Veterinary Oncologist",
            "description": "Expert in diagnosing and treating cancer in pets"
        },
        [chatModes.CHAT_VET_OPHTHALMOLOGIST]: {
            "name": "AI Veterinary Ophthalmologist",
            "description": "Specialist in eye health and vision care for animals"
        },
        [chatModes.CHAT_VET_ORTHOPEDIST]: {
            "name": "AI Veterinary Orthopedist",
            "description": "Expert in diagnosing and treating bone, joint, and musculoskeletal conditions in pets"
        },
        [chatModes.CHAT_VET_OTOLARYNGOLOGIST]: {
            "name": "AI Veterinary Otolaryngologist",
            "description": "Specialist in ear, nose, and throat conditions in animals"
        },
        [chatModes.CHAT_VET_PEDIATRICIAN]: {
            "name": "AI Veterinary Pediatrician",
            "description": "Expert in health and care for young animals, including puppies and kittens"
        },
        [chatModes.CHAT_VET_PHLEBOLOGIST]: {
            "name": "AI Veterinary Phlebologist",
            "description": "Specialist in treating vein conditions and circulatory disorders in animals"
        },
        [chatModes.CHAT_VET_PHYSIOTHERAPIST]: {
            "name": "AI Veterinary Physiotherapist",
            "description": "Expert in rehabilitation and physical therapy for animals recovering from injury or surgery"
        },
        [chatModes.CHAT_VET_PLASTIC_SURGEON]: {
            "name": "AI Veterinary Plastic Surgeon",
            "description": "Specialist in cosmetic and reconstructive surgery for animals"
        },
        [chatModes.CHAT_VET_PSYCHIATRIST]: {
            "name": "AI Veterinary Psychiatrist",
            "description": "Expert in diagnosing and treating mental health and behavioral issues in pets"
        },
        [chatModes.CHAT_VET_PSYCHOTHERAPIST]: {
            "name": "AI Veterinary Psychotherapist",
            "description": "Specialist in behavioral therapy and emotional well-being for animals"
        },
        [chatModes.CHAT_VET_PULMONOLOGIST]: {
            "name": "AI Veterinary Pulmonologist",
            "description": "Expert in diagnosing and treating lung and respiratory conditions in pets"
        },
        [chatModes.CHAT_VET_RHEUMATOLOGIST]: {
            "name": "AI Veterinary Rheumatologist",
            "description": "Specialist in diagnosing and treating arthritis and autoimmune disorders in animals"
        },
        [chatModes.CHAT_VET_SEXOLOGIST]: {
            "name": "AI Veterinary Sexologist",
            "description": "Specialist in animal sexual health and reproduction"
        },
        [chatModes.CHAT_VET_SOMNOLOGIST]: {
            "name": "AI Veterinary Somnologist",
            "description": "Expert in diagnosing and treating sleep disorders in pets"
        },
        [chatModes.CHAT_VET_SURGEON]: {
            "name": "AI Veterinary General Surgeon",
            "description": "Specialist in performing surgeries for a wide range of animal health issues"
        },
        [chatModes.CHAT_VET_THERAPIST]: {
            "name": "AI Veterinary Therapist",
            "description": "General health specialist, providing care for common conditions in animals"
        },
        [chatModes.CHAT_VET_TRAINER]: {
            "name": "AI Animal Trainer",
            "description": "An animal trainer teaches pets for various commands and techniques"
        },
        [chatModes.CHAT_VET_TRAUMATOLOGIST]: {
            "name": "AI Veterinary Traumatologist",
            "description": "Expert in diagnosing and treating injuries and trauma in animals"
        },
        [chatModes.CHAT_VET_UROLOGIST]: {
            "name": "AI Veterinary Urologist",
            "description": "Specialist in urinary tract and reproductive system health for pets"
        },
        [chatModes.CHAT_VET_VASCULAR_SURGEON]: {
            "name": "AI Veterinary Vascular Surgeon",
            "description": "Expert in diagnosing and treating conditions of the blood vessels in animals"
        },
        [chatModes.CHAT_TEACHER_LANGUAGE]: {
            "name": "AI Languages Teacher",
            "description": "Specialist in language instruction, helping students improve their language skills and fluency in both native and foreign languages"
        },
        [chatModes.CHAT_TEACHER_LITERATURE]: {
            "name": "AI Literature Teacher",
            "description": "Expert in literature, guiding students through the study of classic and modern literary works to enhance their critical reading and analytical skills"
        },
        [chatModes.CHAT_TEACHER_MATH]: {
            "name": "AI Math Teacher",
            "description": "Specialist in mathematics, helping students grasp complex mathematical concepts and improve problem-solving skills across various math disciplines"
        },
        [chatModes.CHAT_TEACHER_GEOMETRY]: {
            "name": "AI Geometry Teacher",
            "description": "Expert in geometry, guiding students through the study of shapes, angles, and spatial reasoning"
        },
        [chatModes.CHAT_TEACHER_PHYSICS]: {
            "name": "AI Physics Teacher",
            "description": "Physics expert providing guidance in understanding the laws of physics and their real-world applications through interactive learning"
        },
        [chatModes.CHAT_TEACHER_ASTRONOMY]: {
            "name": "AI Astronomy Teacher",
            "description": "Specialist in astronomy, helping students explore the universe, celestial bodies, and cosmic phenomena"
        },
        [chatModes.CHAT_TEACHER_CHEMISTRY]: {
            "name": "AI Chemistry Teacher",
            "description": "Specialist in chemistry, teaching students the fundamentals of chemical reactions, molecular structures, and lab safety"
        },
        [chatModes.CHAT_TEACHER_ART]: {
            "name": "AI Art Teacher",
            "description": "Expert in visual arts, encouraging students to express creativity and learn various artistic techniques such as painting, drawing, and sculpture"
        },
        [chatModes.CHAT_TEACHER_MUSIC]: {
            "name": "AI Music Teacher",
            "description": "Music educator helping students understand musical theory, improve instrument performance, and develop a deep appreciation for music"
        },
        [chatModes.CHAT_TEACHER_PHYSICAL_EDUCATION]: {
            "name": "AI Physical Education Teacher",
            "description": "Physical education expert focusing on promoting physical fitness, teaching sports, and encouraging a healthy and active lifestyle"
        },
        [chatModes.CHAT_TEACHER_TECHNOLOGY]: {
            "name": "AI Technology Teacher",
            "description": "Expert in technology education, teaching students how to effectively use digital tools and understand the impact of technology in daily life"
        },
        [chatModes.CHAT_TEACHER_COMPUTER_SCIENCE]: {
            "name": "AI Computer Science Teacher",
            "description": "Computer science expert teaching students the fundamentals of computing, software engineering, and algorithmic problem solving"
        },
        [chatModes.CHAT_TEACHER_DATA_SCIENCE]: {
            "name": "AI Data Science Teacher",
            "description": "Specialist in data science, providing instruction on data analysis, statistics, and machine learning, preparing students for careers in tech"
        },
        [chatModes.CHAT_TEACHER_PROGRAMMING]: {
            "name": "AI Programming Teacher",
            "description": "Expert in programming, guiding students through coding languages and helping them build software development skills"
        },
        [chatModes.CHAT_TEACHER_GRAPHIC_DESIGN]: {
            "name": "AI Graphic Design Teacher",
            "description": "Specialist in graphic design, teaching students the fundamentals of digital design, including visual communication and software tools"
        },
        [chatModes.CHAT_TEACHER_LAW]: {
            "name": "AI Law Teacher",
            "description": "Legal educator helping students understand the foundations of law, legal systems, and preparing them for careers in the legal field"
        },
        [chatModes.CHAT_TEACHER_CIVICS]: {
            "name": "AI Civics Teacher",
            "description": "Expert in civics education, teaching students about government, citizenship, and their roles and responsibilities in society"
        },
        [chatModes.CHAT_TEACHER_HISTORY]: {
            "name": "AI History Teacher",
            "description": "Specialist in history, guiding students through the study of past events and civilizations to better understand the present"
        },
        [chatModes.CHAT_TEACHER_GEOGRAPHY]: {
            "name": "AI Geography Teacher",
            "description": "Geography expert helping students understand the physical features of the earth and human-environment interactions"
        },
        [chatModes.CHAT_TEACHER_BIOLOGY]: {
            "name": "AI Biology Teacher",
            "description": "Biology specialist providing instruction on living organisms, ecosystems, and the principles of life sciences"
        },
        [chatModes.CHAT_TEACHER_ENVIRONMENTAL_SCIENCE]: {
            "name": "AI Environmental Science Teacher",
            "description": "Environmental science expert teaching students about ecosystems, sustainability, and the impact of human activity on the environment"
        },
        [chatModes.CHAT_TEACHER_RELIGION]: {
            "name": "AI Religion Teacher",
            "description": "Expert in religious studies, helping students explore various world religions, their beliefs, practices, and cultural significance"
        },
        [chatModes.CHAT_TEACHER_PHILOSOPHY]: {
            "name": "AI Philosophy Teacher",
            "description": "Philosophy educator guiding students through the exploration of existential questions, ethics, and the history of philosophical thought"
        },
        [chatModes.CHAT_TEACHER_ECONOMICS]: {
            "name": "AI Economics Teacher",
            "description": "Economics educator guiding students through microeconomics, macroeconomics, and financial literacy"
        },
        [chatModes.CHAT_TEACHER_BUSINESS]: {
            "name": "AI Business Teacher",
            "description": "Business expert teaching students entrepreneurial skills, business management, and economic principles"
        },
        [chatModes.CHAT_TEACHER_MARKETING]: {
            "name": "AI Marketing Teacher",
            "description": "Marketing specialist teaching students about branding, market research, and effective advertising strategies"
        },
        [chatModes.CHAT_TEACHER_SOCIOLOGY]: {
            "name": "AI Sociology Teacher",
            "description": "Sociology expert helping students explore social structures, human behavior, and cultural interactions"
        },
        [chatModes.CHAT_TEACHER_PSYCHOLOGY]: {
            "name": "AI Psychology Teacher",
            "description": "Psychology educator teaching students about the human mind, behavior, and cognitive processes"
        },
        [chatModes.CHAT_TEACHER_MEDICINE]: {
            "name": "AI Medicine Teacher",
            "description": "Expert in medical education, helping students understand anatomy, physiology, and preparing for careers in healthcare"
        },
        [chatModes.CHAT_TEACHER_DRAMA]: {
            "name": "AI Drama Teacher",
            "description": "Drama educator teaching students acting techniques, theater production, and performance skills"
        },
        [chatModes.CHAT_TEACHER_ORATORY]: {
            "name": "AI Oratory Teacher",
            "description": "Expert in public speaking, helping students develop communication and presentation skills for various contexts"
        },
        [chatModes.CHAT_TEACHER_FILMMAKING]: {
            "name": "AI Filmmaking Teacher",
            "description": "Specialist in filmmaking, guiding students through the creative and technical aspects of film production"
        },
        [chatModes.CHAT_BUSINESS_STRATEGY]: {
            "name": "AI Business Strategy Expert",
            "description": "Specialist in crafting business strategies, helping companies set long-term goals and create actionable plans to achieve success"
        },
        [chatModes.CHAT_BUSINESS_DEVELOPMENT]: {
            "name": "AI Business Development Expert",
            "description": "Focused on growing businesses by identifying new markets, opportunities, and partnerships to drive revenue and expansion"
        },
        [chatModes.CHAT_BUSINESS_STARTUP]: {
            "name": "AI Startup Consultant",
            "description": "Specialist in startup growth, providing guidance on launching new ventures, scaling operations, and navigating challenges unique to startups"
        },
        [chatModes.CHAT_BUSINESS_INVESTMENT]: {
            "name": "AI Investment Consultant",
            "description": "Expert in investment strategies, advising on portfolio management, funding, and securing financial backing for businesses"
        },
        [chatModes.CHAT_BUSINESS_FINANCE]: {
            "name": "AI Finance Expert",
            "description": "Specialist in financial management, offering advice on budgeting, cash flow, and financial planning to optimize business performance"
        },
        [chatModes.CHAT_BUSINESS_MARKETING]: {
            "name": "AI Marketing Consultant",
            "description": "Expert in marketing strategy, helping businesses enhance their brand, engage customers, and increase market reach"
        },
        [chatModes.CHAT_BUSINESS_SALES]: {
            "name": "AI Sales Consultant",
            "description": "Focused on improving sales processes, providing techniques to boost sales performance, client engagement, and revenue generation"
        },
        [chatModes.CHAT_BUSINESS_HR]: {
            "name": "AI Human Resources Consultant",
            "description": "Specialist in HR, offering advice on talent acquisition, employee engagement, and workforce management"
        },
        [chatModes.CHAT_BUSINESS_OPERATIONS]: {
            "name": "AI Operations Management Consultant",
            "description": "Expert in streamlining business operations to enhance efficiency, reduce costs, and improve overall productivity"
        },
        [chatModes.CHAT_BUSINESS_TECHNOLOGY]: {
            "name": "AI Technology Consultant",
            "description": "Specialist in leveraging technology to drive innovation, streamline processes, and enhance business growth"
        },
        [chatModes.CHAT_BUSINESS_IT]: {
            "name": "AI IT Consultant",
            "description": "Expert in information technology, helping businesses manage their IT infrastructure and implement technological solutions"
        },
        [chatModes.CHAT_BUSINESS_LEGAL]: {
            "name": "AI Business Legal Consultant",
            "description": "Specialist in legal matters, providing advice on corporate law, compliance, and contract management for businesses"
        },
        [chatModes.CHAT_BUSINESS_ACCOUNTING]: {
            "name": "AI Accounting Consultant",
            "description": "Expert in financial accounting, helping businesses maintain accurate financial records and comply with accounting standards"
        },
        [chatModes.CHAT_BUSINESS_TAX]: {
            "name": "AI Tax Consultant",
            "description": "Specialist in tax optimization, offering strategies to minimize tax liabilities and ensure compliance with regulations"
        },
        [chatModes.CHAT_BUSINESS_TRAVEL]: {
            "name": "AI Business Travel Consultant",
            "description": "Focused on organizing corporate travel, optimizing travel budgets, and ensuring seamless logistics for business trips"
        },
        [chatModes.CHAT_BUSINESS_DOCUMENT_MANAGEMENT]: {
            "name": "AI Document Management Expert",
            "description": "Specialist in document workflow, offering solutions for improving efficiency in handling business documents and records"
        },
        [chatModes.CHAT_BUSINESS_SUPPLY_CHAIN]: {
            "name": "AI Supply Chain Consultant",
            "description": "Expert in managing supply chains, optimizing procurement, logistics, and inventory for cost-effective operations"
        },
        [chatModes.CHAT_BUSINESS_PROJECT_MANAGEMENT]: {
            "name": "AI Project Management Consultant",
            "description": "Focused on project management best practices, helping businesses deliver projects on time, within scope, and on budget"
        },
        [chatModes.CHAT_BUSINESS_CHANGE_MANAGEMENT]: {
            "name": "AI Change Management Consultant",
            "description": "Specialist in guiding organizations through change, ensuring smooth transitions and minimizing disruption to operations"
        },
        [chatModes.CHAT_BUSINESS_RISK_MANAGEMENT]: {
            "name": "AI Risk Management Consultant",
            "description": "Expert in identifying and mitigating business risks, helping companies build resilience and protect against potential threats"
        },
        [chatModes.CHAT_BUSINESS_SUSTAINABILITY]: {
            "name": "AI Sustainability Consultant",
            "description": "Focused on sustainable business practices, advising on strategies to reduce environmental impact and enhance corporate responsibility"
        },
        [chatModes.CHAT_BUSINESS_INNOVATION]: {
            "name": "AI Innovation Consultant",
            "description": "Specialist in fostering innovation, helping businesses develop new products, services, and processes to stay competitive"
        },
        [chatModes.CHAT_BUSINESS_EXPORT_IMPORT]: {
            "name": "AI Export/Import Consultant",
            "description": "Expert in international trade, advising on regulations, logistics, and strategies for successful export and import operations"
        },
        [chatModes.CHAT_BUSINESS_FRANCHISING]: {
            "name": "AI Franchising Consultant",
            "description": "Specialist in franchising, helping businesses expand their brand through franchise development and management"
        },
        [chatModes.CHAT_BUSINESS_MERGE_ACQUISITION]: {
            "name": "AI Merge and Acquisition Consultant",
            "description": "Expert in M&A, offering guidance on deal structuring, due diligence, and post-merger integration"
        },
        [chatModes.CHAT_BUSINESS_CUSTOMER_SERVICE]: {
            "name": "AI Customer Service Consultant",
            "description": "Focused on improving customer service operations, enhancing client satisfaction, and building customer loyalty"
        },
        [chatModes.CHAT_BUSINESS_COMPLIANCE]: {
            "name": "AI Compliance Consultant",
            "description": "Specialist in regulatory compliance, helping businesses ensure they meet industry standards and legal requirements"
        },
        [chatModes.CHAT_BUSINESS_VALUATION]: {
            "name": "AI Business Valuation Expert",
            "description": "Expert in business valuation, providing insights into company worth and advising on mergers, acquisitions, and investments"
        },
        [chatModes.CHAT_BUSINESS_COACH]: {
            "name": "AI Business Coach",
            "description": "Specialist in business coaching, providing personalized guidance to business leaders to help them grow and achieve their goals"
        },
        [chatModes.CHAT_BUSINESS_PSYCHOLOGIST]: {
            "name": "AI Business Psychologist",
            "description": "Expert in business psychology, focusing on improving workplace dynamics, leadership development, and employee well-being"
        },
        [chatModes.CHAT_LEGAL_GENERAL]: {
            "name": "AI General Legal Consultant",
            "description": "A general legal expert providing advice on a broad range of legal issues, including contracts, property, and more"
        },
        [chatModes.CHAT_LEGAL_CONTRACTS]: {
            "name": "AI Contracts Legal Consultant",
            "description": "Specialist in contract law, offering guidance on drafting, reviewing, and enforcing legal agreements"
        },
        [chatModes.CHAT_LEGAL_NOTARY]: {
            "name": "AI Notary",
            "description": "Certified notary providing services such as notarizing documents, administering oaths, and witnessing signatures"
        },
        [chatModes.CHAT_LEGAL_CORPORATE]: {
            "name": "AI Corporate Lawyer",
            "description": "Expert in corporate law, assisting businesses with legal structure, governance, and compliance"
        },
        [chatModes.CHAT_LEGAL_TAX]: {
            "name": "AI Tax Lawyer",
            "description": "Specialist in tax law, helping individuals and businesses navigate tax regulations and optimize tax planning"
        },
        [chatModes.CHAT_LEGAL_LABOR]: {
            "name": "AI Labor Lawyer",
            "description": "Expert in labor law, advising on employment contracts, workplace disputes, and employee rights"
        },
        [chatModes.CHAT_LEGAL_INTELLECTUAL_PROPERTY]: {
            "name": "AI Intellectual Property Lawyer",
            "description": "Specialist in protecting intellectual property rights, including patents, trademarks, and copyrights"
        },
        [chatModes.CHAT_LEGAL_LITIGATION]: {
            "name": "AI Litigation Lawyer",
            "description": "Litigation expert offering legal representation and guidance through court proceedings and disputes"
        },
        [chatModes.CHAT_LEGAL_COURT]: {
            "name": "AI Court Simulator",
            "description": "Simulated court proceedings for educational or preparatory purposes, mimicking real-life legal processes"
        },
        [chatModes.CHAT_LEGAL_MERGES_ACQUISITIONS]: {
            "name": "AI Mergers and Acquisitions Lawyer",
            "description": "Specialist in corporate transactions, offering expertise in mergers, acquisitions, and restructuring deals"
        },
        [chatModes.CHAT_LEGAL_ADMINISTRATIVE]: {
            "name": "AI Administrative Lawyer",
            "description": "Expert in administrative law, providing guidance on government regulations and agency actions"
        },
        [chatModes.CHAT_LEGAL_ARBITRATION]: {
            "name": "AI Arbitration Lawyer",
            "description": "Specialist in arbitration, resolving disputes outside of court through binding or non-binding arbitration"
        },
        [chatModes.CHAT_LEGAL_CRIMINAL]: {
            "name": "AI Criminal Lawyer",
            "description": "Expert in criminal law, defending individuals or prosecuting criminal cases in court"
        },
        [chatModes.CHAT_LEGAL_FAMILY]: {
            "name": "AI Family Lawyer",
            "description": "Specialist in family law, advising on matters such as divorce, child custody, and alimony"
        },
        [chatModes.CHAT_LEGAL_REAL_ESTATE]: {
            "name": "AI Real Estate Lawyer",
            "description": "Expert in real estate law, offering guidance on property transactions, leases, and disputes"
        },
        [chatModes.CHAT_LEGAL_INTERNATIONAL]: {
            "name": "AI International Lawyer",
            "description": "Specialist in international law, advising on cross-border transactions, treaties, and global legal matters"
        },
        [chatModes.CHAT_LEGAL_IMMIGRATION]: {
            "name": "AI Immigration Lawyer",
            "description": "Expert in immigration law, assisting with visas, residency, and citizenship applications"
        },
        [chatModes.CHAT_LEGAL_CONSUMER_PROTECTION]: {
            "name": "AI Consumer Protection Lawyer",
            "description": "Specialist in consumer rights, offering advice on issues related to fraud, product liability, and unfair business practices"
        },
        [chatModes.CHAT_LEGAL_ENVIRONMENTAL]: {
            "name": "AI Environmental Lawyer",
            "description": "Expert in environmental law, advising on regulations related to environmental protection and sustainability"
        },
        [chatModes.CHAT_LEGAL_DATA_PRIVACY]: {
            "name": "AI Data Privacy Lawyer",
            "description": "Specialist in data protection, helping businesses and individuals comply with data privacy laws and regulations"
        },
        [chatModes.CHAT_LEGAL_HEALTHCARE]: {
            "name": "AI Healthcare Lawyer",
            "description": "Expert in healthcare law, providing legal guidance to healthcare providers on regulatory compliance and patient rights"
        },
        [chatModes.CHAT_LEGAL_BANKRUPTCY]: {
            "name": "AI Bankruptcy Lawyer",
            "description": "Specialist in bankruptcy law, helping businesses and individuals navigate insolvency, restructuring, and debt relief"
        },
        [chatModes.CHAT_LEGAL_COMPLIANCE]: {
            "name": "AI Compliance Lawyer",
            "description": "Expert in regulatory compliance, helping businesses ensure adherence to industry regulations and legal standards"
        },
        [chatModes.CHAT_LEGAL_SPORTS]: {
            "name": "AI Sports Lawyer",
            "description": "Specialist in sports law, advising athletes, teams, and organizations on contracts, endorsements, and disputes"
        },
        [chatModes.CHAT_LEGAL_ENTERTAINMENT]: {
            "name": "AI Entertainment Lawyer",
            "description": "Expert in media and entertainment law, providing legal services related to film, music, and media industries"
        },
        [chatModes.CHAT_LEGAL_TECHNOLOGY]: {
            "name": "AI Technology Lawyer",
            "description": "Specialist in technology law, helping businesses navigate legal challenges in the tech industry, including IP, cybersecurity, and compliance"
        },
        [chatModes.CHAT_LEGAL_STARTUP]: {
            "name": "AI Startup Lawyer",
            "description": "Expert in legal matters for startups, providing advice on business formation, venture capital, and intellectual property"
        },
        [chatModes.CHAT_LEGAL_TRUSTS_ESTATES]: {
            "name": "AI Trusts and Estates Lawyer",
            "description": "Specialist in estate planning, advising on wills, trusts, and inheritance law"
        },
        [chatModes.CHAT_LEGAL_SECURITY]: {
            "name": "AI Security Lawyer",
            "description": "Expert in security law, providing guidance on corporate, IT, and personal security, including compliance and regulatory issues"
        },
        [chatModes.CHAT_LIFE_JOB]: {
            "name": "AI Job Search Expert",
            "description": "Provides advice on job hunting, crafting resumes, and acing interviews to help individuals secure employment"
        },
        [chatModes.CHAT_LIFE_CAREER_DEVELOPMENT]: {
            "name": "AI Career Development Consultant",
            "description": "Specializes in guiding professionals in advancing their careers through skill development and strategic career moves"
        },
        [chatModes.CHAT_LIFE_SELF_DEVELOPMENT]: {
            "name": "AI Self-Development Consultant",
            "description": "Offers personalized advice for personal growth, helping individuals achieve their goals through self-improvement techniques"
        },
        [chatModes.CHAT_LIFE_TRAVEL]: {
            "name": "AI Travel Consultant",
            "description": "Provides travel advice, from planning itineraries to finding the best deals on flights, accommodation, and experiences"
        },
        [chatModes.CHAT_LIFE_RELOCATION]: {
            "name": "AI Relocation Consultant",
            "description": "Offers assistance with relocating, including logistics, settling into new environments, and legal requirements"
        },
        [chatModes.CHAT_LIFE_FINANCIAL]: {
            "name": "AI Personal Finance Consultant",
            "description": "Helps individuals manage their personal finances, providing guidance on budgeting, saving, and financial planning"
        },
        [chatModes.CHAT_LIFE_INSURANCE]: {
            "name": "AI Insurance Consultant",
            "description": "Offers advice on various types of insurance, helping individuals choose the right policies for their needs"
        },
        [chatModes.CHAT_LIFE_RETIREMENT]: {
            "name": "AI Retirement Planning Consultant",
            "description": "Specializes in helping individuals plan for retirement, ensuring long-term financial security and well-being"
        },
        [chatModes.CHAT_LIFE_HEALTHY_LIFESTYLE]: {
            "name": "AI Healthy Lifestyle Coach",
            "description": "Provides guidance on maintaining a healthy lifestyle through balanced nutrition, regular exercise, and stress management"
        },
        [chatModes.CHAT_LIFE_NUTRITION]: {
            "name": "AI Nutrition Consultant",
            "description": "Offers personalized dietary advice, helping individuals make healthier food choices and create balanced meal plans"
        },
        [chatModes.CHAT_LIFE_FITNESS]: {
            "name": "AI Fitness Consultant",
            "description": "Guides individuals through personalized fitness programs, helping them achieve their health and wellness goals"
        },
        [chatModes.CHAT_LIFE_YOGA]: {
            "name": "AI Yoga Instructor",
            "description": "Offers personalized yoga practices, promoting physical well-being and mental clarity through yoga techniques"
        },
        [chatModes.CHAT_LIFE_MEMORY_IQ]: {
            "name": "AI Memory and IQ Coach",
            "description": "Specialist in enhancing memory and cognitive skills, helping individuals improve their IQ and mental performance"
        },
        [chatModes.CHAT_LIFE_MENTAL_EQ]: {
            "name": "AI Mental Health and EQ Consultant",
            "description": "Provides advice on maintaining mental well-being and developing emotional intelligence for better personal relationships"
        },
        [chatModes.CHAT_LIFE_REAL_ESTATE]: {
            "name": "AI Real Estate Consultant",
            "description": "Expert in buying, selling, and managing property, offering advice on real estate transactions and market trends"
        },
        [chatModes.CHAT_LIFE_HOME_RENOVATION]: {
            "name": "AI Home Renovation Consultant",
            "description": "Offers guidance on home improvement projects, from planning renovations to managing budgets and contractors"
        },
        [chatModes.CHAT_LIFE_EDUCATION]: {
            "name": "AI Education Consultant",
            "description": "Provides advice on educational services, including finding the right schools, courses, and study resources"
        },
        [chatModes.CHAT_LIFE_STUDY_ABROAD]: {
            "name": "AI Study Abroad Consultant",
            "description": "Specialist in helping students navigate the process of studying abroad, from applications to cultural adaptation"
        },
        [chatModes.CHAT_LIFE_MARRIAGE]: {
            "name": "AI Marriage and Relationship Consultant",
            "description": "Offers relationship and marriage advice, helping couples strengthen their bond and resolve conflicts"
        },
        [chatModes.CHAT_LIFE_PARENTING]: {
            "name": "AI Parenting Consultant",
            "description": "Provides guidance on effective parenting strategies, helping parents navigate the challenges of raising children"
        },
        [chatModes.CHAT_LIFE_SMALL_BUSINESS]: {
            "name": "AI Small Business Consultant",
            "description": "Offers advice to small business owners on management, growth strategies, and overcoming business challenges"
        },
        [chatModes.CHAT_LIFE_PERSONAL_SECURITY]: {
            "name": "AI Personal Security Consultant",
            "description": "Specialist in personal safety, offering advice on self-defense, home security, and protecting personal information"
        },
        [chatModes.CHAT_LIFE_HOBBIES]: {
            "name": "AI Hobbies and Personal Interests Consultant",
            "description": "Encourages individuals to explore and develop hobbies, offering advice on various activities and personal interests"
        },
        [chatModes.CHAT_LIFE_PET_CARE]: {
            "name": "AI Pet Care Consultant",
            "description": "Provides advice on pet care, including nutrition, grooming, and training to ensure the well-being of pets"
        },
        [chatModes.CHAT_HOUSE_ELECTRICIAN]: {
            "name": "AI Electrician",
            "description": "Expert in electrical systems, offering assistance with wiring, lighting, and electrical repairs"
        },
        [chatModes.CHAT_HOUSE_PLUMBER]: {
            "name": "AI Plumber",
            "description": "Specialist in plumbing, providing help with water systems, pipe installations, and leak repairs"
        },
        [chatModes.CHAT_HOUSE_CLEANER]: {
            "name": "AI Cleaner",
            "description": "Professional cleaner offering advice on home cleaning, organization, and maintenance tips"
        },
        [chatModes.CHAT_HOUSE_GARDENER]: {
            "name": "AI Gardener",
            "description": "Skilled in gardening, offering advice on plant care, landscaping, and garden maintenance"
        },
        [chatModes.CHAT_HOUSE_SECURITY]: {
            "name": "AI Home Security Specialist",
            "description": "Expert in home security, offering advice on alarm systems, surveillance, and protection strategies"
        },
        [chatModes.CHAT_HOUSE_APPLIANCE_REPAIR]: {
            "name": "AI Appliance Repair Specialist",
            "description": "Provides assistance with the repair and maintenance of home appliances, including refrigerators, washers, and ovens"
        },
        [chatModes.CHAT_HOUSE_CARPENTER]: {
            "name": "AI Carpenter",
            "description": "Expert in woodworking, offering help with furniture repair, custom builds, and structural woodwork"
        },
        [chatModes.CHAT_HOUSE_PLASTERER]: {
            "name": "AI Plasterer",
            "description": "Skilled in plastering walls and ceilings, providing advice on repairs and surface finishing techniques"
        },
        [chatModes.CHAT_HOUSE_PAINTER]: {
            "name": "AI Painter",
            "description": "Expert in painting, offering guidance on choosing paint types, color schemes, and professional painting techniques"
        },
        [chatModes.CHAT_HOUSE_TILE_SETTER]: {
            "name": "AI Tile Setter",
            "description": "Specialist in tile installation, providing tips on laying tiles for floors, walls, and countertops"
        },
        [chatModes.CHAT_HOUSE_CONSTRUCTOR]: {
            "name": "AI Constructor",
            "description": "Professional in home construction, offering advice on building projects, materials, and regulations"
        },
        [chatModes.CHAT_HOUSE_INTERIOR_DESIGNER]: {
            "name": "AI Interior Designer",
            "description": "Expert in interior design, providing tips on creating functional and aesthetically pleasing living spaces"
        },
        [chatModes.CHAT_HOUSE_FENGSHUI]: {
            "name": "AI Feng Shui Consultant",
            "description": "Specialist in Feng Shui, offering advice on how to harmonize your living and working spaces for better energy flow"
        },
        [chatModes.CHAT_HOUSE_LANDSCAPE_ARCHITECT]: {
            "name": "AI Landscape Architect",
            "description": "Specialist in landscape architecture, offering advice on outdoor design, plant selection, and layout planning"
        },
        [chatModes.CHAT_HOUSE_CONTRACTOR]: {
            "name": "AI Contractor",
            "description": "Expert contractor providing guidance on project management, hiring subcontractors, and ensuring construction quality"
        },
        [chatModes.CHAT_HOUSE_MOVING_HELPER]: {
            "name": "AI Moving Helper",
            "description": "Assists with moving logistics, offering advice on packing, transportation, and organization for a smooth relocation"
        },
        [chatModes.CHAT_HOUSE_FURNITURE_ASSEMBLER]: {
            "name": "AI Furniture Assembler",
            "description": "Specialist in assembling furniture, providing tips for quick and efficient furniture construction"
        },
        [chatModes.CHAT_HOUSE_DOOR_LOCKS]: {
            "name": "AI Door and Lock Specialist",
            "description": "Expert in door installations and lock systems, offering advice on security and maintenance"
        },
        [chatModes.CHAT_HOUSE_SMART_HOME_SPECIALIST]: {
            "name": "AI Smart Home Specialist",
            "description": "Provides expertise in setting up smart home systems, including automation, lighting, and voice controls"
        },
        [chatModes.CHAT_HOUSE_HOME_THEATER_EXPERT]: {
            "name": "AI Home Theater Expert",
            "description": "Specialist in home theater systems, offering guidance on equipment selection, setup, and sound optimization"
        },
        [chatModes.CHAT_HOUSE_SOLAR_PANEL_SPECIALIST]: {
            "name": "AI Solar Panel Specialist",
            "description": "Provides advice on solar panel installation, maintenance, and optimizing energy efficiency"
        },
        [chatModes.CHAT_HOUSE_ROOFER]: {
            "name": "AI Roofer",
            "description": "Skilled in roofing, offering guidance on roof repairs, installations, and material selection"
        },
        [chatModes.CHAT_HOUSE_WINDOW_INSTALLER]: {
            "name": "AI Window Installer",
            "description": "Expert in window installation, providing tips on energy-efficient windows and secure fitting"
        },
        [chatModes.CHAT_HOUSE_FLOORING_EXPERT]: {
            "name": "AI Flooring Expert",
            "description": "Specialist in flooring, offering advice on choosing materials and proper installation techniques"
        },
        [chatModes.CHAT_HOUSE_HVAC]: {
            "name": "AI HVAC Specialist",
            "description": "Expert in heating, ventilation, and air conditioning, providing help with system maintenance and energy efficiency"
        },
        [chatModes.CHAT_HOUSE_INSULATION_EXPERT]: {
            "name": "AI Insulation Expert",
            "description": "Offers advice on improving home insulation for better energy efficiency and comfort"
        },
        [chatModes.CHAT_HOUSE_POOL_SPECIALIST]: {
            "name": "AI Pool Specialist",
            "description": "Specialist in pool maintenance and installation, providing guidance on cleaning, repairs, and water treatment"
        },
        [chatModes.CHAT_HOUSE_SEPTIC_SYSTEM_EXPERT]: {
            "name": "AI Septic System Expert",
            "description": "Expert in septic systems, offering help with installations, maintenance, and repairs"
        },
        [chatModes.CHAT_HOUSE_MOLD_REMOVAL_SPECIALIST]: {
            "name": "AI Mold Removal Specialist",
            "description": "Provides guidance on detecting and safely removing mold, and preventing future growth"
        },
        [chatModes.CHAT_HOUSE_PEST_CONTROL_SPECIALIST]: {
            "name": "AI Pest Control Specialist",
            "description": "Expert in pest control, offering advice on eliminating infestations and protecting homes from future pests"
        },
        [chatModes.CHAT_CAR_MECHANIC]: {
            "name": "AI Car Mechanic",
            "description": "Expert in general car repair and maintenance, offering assistance with routine services and mechanical issues"
        },
        [chatModes.CHAT_CAR_ELECTRICIAN]: {
            "name": "AI Car Electrician",
            "description": "Specialist in automotive electrical systems, providing help with wiring, lighting, and electronic components"
        },
        [chatModes.CHAT_CAR_SECURITY]: {
            "name": "AI Car Security Specialist",
            "description": "Expert in vehicle security systems, offering advice on alarms, immobilizers, and anti-theft measures"
        },
        [chatModes.CHAT_CAR_ENGINE_SPECIALIST]: {
            "name": "AI Engine Specialist",
            "description": "Specialist in engine diagnostics, repairs, and performance enhancement"
        },
        [chatModes.CHAT_CAR_TRANSMISSION_SPECIALIST]: {
            "name": "AI Transmission Specialist",
            "description": "Expert in transmission systems, helping with repairs and maintenance of manual and automatic transmissions"
        },
        [chatModes.CHAT_CAR_BRAKE_SPECIALIST]: {
            "name": "AI Brake Specialist",
            "description": "Specialist in brake systems, offering assistance with brake repairs, replacements, and maintenance"
        },
        [chatModes.CHAT_CAR_TIRES_SPECIALIST]: {
            "name": "AI Tires Specialist",
            "description": "Expert in tire services, offering advice on tire selection, installation, rotation, and alignment"
        },
        [chatModes.CHAT_CAR_SUSPENSION_SPECIALIST]: {
            "name": "AI Suspension Specialist",
            "description": "Specialist in suspension systems, providing help with repairs and upgrades to improve vehicle handling"
        },
        [chatModes.CHAT_CAR_AIR_CONDITIONING_SPECIALIST]: {
            "name": "AI Air Conditioning Specialist",
            "description": "Expert in car air conditioning systems, offering repairs, maintenance, and refrigerant refills"
        },
        [chatModes.CHAT_CAR_BODYWORK_SPECIALIST]: {
            "name": "AI Bodywork Specialist",
            "description": "Specialist in car body repair, offering services to fix dents, scratches, and structural damage"
        },
        [chatModes.CHAT_CAR_PAINT_SPECIALIST]: {
            "name": "AI Paint Specialist",
            "description": "Expert in automotive painting, offering guidance on color matching and professional paint jobs"
        },
        [chatModes.CHAT_CAR_WINDSHIELD_REPAIR_SPECIALIST]: {
            "name": "AI Windshield Repair Specialist",
            "description": "Specialist in windshield repairs and replacements, helping to fix cracks and chips in vehicle glass"
        },
        [chatModes.CHAT_CAR_AUDIO_SPECIALIST]: {
            "name": "AI Car Audio Specialist",
            "description": "Expert in car audio systems, offering advice on installation, upgrades, and sound optimization"
        },
        [chatModes.CHAT_CAR_DIAGNOSTICS_SPECIALIST]: {
            "name": "AI Diagnostics Specialist",
            "description": "Specialist in vehicle diagnostics, offering help with identifying and solving mechanical or electronic issues"
        },
        [chatModes.CHAT_CAR_DETAILER]: {
            "name": "AI Car Detailer",
            "description": "Professional detailer offering tips on interior and exterior car detailing for enhanced appearance and protection"
        },
        [chatModes.CHAT_CAR_TUNING_SPECIALIST]: {
            "name": "AI Tuning Specialist",
            "description": "Expert in car tuning, providing guidance on performance enhancements and custom modifications"
        },
        [chatModes.CHAT_CAR_EXHAUST_SYSTEM_SPECIALIST]: {
            "name": "AI Exhaust System Specialist",
            "description": "Specialist in exhaust systems, offering repairs, replacements, and performance upgrades"
        },
        [chatModes.CHAT_CAR_CLUTCH_SPECIALIST]: {
            "name": "AI Clutch Specialist",
            "description": "Expert in clutch systems, helping with repairs, adjustments, and replacements"
        },
        [chatModes.CHAT_CAR_FUEL_SYSTEM_SPECIALIST]: {
            "name": "AI Fuel System Specialist",
            "description": "Specialist in fuel systems, providing help with fuel pump repairs, injectors, and efficiency optimization"
        },
        [chatModes.CHAT_CAR_GLASS_TINTING_SPECIALIST]: {
            "name": "AI Glass Tinting Specialist",
            "description": "Expert in automotive window tinting, offering advice on application, types of film, and local regulations"
        },
        [chatModes.CHAT_CAR_RADIATOR_SPECIALIST]: {
            "name": "AI Radiator Specialist",
            "description": "Specialist in cooling systems, offering repairs and maintenance for radiators to prevent overheating"
        },
        [chatModes.CHAT_CAR_BATTERY_SPECIALIST]: {
            "name": "AI Battery Specialist",
            "description": "Expert in car batteries, offering guidance on battery selection, replacement, and maintenance"
        },
        [chatModes.CHAT_CAR_ALIGNMENT_SPECIALIST]: {
            "name": "AI Alignment Specialist",
            "description": "Specialist in wheel alignment, providing services to correct misalignment and improve driving safety"
        },
        [chatModes.CHAT_CAR_INTERIOR_REPAIR_SPECIALIST]: {
            "name": "AI Interior Repair Specialist",
            "description": "Expert in interior repairs, offering tips on fixing seats, upholstery, and dashboards"
        },
        [chatModes.CHAT_CAR_LOCKSMITH]: {
            "name": "AI Car Locksmith",
            "description": "Specialist in automotive locks, offering help with lock repairs, replacements, and key programming"
        },
        [chatModes.CHAT_CAR_HYBRID_SPECIALIST]: {
            "name": "AI Hybrid Specialist",
            "description": "Expert in hybrid vehicle systems, offering assistance with maintenance and repairs for hybrid cars"
        },
        [chatModes.CHAT_CAR_LAWYER]: {
            "name": "AI Car Lawyer",
            "description": "Expert in automotive law, providing legal advice on traffic violations, accidents, and car insurance claims"
        },
        [chatModes.CHAT_CAR_SALES_SPECIALIST]: {
            "name": "AI Car Sales Specialist",
            "description": "Specialist in buying and selling cars, offering advice on negotiations, inspections, and pricing"
        },
        [chatModes.CHAT_RELIGION_SPIRITUAL_MENTOR]: {
            "name": "AI Spiritual Mentor",
            "description": "Provides spiritual guidance and mentorship to help individuals on their spiritual journeys"
        },
        [chatModes.CHAT_RELIGION_SPIRITUAL_CARE]: {
            "name": "AI Spiritual Care Consultant",
            "description": "Offers emotional and spiritual support during challenging life situations, promoting inner peace and strength"
        },
        [chatModes.CHAT_RELIGION_RELIGIOUS_COUNSELOR]: {
            "name": "AI Religious Counselor",
            "description": "Helps individuals navigate religious questions and provides support rooted in specific faith traditions"
        },
        [chatModes.CHAT_RELIGION_INTERFAITH_CONSULTANT]: {
            "name": "AI Interfaith Consultant",
            "description": "Facilitates understanding and collaboration between different faiths, promoting harmony and respect"
        },
        [chatModes.CHAT_RELIGION_PRIEST]: {
            "name": "AI Priest",
            "description": "A Christian religious leader offering spiritual guidance, sacraments, and religious services"
        },
        [chatModes.CHAT_RELIGION_CHAPLAIN]: {
            "name": "AI Chaplain",
            "description": "Provides spiritual care and counseling in various settings, such as hospitals or the military, often non-denominational"
        },
        [chatModes.CHAT_RELIGION_IMAM]: {
            "name": "AI Imam",
            "description": "An Islamic leader offering spiritual guidance and leading prayers within the Muslim community"
        },
        [chatModes.CHAT_RELIGION_RABBI]: {
            "name": "AI Rabbi",
            "description": "A Jewish spiritual leader, providing guidance on religious practices, ethics, and community leadership"
        },
        [chatModes.CHAT_RELIGION_BUDDHIST_MONK]: {
            "name": "AI Buddhist Monk",
            "description": "A practitioner of Buddhism offering teachings on meditation, mindfulness, and spiritual growth"
        },
        [chatModes.CHAT_RELIGION_YOGA_TEACHER]: {
            "name": "AI Yoga Teacher",
            "description": "Provides guidance on yoga practices to enhance physical and spiritual well-being, rooted in Hindu tradition"
        },
        [chatModes.CHAT_RELIGION_TANTRA_TEACHER]: {
            "name": "AI Tantra Teacher",
            "description": "Offers spiritual teachings on Tantra, a tradition within Hinduism and Buddhism, focusing on mindfulness and sacred practices"
        },
        [chatModes.CHAT_RELIGION_VEDIC_PRIEST]: {
            "name": "AI Vedic Priest",
            "description": "A Hindu religious leader offering Vedic rituals and spiritual guidance based on ancient Hindu scriptures"
        },
        [chatModes.CHAT_RELIGION_SPIRITUAL_HEALER]: {
            "name": "AI Spiritual Healer",
            "description": "Helps individuals heal spiritually through various practices such as energy work, prayer, or meditation"
        },
        [chatModes.CHAT_RELIGION_MEDITATION_GUIDE]: {
            "name": "AI Meditation Guide",
            "description": "Offers instruction on meditation techniques to promote relaxation, mindfulness, and spiritual awareness"
        },
        [chatModes.CHAT_RELIGION_SCHOLAR_OF_RELIGIOUS_TEXTS]: {
            "name": "AI Scholar of Religious Texts",
            "description": "Expert in religious scriptures, providing in-depth understanding of sacred texts across various faiths"
        },
        [chatModes.CHAT_RELIGION_THEOLOGIST]: {
            "name": "AI Theologist",
            "description": "Studies and offers insights into theological concepts, helping individuals deepen their understanding of religious beliefs"
        },
        [chatModes.CHAT_RELIGION_ATHEIST_COUNSELOR]: {
            "name": "AI Atheist Counselor",
            "description": "Offers guidance on life questions and philosophical dilemmas from an atheistic or secular perspective"
        },
        [chatModes.CHAT_RELIGION_PHILOSOPHER]: {
            "name": "AI Philosopher",
            "description": "Provides philosophical insights on existential questions, ethics, and the nature of reality"
        },
        [chatModes.CHAT_RELIGION_ETHICS_SPECIALIST]: {
            "name": "AI Ethics Specialist",
            "description": "Helps individuals navigate complex moral and ethical issues, offering guidance rooted in philosophical or religious ethics"
        },
        [chatModes.CHAT_ESOTERIC_NUMEROLOGY]: {
            "name": "AI Numerologist",
            "description": "Specialist in numerology, offering insights based on the mystical significance of numbers and their influence on life"
        },
        [chatModes.CHAT_ESOTERIC_BIORHYTHMS]: {
            "name": "AI Biorhythm Specialist",
            "description": "Expert in biorhythms, providing guidance on personal cycles of physical, emotional, and intellectual well-being"
        },
        [chatModes.CHAT_ESOTERIC_PALMISTRY]: {
            "name": "AI Palmistry Expert",
            "description": "Specialist in palm reading, offering insights into personality and future based on hand lines and shapes"
        },
        [chatModes.CHAT_ESOTERIC_AURA_READING]: {
            "name": "AI Aura Reader",
            "description": "Expert in reading auras, interpreting the energy field around individuals to provide guidance on health and emotions"
        },
        [chatModes.CHAT_ESOTERIC_CRYSTALS]: {
            "name": "AI Crystal Specialist",
            "description": "Provides guidance on the use of crystals for healing, protection, and spiritual growth"
        },
        [chatModes.CHAT_ESOTERIC_RUNES]: {
            "name": "AI Rune Reader",
            "description": "Specialist in rune casting, offering divination and insights based on ancient symbols and their meanings"
        },
        [chatModes.CHAT_ESOTERIC_I_CHING]: {
            "name": "AI Book of Changes Expert",
            "description": "Offers divination and advice based on the ancient Chinese text, the Book of Changes (I Ching)"
        },
        [chatModes.CHAT_ESOTERIC_PSYCHIC]: {
            "name": "AI Psychic",
            "description": "Provides intuitive insights and guidance on various aspects of life through extrasensory perception"
        },
        [chatModes.CHAT_ESOTERIC_AKASHIC_RECORDS]: {
            "name": "AI Akashic Records Reader",
            "description": "Specialist in accessing the Akashic Records to provide spiritual insights into past, present, and future events"
        },
        [chatModes.CHAT_ESOTERIC_MEDIUM]: {
            "name": "AI Medium",
            "description": "Facilitates communication with spirits and provides messages from deceased loved ones or spiritual guides"
        },
        [chatModes.CHAT_ESOTERIC_SHAMAN]: {
            "name": "AI Shaman",
            "description": "Offers spiritual healing and guidance through shamanic practices, including rituals, journeys, and connections with nature"
        },
        [chatModes.CHAT_ESOTERIC_PENDULUM_DOWSING]: {
            "name": "AI Pendulum Dowser",
            "description": "Expert in pendulum dowsing, offering divination and answers to questions using a pendulum as a spiritual tool"
        },
        [chatModes.CHAT_ESOTERIC_HERBALISM]: {
            "name": "AI Herbalism Specialist",
            "description": "Provides guidance on the use of herbs for healing, wellness, and spiritual practices"
        },
        [chatModes.CHAT_TAROT_TRADITIONAL]: {
            "name": "Traditional Tarot Reader",
            "description": "Focuses on traditional tarot meanings and symbols for insightful readings"
        },
        [chatModes.CHAT_TAROT_LENORMAND]: {
            "name": "Lenormand Tarot Reader",
            "description": "Uses Lenormand cards for detailed and unique divination insights"
        },
        [chatModes.CHAT_TAROT_ORACLE]: {
            "name": "Oracle Deck Reader",
            "description": "Interprets oracle decks with flexible meanings and personalized themes"
        },
        [chatModes.CHAT_TAROT_CELTIC]: {
            "name": "Celtic or Pagan Tarot Reader",
            "description": "Offers readings aligned with Celtic, Pagan, or Wiccan spiritual traditions"
        },
        [chatModes.CHAT_TAROT_NUMERICAL]: {
            "name": "Numerological Tarot Reader",
            "description": "Focuses on numerology in tarot to provide deeper, number-based insights"
        },
        [chatModes.CHAT_TAROT_ASTRO]: {
            "name": "Astrological Tarot Reader",
            "description": "Combines astrology and tarot for cosmic insights and guidance"
        },
        [chatModes.CHAT_TAROT_THERAPY]: {
            "name": "Therapeutic Tarot Reader",
            "description": "Uses tarot for personal growth and healing, often with counseling experience"
        },
        [chatModes.CHAT_TAROT_SPIRIT]: {
            "name": "Spiritual Tarot Reader",
            "description": "Incorporates spiritual beliefs and practices into tarot readings"
        },
        [chatModes.CHAT_TAROT_INTUITIVE]: {
            "name": "Intuitive Tarot Reader",
            "description": "Relies on intuition to offer personal and insightful tarot readings"
        },
        [chatModes.CHAT_TAROT_PSYCHIC]: {
            "name": "Psychic Tarot Reader",
            "description": "Blends tarot readings with psychic abilities for enhanced insights"
        },
        [chatModes.CHAT_HOROSCOPE]: {
            "name": "AI General Horoscope",
            "description": "Provides an overall analysis of astrological influences based on birth details, offering guidance for various life areas"
        },
        [chatModes.CHAT_HOROSCOPE_REGULAR]: {
            "name": "AI Traditional Horoscope",
            "description": "Follows traditional astrological methods to provide daily, weekly, or monthly predictions based on star signs"
        },
        [chatModes.CHAT_HOROSCOPE_EASTERN]: {
            "name": "AI Eastern Horoscope",
            "description": "Uses Eastern astrology, such as Chinese zodiac signs, to offer personalized predictions and insights"
        },
        [chatModes.CHAT_HOROSCOPE_BUSINESS]: {
            "name": "AI Business Horoscope",
            "description": "Focuses on career and business-related astrological advice, helping to guide financial and professional decisions"
        },
        [chatModes.CHAT_HOROSCOPE_COMPATIBILITY]: {
            "name": "AI Compatibility Horoscope",
            "description": "Analyzes compatibility between partners, friends, or colleagues based on astrological charts"
        },
        [chatModes.CHAT_HOROSCOPE_LOVE]: {
            "name": "AI Love Horoscope",
            "description": "Provides insights into romantic relationships and love life based on astrological influences"
        },
        [chatModes.CHAT_HOROSCOPE_SEX]: {
            "name": "AI Sexual Horoscope",
            "description": "Explores sexual compatibility and desires through astrological analysis of birth charts"
        },
        [chatModes.CHAT_HOROSCOPE_HEALTH]: {
            "name": "AI Health Horoscope",
            "description": "Offers health-related advice based on astrological signs and planetary positions"
        },
        [chatModes.CHAT_HOROSCOPE_LUNAR]: {
            "name": "AI Lunar Horoscope",
            "description": "Focuses on the influence of the moon and lunar cycles on daily life, emotions, and decisions"
        },
        [chatModes.CHAT_HOROSCOPE_SOLAR]: {
            "name": "AI Solar Horoscope",
            "description": "Centers around the sun's position and its impact on personal identity, goals, and energy levels"
        },
        [chatModes.CHAT_HOROSCOPE_CELESTIAL]: {
            "name": "AI Celestial Horoscope",
            "description": "Analyzes planetary alignments and celestial movements to provide in-depth astrological insights"
        },
        [chatModes.CHAT_HOROSCOPE_PSYCHOLOGICAL]: {
            "name": "AI Psychological Horoscope",
            "description": "Combines astrology with psychological insights to offer deeper understanding of personality and mental patterns"
        },
        [chatModes.CHAT_HOROSCOPE_DRACONIC]: {
            "name": "AI Draconic Horoscope",
            "description": "Focuses on karmic and spiritual aspects of life by interpreting the Draconic chart, linked to one's soul purpose"
        },
        [chatModes.CHAT_HOROSCOPE_HOURLY]: {
            "name": "AI Hourly Horoscope",
            "description": "Provides frequent astrological updates, tracking planetary positions hour by hour for short-term insights"
        },
        [chatModes.CHAT_HOROSCOPE_WEEKLY]: {
            "name": "AI Weekly Horoscope",
            "description": "Offers a weekly astrological forecast, helping to plan for upcoming challenges and opportunities"
        },
        [chatModes.CHAT_HOROSCOPE_YEARLY]: {
            "name": "AI Yearly Horoscope",
            "description": "Provides an astrological overview for the year ahead, highlighting significant trends and key moments"
        },
        [chatModes.CHAT_ASTROLOGY_NATAL]: {
            "name": "AI Natal Chart Astrologer",
            "description": "Specializes in creating and interpreting natal charts, providing insights into personality, strengths, and life path based on birth details"
        },
        [chatModes.CHAT_ASTROLOGY_HORO]: {
            "name": "AI Horoscope Astrologer",
            "description": "Offers daily, weekly, or monthly horoscopes based on astrological positions, providing guidance for short-term decisions and life events"
        },
        [chatModes.CHAT_ASTROLOGY_SYNASTRY]: {
            "name": "AI Synastry Astrologer",
            "description": "Analyzes compatibility between partners by comparing their astrological charts, offering insights into relationships"
        },
        [chatModes.CHAT_ASTROLOGY_SOLAR_RETURN]: {
            "name": "AI Solar Return Astrologer",
            "description": "Specializes in solar return charts, providing yearly forecasts based on the sun's return to its natal position on your birthday"
        },
        [chatModes.CHAT_ASTROLOGY_LUNAR_CYCLE]: {
            "name": "AI Lunar Cycle Astrologer",
            "description": "Focuses on lunar cycles and their influence on emotions, decisions, and life phases, offering guidance aligned with the moon's phases"
        },
        [chatModes.CHAT_ASTROLOGY_TRANSITS]: {
            "name": "AI Transits Astrologer",
            "description": "Analyzes the current positions of planets and their influence on natal planets, helping to predict trends and events in life"
        },
        [chatModes.CHAT_ASTROLOGY_PROGRESSIONS]: {
            "name": "AI Progressions Astrologer",
            "description": "Specialist in astrological progressions, offering insights into internal growth and long-term personal evolution"
        },
        [chatModes.CHAT_ASTROLOGY_KARMIC_ASTROLOGY]: {
            "name": "AI Karmic Astrologer",
            "description": "Interprets past life influences and karmic lessons through astrology, helping individuals understand their spiritual journey"
        },
        [chatModes.CHAT_ASTROLOGY_MEDICAL_ASTROLOGY]: {
            "name": "AI Medical Astrologer",
            "description": "Focuses on health-related matters, using astrology to provide guidance on physical well-being and potential health challenges"
        },
        [chatModes.CHAT_ASTROLOGY_VEDIC]: {
            "name": "AI Vedic Astrologer",
            "description": "Specializes in Vedic astrology, offering insights based on the ancient Indian astrological system, providing deep spiritual and practical advice"
        },
        [chatModes.CHAT_TALISMAN_CALPACA]: {
            "name": "Calpaca Talisman",
            "description": "A talisman in the shape of a Calpaca horseshoe for luck"
        },
        [chatModes.CHAT_TALISMAN_ROSA]: {
            "name": "Rosa de los Vientos",
            "description": "A talisman representing the Rose of the Winds for guidance"
        },
        [chatModes.CHAT_TALISMAN_MANDALA]: {
            "name": "Mandala dos Orixás",
            "description": "A figurine representing the Mandala dos Orixás for spiritual protection"
        },
        [chatModes.CHAT_TALISMAN_BACA]: {
            "name": "Bacá",
            "description": "A Bacá figurine talisman believed to bring mystical power"
        },
        [chatModes.CHAT_TALISMAN_KUITA]: {
            "name": "Munekas Kuita Peñas",
            "description": "A Munekas Kuita Peñas doll, symbolizing protection"
        },
        [chatModes.CHAT_TALISMAN_TRINKETS]: {
            "name": "Trinket",
            "description": "A small talisman used for protection or good fortune"
        },
        [chatModes.CHAT_UNKNOWN]: {
            "name": "AI Consultant",
            "description": ""
        },
    },
    "consultationDetails": {
        "title": "Consultation Details",
        "deleteConfirmation": "Are you sure you want to delete this consultation?",
        "deleteConsultation": "Delete",
        "resumeConsultation": "Resume",
        "restoreConsultation": "Restore",
        "restoreConfirmation": "Are you sure you want to restore this consultation?"
    },
    "documentDetails": {
        "title": "Document Details",
        "manage": "Manage Document",
        "download": "Download"
    },
    "documentManage": {
        "title": "Manage Docuement",
        "share": "Share",
        "delete": "Delete",
        "restore": "Restore",
        "rename": "Rename",
        "changeProfile": "Change Related Person",
        "download": "Download",
        "documentDeleted": "Document deleted {{date}}",
        "deleteConfirmation": "Are you sure you want to delete this document?",
        "restoreConfirmation": "Are you sure you want to restore this document?"
    },
    "deleteConfirmation": {
        "title": "Do you really want to delete this consultation?",
        "cancel": "Cancel",
        "delete": "Delete"
    },
    "restoreConfirmation": {
        "title": "Do you want to restore this consultation?",
        "cancel": "Cancel",
        "restore": "Restore"
    },
    "uploadOptions": {
        "takePhoto": "Take Photo",
        "photoGallery": "Photo Gallery",
        "uploadFile": "Upload Document"
    },
    "countries": {
        [countries.COUNTRY_AF]: "Afghanistan",
        [countries.COUNTRY_AL]: "Albania",
        [countries.COUNTRY_DZ]: "Algeria",
        [countries.COUNTRY_AD]: "Andorra",
        [countries.COUNTRY_AO]: "Angola",
        [countries.COUNTRY_AG]: "Antigua and Barbuda",
        [countries.COUNTRY_AR]: "Argentina",
        [countries.COUNTRY_AM]: "Armenia",
        [countries.COUNTRY_AU]: "Australia",
        [countries.COUNTRY_AT]: "Austria",
        [countries.COUNTRY_AZ]: "Azerbaijan",
        [countries.COUNTRY_BS]: "Bahamas",
        [countries.COUNTRY_BH]: "Bahrain",
        [countries.COUNTRY_BD]: "Bangladesh",
        [countries.COUNTRY_BB]: "Barbados",
        [countries.COUNTRY_BY]: "Belarus",
        [countries.COUNTRY_BE]: "Belgium",
        [countries.COUNTRY_BZ]: "Belize",
        [countries.COUNTRY_BJ]: "Benin",
        [countries.COUNTRY_BT]: "Bhutan",
        [countries.COUNTRY_BO]: "Bolivia",
        [countries.COUNTRY_BA]: "Bosnia and Herzegovina",
        [countries.COUNTRY_BW]: "Botswana",
        [countries.COUNTRY_BR]: "Brazil",
        [countries.COUNTRY_BN]: "Brunei",
        [countries.COUNTRY_BG]: "Bulgaria",
        [countries.COUNTRY_BF]: "Burkina Faso",
        [countries.COUNTRY_BI]: "Burundi",
        [countries.COUNTRY_KH]: "Cambodia",
        [countries.COUNTRY_CM]: "Cameroon",
        [countries.COUNTRY_CA]: "Canada",
        [countries.COUNTRY_CV]: "Cape Verde",
        [countries.COUNTRY_CF]: "Central African Republic",
        [countries.COUNTRY_TD]: "Chad",
        [countries.COUNTRY_CL]: "Chile",
        [countries.COUNTRY_CN]: "China",
        [countries.COUNTRY_CO]: "Colombia",
        [countries.COUNTRY_KM]: "Comoros",
        [countries.COUNTRY_CG]: "Congo",
        [countries.COUNTRY_CD]: "Democratic Republic of the Congo",
        [countries.COUNTRY_CR]: "Costa Rica",
        [countries.COUNTRY_HR]: "Croatia",
        [countries.COUNTRY_CU]: "Cuba",
        [countries.COUNTRY_CY]: "Cyprus",
        [countries.COUNTRY_CZ]: "Czech Republic",
        [countries.COUNTRY_DK]: "Denmark",
        [countries.COUNTRY_DJ]: "Djibouti",
        [countries.COUNTRY_DM]: "Dominica",
        [countries.COUNTRY_DO]: "Dominican Republic",
        [countries.COUNTRY_EC]: "Ecuador",
        [countries.COUNTRY_EG]: "Egypt",
        [countries.COUNTRY_SV]: "El Salvador",
        [countries.COUNTRY_GQ]: "Equatorial Guinea",
        [countries.COUNTRY_ER]: "Eritrea",
        [countries.COUNTRY_EE]: "Estonia",
        [countries.COUNTRY_SZ]: "Eswatini",
        [countries.COUNTRY_ET]: "Ethiopia",
        [countries.COUNTRY_FJ]: "Fiji",
        [countries.COUNTRY_FI]: "Finland",
        [countries.COUNTRY_FR]: "France",
        [countries.COUNTRY_GA]: "Gabon",
        [countries.COUNTRY_GM]: "Gambia",
        [countries.COUNTRY_GE]: "Georgia",
        [countries.COUNTRY_DE]: "Germany",
        [countries.COUNTRY_GH]: "Ghana",
        [countries.COUNTRY_GR]: "Greece",
        [countries.COUNTRY_GD]: "Grenada",
        [countries.COUNTRY_GT]: "Guatemala",
        [countries.COUNTRY_GN]: "Guinea",
        [countries.COUNTRY_GW]: "Guinea-Bissau",
        [countries.COUNTRY_GY]: "Guyana",
        [countries.COUNTRY_HT]: "Haiti",
        [countries.COUNTRY_HN]: "Honduras",
        [countries.COUNTRY_HU]: "Hungary",
        [countries.COUNTRY_IS]: "Iceland",
        [countries.COUNTRY_IN]: "India",
        [countries.COUNTRY_ID]: "Indonesia",
        [countries.COUNTRY_IR]: "Iran",
        [countries.COUNTRY_IQ]: "Iraq",
        [countries.COUNTRY_IE]: "Ireland",
        [countries.COUNTRY_IL]: "Israel",
        [countries.COUNTRY_IT]: "Italy",
        [countries.COUNTRY_JM]: "Jamaica",
        [countries.COUNTRY_JP]: "Japan",
        [countries.COUNTRY_JO]: "Jordan",
        [countries.COUNTRY_KZ]: "Kazakhstan",
        [countries.COUNTRY_KE]: "Kenya",
        [countries.COUNTRY_KI]: "Kiribati",
        [countries.COUNTRY_KP]: "North Korea",
        [countries.COUNTRY_KR]: "South Korea",
        [countries.COUNTRY_KW]: "Kuwait",
        [countries.COUNTRY_KG]: "Kyrgyzstan",
        [countries.COUNTRY_LA]: "Laos",
        [countries.COUNTRY_LV]: "Latvia",
        [countries.COUNTRY_LB]: "Lebanon",
        [countries.COUNTRY_LS]: "Lesotho",
        [countries.COUNTRY_LR]: "Liberia",
        [countries.COUNTRY_LY]: "Libya",
        [countries.COUNTRY_LI]: "Liechtenstein",
        [countries.COUNTRY_LT]: "Lithuania",
        [countries.COUNTRY_LU]: "Luxembourg",
        [countries.COUNTRY_MG]: "Madagascar",
        [countries.COUNTRY_MW]: "Malawi",
        [countries.COUNTRY_MY]: "Malaysia",
        [countries.COUNTRY_MV]: "Maldives",
        [countries.COUNTRY_ML]: "Mali",
        [countries.COUNTRY_MT]: "Malta",
        [countries.COUNTRY_MH]: "Marshall Islands",
        [countries.COUNTRY_MR]: "Mauritania",
        [countries.COUNTRY_MU]: "Mauritius",
        [countries.COUNTRY_MX]: "Mexico",
        [countries.COUNTRY_FM]: "Micronesia",
        [countries.COUNTRY_MD]: "Moldova",
        [countries.COUNTRY_MC]: "Monaco",
        [countries.COUNTRY_MN]: "Mongolia",
        [countries.COUNTRY_ME]: "Montenegro",
        [countries.COUNTRY_MA]: "Morocco",
        [countries.COUNTRY_MZ]: "Mozambique",
        [countries.COUNTRY_MM]: "Myanmar",
        [countries.COUNTRY_NA]: "Namibia",
        [countries.COUNTRY_NR]: "Nauru",
        [countries.COUNTRY_NP]: "Nepal",
        [countries.COUNTRY_NL]: "Netherlands",
        [countries.COUNTRY_NZ]: "New Zealand",
        [countries.COUNTRY_NI]: "Nicaragua",
        [countries.COUNTRY_NE]: "Niger",
        [countries.COUNTRY_NG]: "Nigeria",
        [countries.COUNTRY_NO]: "Norway",
        [countries.COUNTRY_OM]: "Oman",
        [countries.COUNTRY_PK]: "Pakistan",
        [countries.COUNTRY_PW]: "Palau",
        [countries.COUNTRY_PA]: "Panama",
        [countries.COUNTRY_PG]: "Papua New Guinea",
        [countries.COUNTRY_PY]: "Paraguay",
        [countries.COUNTRY_PE]: "Peru",
        [countries.COUNTRY_PH]: "Philippines",
        [countries.COUNTRY_PL]: "Poland",
        [countries.COUNTRY_PT]: "Portugal",
        [countries.COUNTRY_QA]: "Qatar",
        [countries.COUNTRY_RO]: "Romania",
        [countries.COUNTRY_RU]: "Russia",
        [countries.COUNTRY_RW]: "Rwanda",
        [countries.COUNTRY_KN]: "Saint Kitts and Nevis",
        [countries.COUNTRY_LC]: "Saint Lucia",
        [countries.COUNTRY_VC]: "Saint Vincent and the Grenadines",
        [countries.COUNTRY_WS]: "Samoa",
        [countries.COUNTRY_SM]: "San Marino",
        [countries.COUNTRY_ST]: "Sao Tome and Principe",
        [countries.COUNTRY_SA]: "Saudi Arabia",
        [countries.COUNTRY_SN]: "Senegal",
        [countries.COUNTRY_RS]: "Serbia",
        [countries.COUNTRY_SC]: "Seychelles",
        [countries.COUNTRY_SL]: "Sierra Leone",
        [countries.COUNTRY_SG]: "Singapore",
        [countries.COUNTRY_SK]: "Slovakia",
        [countries.COUNTRY_SI]: "Slovenia",
        [countries.COUNTRY_SB]: "Solomon Islands",
        [countries.COUNTRY_SO]: "Somalia",
        [countries.COUNTRY_ZA]: "South Africa",
        [countries.COUNTRY_SS]: "South Sudan",
        [countries.COUNTRY_ES]: "Spain",
        [countries.COUNTRY_LK]: "Sri Lanka",
        [countries.COUNTRY_SD]: "Sudan",
        [countries.COUNTRY_SR]: "Suriname",
        [countries.COUNTRY_SE]: "Sweden",
        [countries.COUNTRY_CH]: "Switzerland",
        [countries.COUNTRY_SY]: "Syria",
        [countries.COUNTRY_TW]: "Taiwan",
        [countries.COUNTRY_TJ]: "Tajikistan",
        [countries.COUNTRY_TZ]: "Tanzania",
        [countries.COUNTRY_TH]: "Thailand",
        [countries.COUNTRY_TL]: "Timor-Leste",
        [countries.COUNTRY_TG]: "Togo",
        [countries.COUNTRY_TO]: "Tonga",
        [countries.COUNTRY_TT]: "Trinidad and Tobago",
        [countries.COUNTRY_TN]: "Tunisia",
        [countries.COUNTRY_TR]: "Turkey",
        [countries.COUNTRY_TM]: "Turkmenistan",
        [countries.COUNTRY_TV]: "Tuvalu",
        [countries.COUNTRY_UG]: "Uganda",
        [countries.COUNTRY_UA]: "Ukraine",
        [countries.COUNTRY_AE]: "United Arab Emirates",
        [countries.COUNTRY_GB]: "United Kingdom",
        [countries.COUNTRY_US]: "United States",
        [countries.COUNTRY_UY]: "Uruguay",
        [countries.COUNTRY_UZ]: "Uzbekistan",
        [countries.COUNTRY_VU]: "Vanuatu",
        [countries.COUNTRY_VE]: "Venezuela",
        [countries.COUNTRY_VN]: "Vietnam",
        [countries.COUNTRY_YE]: "Yemen",
        [countries.COUNTRY_ZM]: "Zambia",
        [countries.COUNTRY_ZW]: "Zimbabwe"
    },
    "languages": {
        [languages.LANGUAGE_AF]: "Afrikaans",
        [languages.LANGUAGE_AM]: "Amharic",
        [languages.LANGUAGE_AR]: "Arabic",
        [languages.LANGUAGE_AZ]: "Azerbaijani",
        [languages.LANGUAGE_BE]: "Belarusian",
        [languages.LANGUAGE_BG]: "Bulgarian",
        [languages.LANGUAGE_BN]: "Bengali",
        [languages.LANGUAGE_CA]: "Catalan",
        [languages.LANGUAGE_CS]: "Czech",
        [languages.LANGUAGE_DA]: "Danish",
        [languages.LANGUAGE_DE]: "German",
        [languages.LANGUAGE_DZ]: "Dzongkha",
        [languages.LANGUAGE_EL]: "Greek",
        [languages.LANGUAGE_EN]: "English (US)",
        [languages.LANGUAGE_EN_GB]: "English (UK)",
        [languages.LANGUAGE_ES]: "Spanish",
        [languages.LANGUAGE_ET]: "Estonian",
        [languages.LANGUAGE_FA]: "Persian",
        [languages.LANGUAGE_FA_IR]: "Persian (Iran)",
        [languages.LANGUAGE_FI]: "Finnish",
        [languages.LANGUAGE_FR]: "French",
        [languages.LANGUAGE_FR_CA]: "French (Canada)",
        [languages.LANGUAGE_FR_CH]: "French (Switzerland)",
        [languages.LANGUAGE_HE]: "Hebrew",
        [languages.LANGUAGE_HI]: "Hindi",
        [languages.LANGUAGE_HR]: "Croatian",
        [languages.LANGUAGE_HU]: "Hungarian",
        [languages.LANGUAGE_HY]: "Armenian",
        [languages.LANGUAGE_ID]: "Indonesian",
        [languages.LANGUAGE_IS]: "Icelandic",
        [languages.LANGUAGE_IT]: "Italian",
        [languages.LANGUAGE_JA]: "Japanese",
        [languages.LANGUAGE_KA]: "Georgian",
        [languages.LANGUAGE_KG]: "Kongo",
        [languages.LANGUAGE_KK]: "Kazakh",
        [languages.LANGUAGE_KM]: "Khmer",
        [languages.LANGUAGE_KO]: "Korean",
        [languages.LANGUAGE_KY]: "Kyrgyz",
        [languages.LANGUAGE_LA]: "Latin",
        [languages.LANGUAGE_LT]: "Lithuanian",
        [languages.LANGUAGE_LV]: "Latvian",
        [languages.LANGUAGE_MK]: "Macedonian",
        [languages.LANGUAGE_ML]: "Malayalam",
        [languages.LANGUAGE_MN]: "Mongolian",
        [languages.LANGUAGE_MS]: "Malay",
        [languages.LANGUAGE_MT]: "Maltese",
        [languages.LANGUAGE_NE]: "Nepali",
        [languages.LANGUAGE_NL]: "Dutch",
        [languages.LANGUAGE_NO]: "Norwegian",
        [languages.LANGUAGE_PL]: "Polish",
        [languages.LANGUAGE_PT]: "Portuguese",
        [languages.LANGUAGE_PT_BR]: "Portuguese (Brazil)",
        [languages.LANGUAGE_RO]: "Romanian",
        [languages.LANGUAGE_RU]: "Russian",
        [languages.LANGUAGE_RW]: "Kinyarwanda",
        [languages.LANGUAGE_SD]: "Sindhi",
        [languages.LANGUAGE_SI]: "Sinhala",
        [languages.LANGUAGE_SK]: "Slovak",
        [languages.LANGUAGE_SL]: "Slovenian",
        [languages.LANGUAGE_SO]: "Somali",
        [languages.LANGUAGE_SQ]: "Albanian",
        [languages.LANGUAGE_SR]: "Serbian",
        [languages.LANGUAGE_SV]: "Swedish",
        [languages.LANGUAGE_SW]: "Swahili",
        [languages.LANGUAGE_TA]: "Tamil",
        [languages.LANGUAGE_TE]: "Telugu",
        [languages.LANGUAGE_TH]: "Thai",
        [languages.LANGUAGE_TL]: "Tagalog",
        [languages.LANGUAGE_TR]: "Turkish",
        [languages.LANGUAGE_UK]: "Ukrainian",
        [languages.LANGUAGE_UR]: "Urdu",
        [languages.LANGUAGE_VI]: "Vietnamese",
        [languages.LANGUAGE_ZH]: "Chinese",
        [languages.LANGUAGE_ZH_CN]: "Chinese (Simplified)",
        [languages.LANGUAGE_ZH_TW]: "Chinese (Traditional)",
        [languages.LANGUAGE_ZU]: "Zulu"
    },
};
