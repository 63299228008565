import React, { useState, useEffect } from 'react';
import { Typography, Box, Grid, Card, CardContent, CardActionArea, Avatar, Fade, Skeleton } from '@mui/material';
import CompletionCircle from '../elements/CompletionProgress';
import { formatDate, formatDateDistance } from '../utils/dateUtils';
import { ProfileIcon } from '../components/ProfileIcon.tsx';
import { useTranslation } from 'react-i18next';
import { chatModes } from '../config/AppModes.ts';
import defaultImage from '../img/default-avatar.webp';

// Utility function to capitalize the first letter of a string
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const ConsultationCard = ({ content, userPhoto, handleCardClick, isDeleted, index, expanded }) => {

    //console.log(content)
    const [startFade, setStartFade] = useState(false);
    const { t } = useTranslation();
    // Delay the activation of the fade effect
    useEffect(() => {
        const timer = setTimeout(() => {
            setStartFade(true);
        }, 100); // Delay in milliseconds (e.g., 1000ms = 1 second)

        return () => clearTimeout(timer); // Cleanup the timeout on unmount
    }, []);

    return (
        (content) &&
        <Fade in={expanded && startFade} key={index} timeout={250 + 100 * (index & 0xF)}>
            <Grid item xs={12} md={12}>
                <Card sx={{
                    mb: 0, borderRadius: '11px', my: 0.5, mx: 0,
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
                    // if is Deleted set opacity to 0.5
                    // opacity: isDeleted ? 0.5 : 1
                }} elevation={0}>

                    {content.id ? (

                        <CardActionArea sx={{ overflow: 'hidden' }} onClick={() => { if (handleCardClick) handleCardClick(content) }}>
                            <CardContent sx={{ p: 0 }}>
                                <Box sx={{
                                    width: '100%', borderRadius: '11px',
                                    justifyContent: 'center',
                                    position: 'relative', overflow: 'auto',
                                    border: '1px solid lightgray',
                                    '&:hover .avatar-zoom': {
                                        transform: 'scale(1.4)',
                                    },
                                }}>
                                    <Box sx={{
                                        height: '100%', width: '100%', justifyContent: 'center', display: 'flex',
                                        flexDirection: 'row', alignItems: 'top',
                                    }}>
                                        <Box sx={{
                                            width: '7rem', flexShrink: 0, backgroundColor: '#E5E7EB',
                                            overflow: 'hidden', display: 'flex', alignItems: 'flex-start',
                                            justifyContent: 'center', position: 'relative',
                                        }}>
                                            <Avatar className="avatar-zoom" sx={{
                                                width: '100%',
                                                height: 'auto',
                                                transform: 'scale(1.3)',
                                                transformOrigin: 'top center',
                                                borderRadius: '0',
                                                position: 'absolute',
                                                pr: 1,
                                                transition: 'transform 0.3s ease-in-out',
                                            }} src={content?.consultant?.photo || defaultImage} />

                                        </Box>
                                        <Box sx={{ px: 2, py: 1, flexGrow: 1, textAlign: 'left', position: 'relative', }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                                                <Typography sx={{
                                                    p: 0,
                                                    width: '100%',
                                                    textAlign: 'left',
                                                    position: 'relative',
                                                    wordWrap: 'break-word', // Old CSS3 property, use as a fallback
                                                    wordBreak: 'break-word', // Ensures that the words can be broken and wrapped at arbitrary points if needed
                                                    overflowWrap: 'break-word', // Standard property to break words to prevent overflow
                                                    hyphens: 'auto' // Automatically add hyphens when breaking words (works best in supported languages)
                                                }} variant="h3">
                                                    {content?.consultant?.key && t(`consultants.${content.consultant.key}.name`) || t(`consultants.${chatModes.CHAT_UNKNOWN}.name`)}
                                                </Typography>
                                                <Box sx={{ width: '2.2rem', height: '2.2rem', pr: 0, pb: 1, pl: 1, display: 'flex', alignItems: 'flex-start', }}>
                                                    <ProfileIcon
                                                        id={content.profileId}
                                                        name={content.profileName}
                                                        onClick={() => { }}
                                                        sx={{ width: '2.3rem', height: '2.3rem' }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Typography sx={{ p: 0, width: '100%', textAlign: 'left', position: 'relative', }} variant="body2">
                                                {content.description} <CompletionCircle sx={{}} percentage={content.completion}></CompletionCircle>
                                            </Typography>
                                            <Typography sx={{ pt: 2, width: '100%', textAlign: 'left', position: 'relative', }} variant="body2">
                                                {capitalizeFirstLetter(formatDateDistance(new Date(content.date)))}
                                                , #{content.id}, {content.total_cost}$
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </CardContent>
                        </CardActionArea>

                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'top' }}>
                            <Skeleton variant="rectangular" width="7rem" height="100%" sx={{ borderRadius: 0, flexShrink: 0, backgroundColor: '#f0f0f0' }} />
                            <Box sx={{ px: 2, py: 1, flexGrow: 1 }}>
                                <Skeleton width="60%" height="1.5rem" />
                                <Skeleton width="100%" height="1rem" />
                                <Skeleton width="50%" height="1rem" sx={{ mt: 2 }} />
                            </Box>
                        </Box>
                    )}
                </Card>
            </Grid>
        </Fade>
    );
};

export default ConsultationCard;