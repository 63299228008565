import React, { useEffect, useState } from 'react';
import {
    Typography
    , Box
    , ListItemButton
    , useMediaQuery
    //    , TextField
} from '@mui/material';
import { useAuthRedirect } from '../components/AuthContext';  // Import useAuth from AuthContext
import {
    Avatar
    , List
    , ListItemIcon
    , ListItemText
    , Divider
    //, Fade
} from '@mui/material';

import { ReactComponent as ProfileSolidIcon } from '../img/icons/navigation/icon-profile-outlined-white.svg';
import { ReactComponent as DocSolidIcon } from '../img/icons/navigation/icon-document-outlined-white.svg';
import { ReactComponent as ConsultationIconSolid } from '../img/icons/navigation/icon-consultation-outlined-white.svg';

import {
    People as PeopleIcon,
    //Favorite as FavoriteIcon,
    //FitnessCenter as FitnessCenterIcon,
    //LocalFireDepartment as LocalFireDepartmentIcon,
    Person as PersonIcon,
    Settings as SettingsIcon,
    Payment as PaymentsIcon,
    LightMode as OfficeIcon,
    Help as HelpIcon,
    Article as TermsIcon,
    ExitToApp as ExitToAppIcon,
    ArrowForwardIos as ArrowForwardIosIcon,
    //Chat as ChatIcon,
    //Article as ArticleIcon
} from '@mui/icons-material';

import { useAuth } from '../components/AuthContext'; // Import useAuth hook
import api from '../classes/API';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { AppRoutes } from '../config/AppModes.ts';
import { ProfileIcon } from '../components/ProfileIcon.tsx';

import profileBackground from '../img/profile-bg.webp';  // Import the Header component 

export function ProfileScreen({ setDirection, useDelayedNavigation }) {
    useAuthRedirect(setDirection, useDelayedNavigation);
    const setDelayedNavigation = useDelayedNavigation();
    const { userProfile, logout } = useAuth();
    const { t } = useTranslation(); // Initialize translation function

    const [stat, setStat] = useState({
        profiles: "...",
        consultations: "...",
        documents: "...",
    });
    const [profileName, setProfileName] = useState();
    const [profileId, setProfileId] = useState();

    useEffect(() => {
        api.stats()
            .then(x => setStat(x));
    }, []);
    useEffect(() => {
        if (!userProfile) return;
        setProfileId(userProfile.profile_id);
        api.getProfile(
            userProfile.profile_id
        )
            .then(x => setProfileName(x.name));
    }
        , [
            userProfile
        ]);

    // logout
    const handleLogout = () => {
        logout();
        setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_LOGIN.path);
    }

    const handleClick = () => {
        setDelayedNavigation(() => setDirection('forward'), `${AppRoutes.ROUTE_PROFILE_EDIT.path}?profileId=${profileId}`);
    }

    const isXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return (
        <Box sx={{
            p: 0, m: 0,
            height: 'auto',
            flex: 1,
            display: 'flex', flexDirection: 'column',
            // background: 'linear-gradient(135deg, #3700AB 100%, #3700AB 100%)',
            backgroundImage: `url(${profileBackground})`,
            backgroundSize: 'cover', // This will make the image cover the available space
            backgroundPosition: 'center', // Centers the background image
        }}>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                alignItems: 'center',
                mt: 0, pt: { xs: 3, md: 6 }, px: { xs: 4, sm: 6 }, pb: 3,
                color: 'white'
            }}>
                <Box sx={{ display: 'flex', mb: 2, flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                    <Box sx={{ width: { xs: '5.5rem', md: '7.5rem' }, height: { xs: '5.5rem', md: '7.5rem' }, position: 'relative', overflow: 'hidden', flexShrink: 0, boxSizing: 'border-box' }}>
                        <Box sx={{ width: '100%', height: '100%', border: '0.4rem solid #7230FE', borderRadius: '50%', overflow: 'hidden', boxSizing: 'border-box' }}>
                            <ProfileIcon
                                id={profileId}
                                name={profileName}
                                onClick={handleClick}
                                sx={{
                                    width: '100%'
                                    , height: '100%'
                                    , padding: '0px'
                                    , zoom: 3
                                }}
                            />
                        </Box>
                    </Box>
                    <Typography variant={isXs ? 'h2' : 'h1'} sx={{
                        mt: 1, ml: 3, flexGrow: 1,
                        width: '100%', color: 'white',
                        wordWrap: 'break-word', // Old CSS3 property, use as a fallback
                        wordBreak: 'break-word', // Ensures that the words can be broken and wrapped at arbitrary points if needed
                        overflowWrap: 'break-word', // Standard property to break words to prevent overflow
                        hyphens: 'auto' // Automatically add hyphens when breaking words (works best in supported languages)                    
                    }}>
                        {profileName}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', color: 'white' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 1, width: { xs: '100%', sm: '60%', md: '50%' }, px: { xs: 0, sm: 3 } }}>
                        <ProfileSolidIcon width="3rem" height="3rem" style={{ flexShrink: 0 }} />
                        <Typography variant="h3" color={'white'} sx={{ flexGrow: 1, mx: 1 }}>
                            {t('profiles.title')}
                        </Typography>
                        <Typography variant="h2" sx={{ color: 'white', pr: { xs: 2, md: 6 } }}>{stat.profiles}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 1, width: { xs: '100%', sm: '60%', md: '50%' }, px: { xs: 0, sm: 3 } }}>
                        <ConsultationIconSolid width="3rem" height="3rem" style={{ flexShrink: 0 }} />
                        <Typography variant="h3" color={'white'} sx={{ flexGrow: 1, mx: 1 }}>
                            {t('consultations.title')}
                        </Typography>
                        <Typography variant="h2" sx={{ color: 'white', pr: { xs: 2, md: 6 } }}>{stat.consultations}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 1, width: { xs: '100%', sm: '60%', md: '50%' }, px: { xs: 0, sm: 3 } }}>
                        <DocSolidIcon width="3rem" height="3rem" style={{ flexShrink: 0 }} />
                        <Typography variant="h3" color={'white'} sx={{ flexGrow: 1, mx: 1 }}>
                            {t('documents.title')}
                        </Typography>
                        <Typography variant="h2" sx={{ color: 'white', pr: { xs: 2, md: 6 } }} > {stat.documents}</Typography>
                    </Box>
                </Box>

            </Box>

            <Box maxWidth='md'
                sx={{
                    width: '100%',
                    position: 'relative',
                    overflowY: 'hidden',
                    flexShrink: 0,
                }}
            >
                <Box sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    borderRadius: '11px 11px 0 0',
                    textAlign: 'center',
                    width: 'auto',
                    position: 'relative',
                    mx: { xs: 2, sm: 6, md: 6 },
                    px: 2,
                    pb: 0,
                    pt: 1,
                }}>
                    <List component="nav" sx={{ mt: 0, pb: 3, width: '100%' }}>
                        <ListItemButton onClick={() => setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_SELECT_PROFILE.path)}>
                            <ListItemIcon>
                                <PeopleIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('profiles.title')} />
                            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                <ArrowForwardIosIcon />
                            </ListItemIcon>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton onClick={() => setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_OFFICES.path)}>
                            <ListItemIcon>
                                <OfficeIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('profile.offices')} />
                            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                <ArrowForwardIosIcon />
                            </ListItemIcon>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton onClick={() => setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_SETTINGS.path)}>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('profile.settings')} />
                            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                <ArrowForwardIosIcon />
                            </ListItemIcon>
                        </ListItemButton>
                        {/*
                        <Divider />
                        <ListItemButton onClick={() => setDelayedNavigation(() => setDirection('forward'), AppRoutes.ROUTE_BILLING.path)}>
                            <ListItemIcon>
                                <PaymentsIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('profile.billing')} />
                            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                <ArrowForwardIosIcon />
                            </ListItemIcon>
                        </ListItemButton>
                        */}
                        <Divider />
                        <ListItemButton>
                            <ListItemIcon>
                                <TermsIcon />
                            </ListItemIcon>
                            <ListItemText primary={t("profile.termsConditions")} />
                            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                <ArrowForwardIosIcon />
                            </ListItemIcon>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton onClick={handleLogout}>
                            <ListItemIcon>
                                <ExitToAppIcon color="error" />
                            </ListItemIcon>
                            <ListItemText primary={t('profile.logout')} sx={{ color: 'error.main' }} />
                            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                <ArrowForwardIosIcon />
                            </ListItemIcon>
                        </ListItemButton>
                    </List>
                </Box>
            </Box>
        </Box>
    );
}

export default ProfileScreen;