import { officeTypes, chatModes, genderTypes } from '../../config/AppModes.ts';

export const nl = {
    "send_email_code": "E-mail wordt verzonden naar {{email}} met code: {{code}}",
    "token_valid": "Token is geldig",
    "token_invalid": "Token is ongeldig",
    "verification_error": "Fout bij het verifiëren van token:",
    "verification_completed": "Verificatie voltooid. Geauthenticeerd: ",
    "welcome": {
        "title": "Wixee"
    },
    "splash1": {
        "title": "Slimme gezondheid begint hier",
        "description": "Versterk het welzijn van je gezin met gepersonaliseerd AI-gezondheidsadvies, afgestemd op de unieke behoeften van elk gezinslid."
    },
    "splash2": {
        "title": "Gezondheidsinzichten voor iedereen",
        "description": "Ontdek uitgebreide gezondheidsinzichten voor alle leeftijden, zodat je dierbaren gezond en gelukkig blijven."
    },
    "splash3": {
        "title": "Jouw gezondheid, onze prioriteit",
        "description": "Ervaar de toekomst van gezinsgezondheidszorg met AI-gestuurde tips en aanbevelingen, ontworpen om jou en je familie in optimale gezondheid te houden."
    },
    "splash4": {
        "title": "Laten we beginnen!",
        "description": "Log in om te genieten van de functies die we hebben voorzien en blijf gezond!",
        "signInButton": "Inloggen"
    },
    "home": {
        "title": "Startpagina",
        "consultations": "Consultaties",
        "consultationsDescription": "Klik hier om je consultaties te zien",
        "documents": "Documenten",
        "documentsDescription": "Klik hier om je documenten te uploaden",
        "notifications": "Meldingen",
        "notificationsDescription": "Klik hier om alle evenementen te zien",
        "offices": "Diensten",
        "officesDescription": "Selecteer een dienst om een service te krijgen",
        "settings": "Instellingen",
        "settingsDescription": "Verander taal, enz.",
        "cardTitle": "Geniet van je dag vandaag",
        "cardDescription": "Je gezondheidsconditie en zonneactiviteit suggereren dat je mogelijk cognitieve complicaties kunt ondervinden. Ontspan en geniet",
        "newConsultation": "Nieuwe Consultatie",
        "adTitle": "Advertentie van de app",
        "learnMore": "Meer informatie"
    },
    "calendar": {
        "title": "Meldingen",
        "created": "Aangemaakt",
        "source": {
            "consultation": "van consultatie",
            "document": "van document"
        }
    },
    "documents": {
        "title": "Mijn documenten",
        "latest": "Laatste",
        "lastMonth": "Vorige maand",
        "older": "Ouder",
        "upload": "Nieuw document uploaden",
        "deleted": "Verwijderd"
    },
    "document": {
        "title": "Document",
    },
    "header": {
        "youAndYourBeloved": "Jij en je geliefden"
    },
    "settings": {
        "title": "Instellingen",
        "language": "Taal",
        "country": "Land",
        "userCountry": "Mijn Land",
        "userLanguage": "Mijn Taal",
        "userEmail": "Mijn E-mail",
        "emailAddress": "E-mailadres"
    },
    "consultation": {
        "title": "Consultatie",
        "description": "Consultatiebeschrijving",
        "selectConsultant": "Kies Consultant",
        "manageConsultation": "Beheer Consultatie",
        "consultationDeleted": "Consultatie verwijderd {{date}}",
        "download": "Downloaden",
        "message": "Bericht",
        "join": {
            [genderTypes.MALE]: "voegt zich bij het gesprek",
            [genderTypes.FEMALE]: "voegt zich bij het gesprek",
            [genderTypes.OTHER]: "voegt zich bij het gesprek",
        },
    },
    "consultations": {
        "title": "Mijn consultaties",
        "description": "Consultatiebeschrijving",
        "latest": "Laatste",
        "seeAll": "Bekijk alles",
        "lastMonth": "Vorige maand",
        "older": "Ouder",
        "deleted": "Verwijderd",
        "about": "over",
    },
    "profiles": {
        "title": "Mijn geliefden",
    },
    "profile": {
        "title": "Mijn profiel",
        "billing": "Mijn abonnement",
        "offices": "Mijn diensten",
        "settings": "Instellingen",
        "general": "Algemeen",
        "create": "Aanmaken",
        "logout": "Uitloggen",
        "addProfile": "Nieuwe geliefde toevoegen",
        "hideStoredData": "Verberg opgeslagen gegevens",
        "showStoredData": "Toon opgeslagen gegevens",
        "officeNoSummary": "Geen samenvatting beschikbaar",
        "termsConditions": "Algemene Voorwaarden",
        "clickToChange": "Klik om te wijzigen",
        "name": "Naam",
        "comment": "Opmerking",
        "dateOfBirth": "Geboortedatum",
        "timeOfBirth": "Geboortetijd (lokaal)",
        "placeOfBirth": "Geboorteplaats",
        "height": "Lengte (cm)",
        "weight": "Gewicht (kg)",
        "cm": "cm",
        "kg": "kg"
    },
    "billing": {
        "title": "Facturatie",
        "currentBalance": "Huidig saldo",
        "subscriptionPlan": "Abonnementsplan",
        "subscription": {
            "free": "Gratis",
            "premium": "Premium",
            "exclusive": {
                "title": "Exclusief",
                "description": "Een exclusief abonnementsplan biedt leden premium toegang tot unieke content, speciale functies en gepersonaliseerde diensten die niet beschikbaar zijn voor reguliere gebruikers."
            }
        },
        "topUp": "Opwaarderen",
        "changePlan": "Plan wijzigen"
    },
    "offices": {
        "title": "Diensten",
        [officeTypes.HEALTH]: {
            "name": "Gezondheid",
            "description": "Een door AI aangedreven gezondheidsdiagnoseservice die door gebruikers verstrekte symptomen en medische geschiedenis analyseert om voorlopige beoordelingen van gezondheidsaandoeningen te geven. Het biedt gepersonaliseerde inzichten en aanbevelingen en begeleidt gebruikers bij mogelijke volgende stappen in de medische zorg, inclusief wanneer professionele hulp moet worden gezocht.",
            "disclaimer": "Deze door AI aangedreven dienst is uitsluitend voor informatieve doeleinden en is geen vervanging voor medische hulpmiddelen of diensten. Het biedt geen medische diagnoses, behandelingen of voorschriften. Alle gezondheidsgerelateerde beslissingen moeten worden genomen in overleg met een bevoegde zorgverlener. De ontwikkelaars en eigenaren van deze applicatie zijn niet verantwoordelijk voor gezondheidsuitkomsten op basis van het gebruik van dit hulpmiddel. Als je een medisch noodgeval ervaart, bel dan 112 of de plaatselijke noodhulpdienst."
        },
        [officeTypes.ALTMED]: {
            "name": "Alternatieve Geneeskunde",
            "description": "Een door AI aangedreven dienst voor alternatieve geneeskunde, die een reeks niet-traditionele behandelingen aanbiedt, waaronder acupunctuur, kruidengeneeskunde en homeopathie. Door middel van geavanceerde algoritmen biedt het gepersonaliseerde aanbevelingen op basis van de principes van alternatieve genezingspraktijken. De dienst is bedoeld als aanvulling op conventionele medische behandelingen door holistische inzichten te bieden in de gezondheid en het welzijn van de gebruiker.",
            "disclaimer": "Deze door AI aangedreven dienst voor alternatieve geneeskunde is uitsluitend bedoeld voor algemeen welzijn en informatieve doeleinden. Het is geen vervanging voor een professionele medische diagnose of behandeling. Raadpleeg altijd een gekwalificeerde zorgverlener voordat je gezondheidsbeslissingen neemt. De ontwikkelaars zijn niet verantwoordelijk voor gezondheidsuitkomsten die voortvloeien uit het gebruik van deze dienst."
        },
        [officeTypes.PSYCHO]: {
            "name": "Psychologie",
            "description": "Een door AI aangedreven psychologische adviesdienst biedt ondersteuning bij geestelijke gezondheid door de door gebruikers gerapporteerde emoties, gedachten en gedragingen te analyseren. Het biedt gepersonaliseerde adviezen, copingstrategieën en zelfverbeteringstechnieken om gebruikers te helpen stress, angst en andere psychologische uitdagingen te beheersen. Deze dienst is ontworpen om traditionele therapie aan te vullen door onmiddellijke en toegankelijke ondersteuning te bieden.",
            "disclaimer": "Deze door AI aangedreven psychologische adviesdienst is bedoeld om algemene ondersteuning bij geestelijke gezondheid te bieden en vervangt geen professionele psychologische of psychiatrische adviezen, diagnoses of behandelingen. Raadpleeg voor nauwkeurige geestelijke gezondheidszorg altijd een bevoegde geestelijke gezondheidsprofessional. Als je in een crisis verkeert of een noodgeval ervaart, neem dan contact op met je lokale noodhulpdienst of de crisislijn voor geestelijke gezondheid. De ontwikkelaars en eigenaren van deze applicatie zijn niet verantwoordelijk voor uitkomsten op basis van het gebruik van deze dienst."
        },
        [officeTypes.VET]: {
            "name": "Huisdieren",
            "description": "Een door AI aangedreven veterinaire dienst die huisdiereigenaren helpt de gezondheidsconditie van hun dieren te begrijpen door de door gebruikers verstrekte symptomen en medische geschiedenis te analyseren. Het biedt eerste inzichten in mogelijke gezondheidsproblemen en aanbevelingen voor volgende stappen, zoals het maken van een dierenartsafspraak of thuiszorg.",
            "disclaimer": "Deze door AI aangedreven veterinaire dienst biedt eerste inzichten in de gezondheid van je huisdier op basis van de door jou verstrekte informatie. Het vervangt geen professionele veterinaire zorg, diagnose of behandeling. Voor nauwkeurige diagnoses en behandelingsplannen moet je altijd een erkende dierenarts raadplegen. In geval van een dringend of noodsituatie met je huisdier, neem dan contact op met de plaatselijke veterinaire noodhulpdienst. De ontwikkelaars en eigenaren van deze applicatie zijn niet aansprakelijk voor gezondheidsuitkomsten van je huisdier op basis van het gebruik van dit hulpmiddel."
        }
    },
    "consultants": {
        "title": "Consultants",
        [chatModes.CHAT_HEALTH_RECEPTIONIST]: {
            "name": "AI-Receptionist",
            "description": "Helpt je bij het kiezen van de juiste specialist"
        },
        [chatModes.CHAT_HEALTH_ALLERGIST]: {
            "name": "AI-Allergoloog",
            "description": "Specialist in het diagnosticeren en behandelen van allergieën en verwante aandoeningen"
        },
        [chatModes.CHAT_HEALTH_CARDIOLOGIST]: {
            "name": "AI-Cardioloog",
            "description": "Hartgezondheidsdeskundige, gespecialiseerd in het diagnosticeren en behandelen van hartaandoeningen"
        },
        [chatModes.CHAT_HEALTH_PEDIATRICIAN]: {
            "name": "AI-Kinderarts",
            "description": "Specialist in de gezondheid van kinderen, medische zorg voor baby’s en kinderen"
        },
        [chatModes.CHAT_VET_RECEPTIONIST]: {
            "name": "AI-Dierenarts-Receptionist",
            "description": "Helpt je bij het kiezen van de juiste veterinaire specialist"
        },
        [chatModes.CHAT_VET_DENTIST]: {
            "name": "AI-Dierenarts-Tandarts",
            "description": "Specialist in tandheelkundige zorg en mondgezondheid bij dieren"
        },
        [chatModes.CHAT_HOUSE_FENGSHUI]: {
            "name": "AI-Feng Shui-Adviseur",
            "description": "Specialist in Feng Shui, biedt advies over hoe je je ruimte kunt harmoniseren voor een betere energiestroom"
        }
    },
    "consultationDetails": {
        "title": "Consultatie Details",
        "deleteConfirmation": "Weet je zeker dat je deze consultatie wilt verwijderen?",
        "deleteConsultation": "Verwijderen",
        "resumeConsultation": "Hervatten",
        "restoreConsultation": "Herstellen",
        "restoreConfirmation": "Weet je zeker dat je deze consultatie wilt herstellen?"
    },
    "documentDetails": {
        "title": "Documentdetails",
        "manage": "Document beheren",
        "download": "Downloaden"
    },
    "documentManage": {
        "title": "Document beheren",
        "share": "Delen",
        "delete": "Verwijderen",
        "restore": "Herstellen",
        "rename": "Hernoemen",
        "changeProfile": "Gerelateerde persoon wijzigen",
        "download": "Downloaden",
        "documentDeleted": "Document verwijderd op {{date}}",
        "deleteConfirmation": "Weet je zeker dat je dit document wilt verwijderen?",
        "restoreConfirmation": "Weet je zeker dat je dit document wilt herstellen?"
    },
    "deleteConfirmation": {
        "title": "Weet je zeker dat je deze consultatie wilt verwijderen?",
        "cancel": "Annuleren",
        "delete": "Verwijderen"
    },
    "restoreConfirmation": {
        "title": "Wil je deze consultatie herstellen?",
        "cancel": "Annuleren",
        "restore": "Herstellen"
    },
    "uploadOptions": {
        "takePhoto": "Foto maken",
        "photoGallery": "Fotogalerij",
        "uploadFile": "Document uploaden"
    },
    "languages": {
        "af": "Afrikaans",
        "ar": "Arabisch",
        "az": "Azerbeidzjaans",
        "be": "Wit-Russisch",
        "bg": "Bulgaars",
        "bn": "Bengaals",
        "bs": "Bosnisch",
        "ca": "Catalaans",
        "cs": "Tsjechisch",
        "da": "Deens",
        "de": "Duits",
        "de-AT": "Duits (Oostenrijk)",
        "el": "Grieks",
        "en": "Engels",
        "en-GB": "Engels (VK)",
        "en-US": "Engels (VS)",
        "es": "Spaans",
        "et": "Ests",
        "fa-IR": "Perzisch (Iran)",
        "fi": "Fins",
        "fr": "Frans",
        "fr-CA": "Frans (Canada)",
        "fr-CH": "Frans (Zwitserland)",
        "he": "Hebreeuws",
        "hi": "Hindi",
        "hu": "Hongaars",
        "is": "IJslands",
        "it": "Italiaans",
        "ja": "Japans",
        "ko": "Koreaans",
        "nl": "Nederlands",
        "pl": "Pools",
        "pt": "Portugees",
        "pt-BR": "Portugees (Brazilië)",
        "ro": "Roemeens",
        "ru": "Russisch",
        "sv": "Zweeds",
        "tr": "Turks",
        "uk": "Oekraïens",
        "vi": "Vietnamees",
        "zh-CN": "Chinees (vereenvoudigd)",
        "zh-TW": "Chinees (traditioneel)"
    }
};
