import React from 'react';
import { Typography, Grid, Box, Card, CardActionArea, CardContent, } from '@mui/material';
import { useAuthRedirect } from '../components/AuthContext.js';  // Import useAuth from AuthContext
import { useTranslation } from 'react-i18next';
import { useAppContext, } from '../components/AppContext.js'; // Import useAppContext hook

export function SelectOfficeScreen({ setDirection, useDelayedNavigation }) {
    useAuthRedirect(setDirection, useDelayedNavigation);
    const setDelayedNavigation = useDelayedNavigation();
    const { t, i18n } = useTranslation();
    const { officeManager } = useAppContext();
    const currentCountry = localStorage.getItem('currentCountry') || i18n.country
    const activeOffices = officeManager.getActiveOffices(currentCountry);

    function handleOffeSelect(officeType) {
        officeManager.setCurrentOffice(officeType, currentCountry);
        setDelayedNavigation(() => setDirection('backward'), -1);
    }

    return (
        <Box
            sx={{
                pt: 0,
                pb: 10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
            }}
        >
            <Box
                sx={{
                    pt: 2,
                    pl: 4,
                    pr: 4,
                    width: '100%',
                }}
            >
                <Grid container spacing={2} sx={{ px: 2 }}>
                    {activeOffices.map((office, index) => (
                        <Grid item xs={12} sm={6} md={6} key={index}>
                            <Card
                                sx={{
                                    position: 'relative',
                                    overflow: 'hidden',
                                    width: '100%',
                                    borderRadius: '11px',
                                    opacity: 0,
                                    transform: 'translateY(20px)',
                                    animation: `fadeInUp 0.5s ease-in-out ${index * 0.2}s forwards`,
                                    boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
                                }}
                                elevation={0}
                            >
                                <CardActionArea onClick={() => handleOffeSelect(office.type)}>
                                    <CardContent sx={{ p: 0 }}>
                                        <Box
                                            sx={{
                                                backgroundImage: `url(${office.image})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                width: '100%',
                                                aspectRatio: '19/9',
                                                borderRadius: '11px',
                                                position: 'relative',
                                                overflow: 'hidden',
                                                transition: 'transform 0.3s ease',
                                                '&:hover': {
                                                    transform: 'scale(1.05)',
                                                },

                                            }}
                                        >
                                            {/* Gradient overlay */}
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    background:
                                                        'linear-gradient(36deg, rgba(1, 20, 130, 0.90) 10%, rgba(255, 255, 255, 0) 60%)',
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    px: 3,
                                                    pb: 1.5,
                                                    width: '50%',
                                                    textAlign: 'left',
                                                    position: 'absolute',
                                                    bottom: 0,
                                                }}
                                                variant="h1"
                                                color="white"
                                            >
                                                {t(`offices.${office.type}.name`)}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box >
    );
}

export default SelectOfficeScreen;