export const AppModes = {
    LANDING_ALL_SERVICES: 'landing-all-services',
    LANDING_HEALTHADVISOR: 'landing-healthadvisor',
    MODE_DASHBOARD: 'Dashboard',
    MODE_GENERATE_FORM: 'GenerateForm',
    CHAT_TAROT: 'chat-tarot',
    CHAT_HOROSCOPE: 'chat-horoscope',
    CHAT_HEALTH: 'chat-doctor',
    CHAT_VET: 'chat-vet',
    CHAT_HEALTH_RECEPTIONIST: 'chat-doctor-receptionist',
    CHAT_HEALTH_COPILOT: 'chat-doctor-copilot',
    CHAT_HEALTH_COPILOT_DIAGNOSE: 'chat-doctor-copilot-diagnose',
    CHAT_HEALTH_COPILOT_DIALOG: 'chat-doctor-copilot-dialog',
    CHAT_HEALTH_PRESCREEN: 'chat-doctor-prescreen',
    MODE_NONE: 'none',
};

export const officeTypes = {
    HEALTH: 'health',
    ALTMED: 'altmed',
    PSYCHO: 'psycho',
    VET: 'veterinery',
    TEACHER: 'teacher',
    BUSINESS: 'business',
    LEGAL: 'legal',
    LIFE: 'life',
    HOUSE: 'house',
    CAR: 'car',
    RELIGION: 'religion',
    ESOTERIC: 'esoteric',
    TAROT: 'tarot',
    HOROSCOPE: 'horoscope',
    ASTROLOGY: 'astrology',
    TALISMAN: 'talisman',
};

export const chatModes = {
    CHAT_HEALTH: 'chat-doctor',                                               // Врач
    CHAT_HEALTH_RECEPTIONIST: 'chat-doctor-receptionist',                     // Регистратор
    CHAT_HEALTH_CARDIOLOGIST: 'chat-doctor-cardiologist',                     // Кардиолог
    CHAT_HEALTH_DERMATOLOGIST: 'chat-doctor-dermatologist',                   // Дерматолог
    CHAT_HEALTH_ENDOCRINOLOGIST: 'chat-doctor-endocrinologist',               // Эндокринолог
    CHAT_HEALTH_GASTROENTEROLOGIST: 'chat-doctor-gastroenterologist',         // Гастроэнтеролог
    CHAT_HEALTH_SURGEON: 'chat-doctor-surgeon',                               // Хирург
    CHAT_HEALTH_GYNECOLOGIST: 'chat-doctor-gynecologist',                     // Гинеколог
    CHAT_HEALTH_MAMMOLOGIST: 'chat-doctor-mammologist',                       // Маммолог
    CHAT_HEALTH_NEUROLOGIST: 'chat-doctor-neurologist',                       // Невролог
    CHAT_HEALTH_ONCOLOGIST: 'chat-doctor-oncologist',                         // Онколог
    CHAT_HEALTH_ORTHOPEDIST: 'chat-doctor-orthopedist',                       // Ортопед
    CHAT_HEALTH_PEDIATRICIAN: 'chat-doctor-pediatrician',                     // Педиатр
    CHAT_HEALTH_ALLERGIST: 'chat-doctor-allergist',                           // Аллерголог
    CHAT_HEALTH_IMMUNOLOGIST: 'chat-doctor-immunologist',                     // Иммунолог
    CHAT_HEALTH_ANESTHESIOLOGIST: 'chat-doctor-anesthesiologist',             // Анестезиолог
    CHAT_HEALTH_DIETITIAN: 'chat-doctor-dietitian',                           // Диетолог
    CHAT_HEALTH_INFECTIOLOGIST: 'chat-doctor-infectiologist',                 // Инфекционист
    CHAT_HEALTH_COLOPROCTOLOGIST: 'chat-doctor-coloproctologist',             // Колопроктолог
    CHAT_HEALTH_COSMETOLOGIST: 'chat-doctor-cosmetologist',                   // Косметолог
    CHAT_HEALTH_NEPHROLOGIST: 'chat-doctor-nephrologist',                     // Нефролог
    CHAT_HEALTH_OTOLARYNGOLOGIST: 'chat-doctor-otolaryngologist',             // Отоларинголог (ЛОР)
    CHAT_HEALTH_OPHTHALMOLOGIST: 'chat-doctor-ophthalmologist',               // Офтальмолог
    CHAT_HEALTH_PLASTIC_SURGEON: 'chat-doctor-plastic-surgeon',               // Пластический хирург
    CHAT_HEALTH_PSYCHIATRIST: 'chat-doctor-psychiatrist',                     // Психиатр
    CHAT_HEALTH_PSYCHOTHERAPIST: 'chat-doctor-psychotherapist',               // Психотерапевт
    CHAT_HEALTH_PULMONOLOGIST: 'chat-doctor-pulmonologist',                   // Пульмонолог
    CHAT_HEALTH_REHABILITOLOGIST: 'chat-doctor-rehabilitologist',             // Реабилитолог
    CHAT_HEALTH_RHEUMATOLOGIST: 'chat-doctor-rheumatologist',                 // Ревматолог
    CHAT_HEALTH_SEXOLOGIST: 'chat-doctor-sexologist',                         // Сексолог
    CHAT_HEALTH_VASCULAR_SURGEON: 'chat-doctor-vascular-surgeon',             // Сосудистый хирург
    CHAT_HEALTH_SOMNOLOGIST: 'chat-doctor-somnologist',                       // Сомнолог
    CHAT_HEALTH_DENTIST: 'chat-doctor-dentist',                               // Стоматолог
    CHAT_HEALTH_THERAPIST: 'chat-doctor-therapist',                           // Терапевт
    CHAT_HEALTH_TRAUMATOLOGIST: 'chat-doctor-traumatologist',                 // Травматолог
    CHAT_HEALTH_UROLOGIST: 'chat-doctor-urologist',                           // Уролог
    CHAT_HEALTH_PHYSIOTHERAPIST: 'chat-doctor-physiotherapist',               // Физиотерапевт
    CHAT_HEALTH_PHLEBOLOGIST: 'chat-doctor-phlebologist',                     // Флеболог
    CHAT_HEALTH_ENDOSCOPIST: 'chat-doctor-endoscopist',                       // Эндоскопист
    CHAT_HEALTH_PHARMACIST: 'chat-doctor-pharmacist',                         // Фармацевт

    CHAT_ALTMED_ACUPUNCTURE: 'chat-altmed-acupuncture',                       // Специалист по акупунктуре
    CHAT_ALTMED_HERBAL_MEDICINE: 'chat-altmed-herbal-medicine',               // Специалист по траволечению
    CHAT_ALTMED_HOMEOPATHY: 'chat-altmed-homeopathy',                         // Гомеопат
    CHAT_ALTMED_REFLEXOLOGY: 'chat-altmed-reflexology',                       // Рефлексотерапевт
    CHAT_ALTMED_REIKI: 'chat-altmed-reiki',                                   // Специалист по Рэйки
    CHAT_ALTMED_CHIROPRACTOR: 'chat-altmed-chiropractor',                     // Хиропрактик
    CHAT_ALTMED_NATUROPATHY: 'chat-altmed-naturopathy',                       // Натуропат
    CHAT_ALTMED_AROMATHERAPY: 'chat-altmed-aromatherapy',                     // Ароматерапевт
    CHAT_ALTMED_AYURVEDA: 'chat-altmed-ayurveda',                             // Специалист по аюрведе
    CHAT_ALTMED_TCM: 'chat-altmed-tcm',                                       // Специалист по традиционной китайской медицине (ТКМ)
    CHAT_ALTMED_HYDROTHERAPY: 'chat-altmed-hydrotherapy',                     // Специалист по гидротерапии
    CHAT_ALTMED_BIOENERGETICS: 'chat-altmed-bioenergetics',                   // Специалист по биоэнергетике
    CHAT_ALTMED_HYPNOTHERAPY: 'chat-altmed-hypnotherapy',                     // Гипнотерапевт
    CHAT_ALTMED_CRANIOSACRAL: 'chat-altmed-craniosacral',                     // Специалист по краниосакральной терапии
    CHAT_ALTMED_SOUND_THERAPY: 'chat-altmed-sound-therapy',                   // Специалист по звуковой терапии
    CHAT_ALTMED_THERMOTHERAPY: 'chat-altmed-thermotherapy',                   // Специалист по термотерапии
    CHAT_ALTMED_FITOTHERAPY: 'chat-altmed-fitotherapy',                       // Специалист по фитотерапии

    CHAT_PSYCHO_REGULAR: 'chat-psycho-regular',                               // Психолог общей практики
    CHAT_PSYCHO_FAMILY: 'chat-psycho-family',                                 // Семейный психолог
    CHAT_PSYCHO_COUPLES: 'chat-psycho-couples',                               // Психолог для пар
    CHAT_PSYCHO_CHILD: 'chat-psycho-child',                                   // Детский психолог
    CHAT_PSYCHO_SCHOOL: 'chat-psycho-school',                                 // Школьный психолог
    CHAT_PSYCHO_COGNITIVE_BEHAVIORAL: 'chat-psycho-cognitive-behavioral',     // Психолог когнитивно-поведенческой терапии (КПТ)
    CHAT_PSYCHO_GESTALT: 'chat-psycho-gestalt',                               // Гештальт-психолог
    CHAT_PSYCHO_PSYCHODYNAMIC: 'chat-psycho-psychodynamic',                   // Психодинамический психолог
    CHAT_PSYCHO_CLINICAL: 'chat-psycho-clinical',                             // Клинический психолог
    CHAT_PSYCHO_SPORTS: 'chat-psycho-sports',                                 // Спортивный психолог
    CHAT_PSYCHO_ORGANIZATIONAL: 'chat-psycho-organizational',                 // Организационный психолог
    CHAT_PSYCHO_TRAUMA: 'chat-psycho-trauma',                                 // Психолог по травматерапии
    CHAT_PSYCHO_NEUROPSYCHOLOGIST: 'chat-psycho-neuropsychologist',           // Нейропсихолог
    CHAT_PSYCHO_EATING_DISORDER: 'chat-psycho-eating-disorder',               // Психолог по расстройствам пищевого поведения (РПП)
    CHAT_PSYCHO_NARCOLOGIST: 'chat-psycho-narcologist',                       // Психолог-нарколог
    CHAT_PSYCHO_ADDICTION: 'chat-psycho-addiction',                           // Психолог по зависимостям
    CHAT_PSYCHO_GRIEF: 'chat-psycho-grief',                                   // Психолог по вопросам горя и утраты
    CHAT_PSYCHO_WORKPLACE: 'chat-psycho-workplace',                           // Психолог по вопросам рабочего места
    CHAT_PSYCHO_SEXUALITY: 'chat-psycho-sexuality',                           // Психолог по вопросам сексуальности
    CHAT_PSYCHO_LGBTQ: 'chat-psycho-lgbtq',                                   // Психолог для LGBTQ+

    CHAT_VET: 'chat-vet',                                                     // Ветеринар
    CHAT_VET_RECEPTIONIST: 'chat-vet-receptionist',                           // Ветеринарный регистратор
    CHAT_VET_CARDIOLOGIST: 'chat-vet-cardiologist',                           // Ветеринар-кардиолог
    CHAT_VET_DERMATOLOGIST: 'chat-vet-dermatologist',                         // Ветеринар-дерматолог
    CHAT_VET_ENDOCRINOLOGIST: 'chat-vet-endocrinologist',                     // Ветеринар-эндокринолог
    CHAT_VET_GASTROENTEROLOGIST: 'chat-vet-gastroenterologist',               // Ветеринар-гастроэнтеролог
    CHAT_VET_SURGEON: 'chat-vet-surgeon',                                     // Ветеринар-хирург
    CHAT_VET_GYNECOLOGIST: 'chat-vet-gynecologist',                           // Ветеринар-гинеколог
    CHAT_VET_NEUROLOGIST: 'chat-vet-neurologist',                             // Ветеринар-невролог
    CHAT_VET_ONCOLOGIST: 'chat-vet-oncologist',                               // Ветеринар-онколог
    CHAT_VET_ORTHOPEDIST: 'chat-vet-orthopedist',                             // Ветеринар-ортопед
    CHAT_VET_PEDIATRICIAN: 'chat-vet-pediatrician',                           // Ветеринар-педиатр
    CHAT_VET_ALLERGIST: 'chat-vet-allergist',                                 // Ветеринар-аллерголог
    CHAT_VET_IMMUNOLOGIST: 'chat-vet-immunologist',                           // Ветеринар-иммунолог
    CHAT_VET_ANESTHESIOLOGIST: 'chat-vet-anesthesiologist',                   // Ветеринар-анестезиолог
    CHAT_VET_DIETITIAN: 'chat-vet-dietitian',                                 // Ветеринар-диетолог
    CHAT_VET_INFECTIOLOGIST: 'chat-vet-infectiologist',                       // Ветеринар-инфекционист
    CHAT_VET_COLOPROCTOLOGIST: 'chat-vet-coloproctologist',                   // Ветеринар-колопроктолог
    CHAT_VET_COSMETOLOGIST: 'chat-vet-cosmetologist',                         // Ветеринар-косметолог
    CHAT_VET_NEPHROLOGIST: 'chat-vet-nephrologist',                           // Ветеринар-нефролог
    CHAT_VET_OTOLARYNGOLOGIST: 'chat-vet-otolaryngologist',                   // Ветеринар-отоларинголог (ЛОР)
    CHAT_VET_OPHTHALMOLOGIST: 'chat-vet-ophthalmologist',                     // Ветеринар-офтальмолог
    CHAT_VET_PULMONOLOGIST: 'chat-vet-pulmonologist',                         // Ветеринар-пульмонолог
    CHAT_VET_PSYCHIATRIST: 'chat-vet-psychiatrist',                           // Ветеринар-психиатр
    CHAT_VET_PSYCHOTHERAPIST: 'chat-vet-psychotherapist',                     // Ветеринар-психотерапевт
    CHAT_VET_PLASTIC_SURGEON: 'chat-vet-plastic-surgeon',                     // Ветеринар-пластический хирург
    CHAT_VET_RHEUMATOLOGIST: 'chat-vet-rheumatologist',                       // Ветеринар-ревматолог
    CHAT_VET_SEXOLOGIST: 'chat-vet-sexologist',                               // Ветеринар-сексолог
    CHAT_VET_VASCULAR_SURGEON: 'chat-vet-vascular-surgeon',                   // Ветеринар-сосудистый хирург
    CHAT_VET_SOMNOLOGIST: 'chat-vet-somnologist',                             // Ветеринар-сомнолог
    CHAT_VET_DENTIST: 'chat-vet-dentist',                                     // Ветеринар-стоматолог
    CHAT_VET_THERAPIST: 'chat-vet-therapist',                                 // Ветеринар-терапевт
    CHAT_VET_TRAINER: 'chat-vet-trainer',                                     // Дрессировщик животных
    CHAT_VET_TRAUMATOLOGIST: 'chat-vet-traumatologist',                       // Ветеринар-травматолог
    CHAT_VET_UROLOGIST: 'chat-vet-urologist',                                 // Ветеринар-уролог
    CHAT_VET_PHYSIOTHERAPIST: 'chat-vet-physiotherapist',                     // Ветеринар-физиотерапевт
    CHAT_VET_PHLEBOLOGIST: 'chat-vet-phlebologist',                           // Ветеринар-флеболог
    CHAT_VET_ENDOSCOPIST: 'chat-vet-endoscopist',                             // Ветеринар-эндоскопист
    
    CHAT_TEACHER_LANGUAGE: 'chat-teacher-language',                           // Язык
    CHAT_TEACHER_LITERATURE: 'chat-teacher-literature',                       // Литература
    CHAT_TEACHER_MATH: 'chat-teacher-math',                                   // Математика
    CHAT_TEACHER_GEOMETRY: 'chat-teacher-geometry',                           // Геометрия (NEW)
    CHAT_TEACHER_PHYSICS: 'chat-teacher-physics',                             // Физика
    CHAT_TEACHER_ASTRONOMY: 'chat-teacher-astronomy',                         // Астрономия (NEW)
    CHAT_TEACHER_CHEMISTRY: 'chat-teacher-chemistry',                         // Химия
    CHAT_TEACHER_ART: 'chat-teacher-art',                                     // Рисование
    CHAT_TEACHER_MUSIC: 'chat-teacher-music',                                 // Музыка
    CHAT_TEACHER_PHYSICAL_EDUCATION: 'chat-teacher-physical-education',       // Физкультура
    CHAT_TEACHER_TECHNOLOGY: 'chat-teacher-technology',                       // Технология
    CHAT_TEACHER_COMPUTER_SCIENCE: 'chat-teacher-computer-science',           // Информатика
    CHAT_TEACHER_DATA_SCIENCE: 'chat-teacher-data-science',                   // Обработка данных
    CHAT_TEACHER_PROGRAMMING: 'chat-teacher-programming',                     // Программирования
    CHAT_TEACHER_GRAPHIC_DESIGN: 'chat-teacher-graphic-design',               // Графический дизайн
    CHAT_TEACHER_LAW: 'chat-teacher-law',                                     // Право
    CHAT_TEACHER_CIVICS: 'chat-teacher-civics',                               // Обществознание
    CHAT_TEACHER_HISTORY: 'chat-teacher-history',                             // История
    CHAT_TEACHER_GEOGRAPHY: 'chat-teacher-geography',                         // География
    CHAT_TEACHER_BIOLOGY: 'chat-teacher-biology',                             // Биология
    CHAT_TEACHER_ENVIRONMENTAL_SCIENCE: 'chat-teacher-environmental-science', // Экология
    CHAT_TEACHER_RELIGION: 'chat-teacher-religion',                           // Религия
    CHAT_TEACHER_PHILOSOPHY: 'chat-teacher-philosophy',                       // Философии
    CHAT_TEACHER_ECONOMICS: 'chat-teacher-economics',                         // Экономика
    CHAT_TEACHER_BUSINESS: 'chat-teacher-business',                           // Предпринимательство
    CHAT_TEACHER_MARKETING: 'chat-teacher-marketing',                         // Маркетинг
    CHAT_TEACHER_SOCIOLOGY: 'chat-teacher-sociology',                         // Социология
    CHAT_TEACHER_PSYCHOLOGY: 'chat-teacher-psychology',                       // Психология
    CHAT_TEACHER_MEDICINE: 'chat-teacher-medicine',                           // Медицина
    CHAT_TEACHER_DRAMA: 'chat-teacher-drama',                                 // Театральное искусство
    CHAT_TEACHER_ORATORY: 'chat-teacher-oratory',                             // Ораторское искусство
    CHAT_TEACHER_FILMMAKING: 'chat-teacher-filmmaking',                       // Кинематография

    CHAT_BUSINESS_STRATEGY: 'chat-business-strategy',                         // Стратегия бизнеса
    CHAT_BUSINESS_DEVELOPMENT: 'chat-business-development',                   // Развитие бизнеса
    CHAT_BUSINESS_STARTUP: 'chat-business-startup',                           // Стартапы
    CHAT_BUSINESS_INVESTMENT: 'chat-business-investment',                     // Инвестиции
    CHAT_BUSINESS_FINANCE: 'chat-business-finance',                           // Финансы
    CHAT_BUSINESS_MARKETING: 'chat-business-marketing',                       // Маркетинг
    CHAT_BUSINESS_SALES: 'chat-business-sales',                               // Продажи
    CHAT_BUSINESS_HR: 'chat-business-hr',                                     // Управление персоналом (HR)
    CHAT_BUSINESS_OPERATIONS: 'chat-business-operations',                     // Операционная деятельность
    CHAT_BUSINESS_TECHNOLOGY: 'chat-business-technology',                     // Технологии
    CHAT_BUSINESS_IT: 'chat-business-it',                                     // Информационные технологии
    CHAT_BUSINESS_LEGAL: 'chat-business-legal',                               // Юриспруденция
    CHAT_BUSINESS_ACCOUNTING: 'chat-business-accounting',                     // Бухгалтерия
    CHAT_BUSINESS_TAX: 'chat-business-tax',                                   // Оптимизация налогов
    CHAT_BUSINESS_TRAVEL: 'chat-businesstravel-consultant',                   // Организация командировок
    CHAT_BUSINESS_DOCUMENT_MANAGEMENT: 'chat-business-document-management',   // Документооборот
    CHAT_BUSINESS_SUPPLY_CHAIN: 'chat-business-supply-chain',                 // Цепочки поставок
    CHAT_BUSINESS_PROJECT_MANAGEMENT: 'chat-business-project-management',     // Управление проектами
    CHAT_BUSINESS_CHANGE_MANAGEMENT: 'chat-business-change-management',       // Управление изменениями
    CHAT_BUSINESS_RISK_MANAGEMENT: 'chat-business-risk-management',           // Управление рисками
    CHAT_BUSINESS_SUSTAINABILITY: 'chat-business-sustainability',             // Устойчивое развитие
    CHAT_BUSINESS_INNOVATION: 'chat-business-innovation',                     // Инновации
    CHAT_BUSINESS_EXPORT_IMPORT: 'chat-business-export-import',               // Экспорт и импорт
    CHAT_BUSINESS_FRANCHISING: 'chat-business-franchising',                   // Франчайзинг
    CHAT_BUSINESS_MERGE_ACQUISITION: 'chat-business-merger-acquisition',     // Слияния и поглощения
    CHAT_BUSINESS_CUSTOMER_SERVICE: 'chat-business-customer-service',         // Клиентский сервис
    CHAT_BUSINESS_COMPLIANCE: 'chat-business-compliance',                     // Соответствие стандартам
    CHAT_BUSINESS_VALUATION: 'chat-business-valuation',                       // Оценка бизнеса
    CHAT_BUSINESS_COACH: 'chat-business-coach',                               // Бизнес-коучинг
    CHAT_BUSINESS_PSYCHOLOGIST: 'chat-business-psychologist',                 // Бизнес-психология

    CHAT_LEGAL_GENERAL: 'chat-legal-general',                                 // Юрист общего профиля
    CHAT_LEGAL_CONTRACTS: 'chat-legal-contracts',                             // Консультант по договорам
    CHAT_LEGAL_NOTARY: 'chat-legal-notary',                                   // Нотариус
    CHAT_LEGAL_CORPORATE: 'chat-legal-corporate',                             // Корпоративный юрист
    CHAT_LEGAL_TAX: 'chat-legal-tax',                                         // Налоговый юрист
    CHAT_LEGAL_LABOR: 'chat-legal-labor',                                     // Юрист по трудовому праву
    CHAT_LEGAL_INTELLECTUAL_PROPERTY: 'chat-legal-intellectual-property',     // Юрист по интеллектуальной собственности
    CHAT_LEGAL_LITIGATION: 'chat-legal-litigation',                           // Юрист по судебным разбирательствам
    CHAT_LEGAL_COURT: 'chat-legal-court',                                     // Судебный процесс (имитация)
    CHAT_LEGAL_MERGES_ACQUISITIONS: 'chat-legal-mergers-acquisitions',        // Юрист по слияниям и поглощениям
    CHAT_LEGAL_ADMINISTRATIVE: 'chat-legal-administrative',                   // Юрист по административному праву
    CHAT_LEGAL_ARBITRATION: 'chat-legal-arbitration',                         // Юрист по арбитражному праву
    CHAT_LEGAL_CRIMINAL: 'chat-legal-criminal',                               // Юрист по уголовному праву
    CHAT_LEGAL_FAMILY: 'chat-legal-family',                                   // Юрист по семейному праву
    CHAT_LEGAL_REAL_ESTATE: 'chat-legal-real-estate',                         // Юрист по недвижимости
    CHAT_LEGAL_INTERNATIONAL: 'chat-legal-international',                     // Юрист по международному праву
    CHAT_LEGAL_IMMIGRATION: 'chat-legal-immigration',                         // Юрист по иммиграционному праву
    CHAT_LEGAL_CONSUMER_PROTECTION: 'chat-legal-consumer-protection',         // Юрист по защите прав потребителей
    CHAT_LEGAL_ENVIRONMENTAL: 'chat-legal-environmental',                     // Юрист по экологическому праву
    CHAT_LEGAL_DATA_PRIVACY: 'chat-legal-data-privacy',                       // Юрист по защите данных
    CHAT_LEGAL_HEALTHCARE: 'chat-legal-healthcare',                           // Юрист по медицинскому праву
    CHAT_LEGAL_BANKRUPTCY: 'chat-legal-bankruptcy',                           // Юрист по банкротству
    CHAT_LEGAL_COMPLIANCE: 'chat-legal-compliance',                           // Юрист по вопросам соответствия требованиям
    CHAT_LEGAL_SPORTS: 'chat-legal-sports',                                   // Спортивный юрист
    CHAT_LEGAL_ENTERTAINMENT: 'chat-legal-entertainment',                     // Юрист по медиа и развлечениям
    CHAT_LEGAL_TECHNOLOGY: 'chat-legal-technology',                           // Юрист по технологиям
    CHAT_LEGAL_STARTUP: 'chat-legal-startup',                                 // Юрист для стартапов
    CHAT_LEGAL_TRUSTS_ESTATES: 'chat-legal-trusts-estates',                   // Юрист по наследству и трастам
    CHAT_LEGAL_SECURITY: 'chat-legal-security',                               // Юрист по безопасности (включая корпоративную и IT)

    CHAT_LIFE_JOB: 'chat-life-job',                                           // Поиск работы
    CHAT_LIFE_CAREER_DEVELOPMENT: 'chat-life-career-development',             // Карьерное развитие
    CHAT_LIFE_SELF_DEVELOPMENT: 'chat-life-self-development',                 // Саморазвитие
    CHAT_LIFE_TRAVEL: 'chat-life-travel',                                     // Путешествия
    CHAT_LIFE_RELOCATION: 'chat-life-relocation',                             // Релокация
    CHAT_LIFE_FINANCIAL: 'chat-life-financial',                               // Личные финансы
    CHAT_LIFE_INSURANCE: 'chat-life-insurance',                               // Страхование
    CHAT_LIFE_RETIREMENT: 'chat-life-retirement',                             // Планирование пенсии
    CHAT_LIFE_HEALTHY_LIFESTYLE: 'chat-life-healthy-lifestyle',               // Здоровый образ жизни (ЗОЖ)
    CHAT_LIFE_NUTRITION: 'chat-life-nutrition',                               // Консультации по питанию
    CHAT_LIFE_FITNESS: 'chat-life-fitness',                                   // Фитнес и здоровье
    CHAT_LIFE_YOGA: 'chat-life-yoga',                                         // Йога
    CHAT_LIFE_MEMORY_IQ: 'chat-life-memory_iq',                               // Память и IQ
    CHAT_LIFE_MENTAL_EQ: 'chat-life-mental-eq',                               // Ментальное здоровье и EQ
    CHAT_LIFE_REAL_ESTATE: 'chat-life-real-estate',                           // Недвижимость
    CHAT_LIFE_HOME_RENOVATION: 'chat-life-home-renovation',                   // Ремонт и улучшение жилья
    CHAT_LIFE_EDUCATION: 'chat-life-education',                               // Образовательные услуги
    CHAT_LIFE_STUDY_ABROAD: 'chat-life-study-abroad',                         // Обучение за границей
    CHAT_LIFE_MARRIAGE: 'chat-life-marriage',                                 // Семейные отношения и брак
    CHAT_LIFE_PARENTING: 'chat-life-parenting',                               // Воспитание детей
    CHAT_LIFE_SMALL_BUSINESS: 'chat-life-small-business',                     // Малый бизнес
    CHAT_LIFE_PERSONAL_SECURITY: 'chat-life-personal-security',               // Личная безопасность
    CHAT_LIFE_HOBBIES: 'chat-life-hobbies',                                   // Хобби и личные интересы
    CHAT_LIFE_PET_CARE: 'chat-life-pet-care',                                 // Уход за домашними животными

    CHAT_HOUSE_ELECTRICIAN: 'chat-house-electrician',                         // Электрик
    CHAT_HOUSE_PLUMBER: 'chat-house-plumber',                                 // Сантехник
    CHAT_HOUSE_CLEANER: 'chat-house-cleaner',                                 // Уборщик
    CHAT_HOUSE_GARDENER: 'chat-house-gardener',                               // Садовник
    CHAT_HOUSE_SECURITY: 'chat-house-security',                               // Специалист по безопасности
    CHAT_HOUSE_APPLIANCE_REPAIR: 'chat-house-appliance-repair',               // Мастер по ремонту бытовой техники
    CHAT_HOUSE_CARPENTER: 'chat-house-carpenter',                             // Плотник
    CHAT_HOUSE_PLASTERER: 'chat-house-plasterer',                             // Штукатур
    CHAT_HOUSE_PAINTER: 'chat-house-painter',                                 // Маляр
    CHAT_HOUSE_TILE_SETTER: 'chat-house-tile-setter',                         // Плиточник
    CHAT_HOUSE_CONSTRUCTOR: 'chat-house-constructor',                         // Проектировщик
    CHAT_HOUSE_INTERIOR_DESIGNER: 'chat-house-interior-designer',             // Дизайнер интерьера
    CHAT_HOUSE_FENGSHUI : 'chat-house-fengshui',                              // Специалист по Феншуй
    CHAT_HOUSE_LANDSCAPE_ARCHITECT: 'chat-house-landscape-architect',         // Ландшафтный архитектор
    CHAT_HOUSE_CONTRACTOR: 'chat-house-contractor',                           // Подрядчик
    CHAT_HOUSE_MOVING_HELPER: 'chat-house-moving-helper',                     // Помощник по переезду
    CHAT_HOUSE_FURNITURE_ASSEMBLER: 'chat-house-furniture-assembler',         // Специалист по сборке мебели
    CHAT_HOUSE_DOOR_LOCKS: 'chat-house-door-locks',                           // Специалист по дверям и замкам
    CHAT_HOUSE_SMART_HOME_SPECIALIST: 'chat-house-smart-home-specialist',     // Специалист по умным домам
    CHAT_HOUSE_HOME_THEATER_EXPERT: 'chat-house-home-theater-expert',         // Специалист по домашним кинотеатрам
    CHAT_HOUSE_SOLAR_PANEL_SPECIALIST: 'chat-house-solar-panel-specialist',   // Специалист по солнечным панелям
    CHAT_HOUSE_ROOFER: 'chat-house-roofer',                                   // Кровельщик
    CHAT_HOUSE_WINDOW_INSTALLER: 'chat-house-window-installer',               // Установщик окон
    CHAT_HOUSE_FLOORING_EXPERT: 'chat-house-flooring-expert',                 // Специалист по напольным покрытиям
    CHAT_HOUSE_HVAC: 'chat-house-hvac',                                       // Специалист по вентиляции и кондиционированию
    CHAT_HOUSE_INSULATION_EXPERT: 'chat-house-insulation-expert',             // Специалист по утеплению
    CHAT_HOUSE_POOL_SPECIALIST: 'chat-house-pool-specialist',                 // Специалист по бассейнам
    CHAT_HOUSE_SEPTIC_SYSTEM_EXPERT: 'chat-house-septic-system-expert',       // Эксперт по септическим системам
    CHAT_HOUSE_MOLD_REMOVAL_SPECIALIST: 'chat-house-mold-removal-specialist', // Специалист по борьбе с плесенью
    CHAT_HOUSE_PEST_CONTROL_SPECIALIST: 'chat-house-pest-control-specialist', // Специалист по борьбе с вредителями

    CHAT_CAR_MECHANIC: 'chat-car-mechanic',                                   // Автомеханик
    CHAT_CAR_ELECTRICIAN: 'chat-car-electrician',                             // Автоэлектрик
    CHAT_CAR_SECURITY: 'chat-car-security',                                   // Специалист по безопасности
    CHAT_CAR_ENGINE_SPECIALIST: 'chat-car-engine-specialist',                 // Специалист по двигателям
    CHAT_CAR_TRANSMISSION_SPECIALIST: 'chat-car-transmission-specialist',     // Специалист по трансмиссии
    CHAT_CAR_BRAKE_SPECIALIST: 'chat-car-brake-specialist',                   // Специалист по тормозам
    CHAT_CAR_TIRES_SPECIALIST: 'chat-car-tires-specialist',                   // Специалист по шинам
    CHAT_CAR_SUSPENSION_SPECIALIST: 'chat-car-suspension-specialist',         // Специалист по подвеске
    CHAT_CAR_AIR_CONDITIONING_SPECIALIST: 'chat-car-air-conditioning-specialist', // Специалист по кондиционерам
    CHAT_CAR_BODYWORK_SPECIALIST: 'chat-car-bodywork-specialist',             // Специалист по кузовным работам
    CHAT_CAR_PAINT_SPECIALIST: 'chat-car-paint-specialist',                   // Специалист по покраске
    CHAT_CAR_WINDSHIELD_REPAIR_SPECIALIST: 'chat-car-windshield-repair-specialist', // Специалист по ремонту стекол
    CHAT_CAR_AUDIO_SPECIALIST: 'chat-car-audio-specialist',                   // Специалист по аудиосистемам
    CHAT_CAR_DIAGNOSTICS_SPECIALIST: 'chat-car-diagnostics-specialist',       // Специалист по диагностике
    CHAT_CAR_DETAILER: 'chat-car-detailer',                                   // Специалист по детейлингу
    CHAT_CAR_TUNING_SPECIALIST: 'chat-car-tuning-specialist',                 // Специалист по тюнингу
    CHAT_CAR_EXHAUST_SYSTEM_SPECIALIST: 'chat-car-exhaust-system-specialist', // Специалист по выхлопной системе
    CHAT_CAR_CLUTCH_SPECIALIST: 'chat-car-clutch-specialist',                 // Специалист по сцеплению
    CHAT_CAR_FUEL_SYSTEM_SPECIALIST: 'chat-car-fuel-system-specialist',       // Специалист по топливной системе
    CHAT_CAR_GLASS_TINTING_SPECIALIST: 'chat-car-glass-tinting-specialist',   // Специалист по тонировке стекол
    CHAT_CAR_RADIATOR_SPECIALIST: 'chat-car-radiator-specialist',             // Специалист по радиаторам
    CHAT_CAR_BATTERY_SPECIALIST: 'chat-car-battery-specialist',               // Специалист по аккумуляторам
    CHAT_CAR_ALIGNMENT_SPECIALIST: 'chat-car-alignment-specialist',           // Специалист по развалу-схождению
    CHAT_CAR_INTERIOR_REPAIR_SPECIALIST: 'chat-car-interior-repair-specialist', // Специалист по ремонту салона
    CHAT_CAR_LOCKSMITH: 'chat-car-locksmith',                                 // Автослесарь по замкам
    CHAT_CAR_HYBRID_SPECIALIST: 'chat-car-hybrid-specialist',                 // Специалист по гибридным автомобилям
    CHAT_CAR_LAWYER: 'chat-car-lawyer',                                       // Автоюрист
    CHAT_CAR_SALES_SPECIALIST: 'chat-car-sales-specialist',                   // Специалист по покупке-продаже автомобилей

    CHAT_RELIGION_SPIRITUAL_MENTOR: 'chat-religion-spiritual-mentor',         // Духовный наставник
    CHAT_RELIGION_SPIRITUAL_CARE: 'chat-religion-spiritual-care',             // Консультант по духовной поддержке
    CHAT_RELIGION_RELIGIOUS_COUNSELOR: 'chat-religion-religious-counselor',   // Консультант по религиозной поддержке
    CHAT_RELIGION_INTERFAITH_CONSULTANT: 'chat-religion-interfaith-consultant', // Консультант по межконфессиональным вопросам
    CHAT_RELIGION_PRIEST: 'chat-religion-priest',                             // Священник (Христианство)
    CHAT_RELIGION_CHAPLAIN: 'chat-religion-chaplain',                         // Капеллан (Христианство)
    CHAT_RELIGION_IMAM: 'chat-religion-imam',                                 // Имам (Ислам)
    CHAT_RELIGION_RABBI: 'chat-religion-rabbi',                               // Раввин (Иудаизм)
    CHAT_RELIGION_BUDDHIST_MONK: 'chat-religion-buddhist-monk',               // Буддийский монах (Буддизм)
    CHAT_RELIGION_YOGA_TEACHER: 'chat-religion-yoga-teacher',                 // Учитель йоги (Индуизм)
    CHAT_RELIGION_TANTRA_TEACHER: 'chat-religion-tantra-teacher',             // Учитель тантры (Буддизм, Индуизм)
    CHAT_RELIGION_VEDIC_PRIEST: 'chat-religion-vedic-priest',                 // Ведический священник (Индуизм)
    CHAT_RELIGION_SPIRITUAL_HEALER: 'chat-religion-spiritual-healer',         // Духовный целитель
    CHAT_RELIGION_MEDITATION_GUIDE: 'chat-religion-meditation-guide',         // Проводник медитации
    CHAT_RELIGION_SCHOLAR_OF_RELIGIOUS_TEXTS: 'chat-religion-scholar-texts',  // Специалист по религиозным текстам
    CHAT_RELIGION_THEOLOGIST: 'chat-religion-theologist',                     // Теолог
    CHAT_RELIGION_ATHEIST_COUNSELOR: 'chat-religion-atheist-counselor',       // Консультант по вопросам атеизма
    CHAT_RELIGION_PHILOSOPHER: 'chat-religion-philosopher',                   // Философ
    CHAT_RELIGION_ETHICS_SPECIALIST: 'chat-religion-ethics-specialist',       // Специалист по этике

    CHAT_ESOTERIC_NUMEROLOGY: 'chat-esoteric-numerology',                     // Нумеролог
    CHAT_ESOTERIC_BIORHYTHMS: 'chat-esoteric-biorhythms',                     // Специалист по биоритмам
    CHAT_ESOTERIC_PALMISTRY: 'chat-esoteric-palmistry',                       // Хиромант (гадание по руке)
    CHAT_ESOTERIC_AURA_READING: 'chat-esoteric-aura-reading',                 // Специалист по чтению ауры
    CHAT_ESOTERIC_CRYSTALS: 'chat-esoteric-crystals',                         // Специалист по кристаллам
    CHAT_ESOTERIC_RUNES: 'chat-esoteric-runes',                               // Гадание по руке
    CHAT_ESOTERIC_I_CHING: 'chat-esoteric-i-ching',                           // Гадание по И-Цзин (Книга Перемен)
    CHAT_ESOTERIC_PSYCHIC: 'chat-esoteric-psychic',                           // Экстрасенс
    CHAT_ESOTERIC_AKASHIC_RECORDS: 'chat-esoteric-akashic-records',           // Специалист по чтению Акаши
    CHAT_ESOTERIC_MEDIUM: 'chat-esoteric-medium',                             // Медиум (контакт с духами)
    CHAT_ESOTERIC_SHAMAN: 'chat-esoteric-shaman',                             // Шаман
    CHAT_ESOTERIC_PENDULUM_DOWSING: 'chat-esoteric-pendulum-dowsing',         // Гадание на маятнике
    CHAT_ESOTERIC_HERBALISM: 'chat-esoteric-herbalism',                       // Специалист по травам

    CHAT_TAROT: 'chat-tarot',                                                 // Таро-консультант
    CHAT_TAROT_TRADITIONAL: 'chat-tarot-traditional',                         // Традиционный Таро-консультант
    CHAT_TAROT_LENORMAND: 'chat-tarot-lenormand',                             // Таро-консультант по системе Ленорман
    CHAT_TAROT_ORACLE: 'chat-tarot-oracle',                                   // Таро-консультант с Оракулом
    CHAT_TAROT_CELTIC: 'chat-tarot-celtic',                                   // Кельтский Таро-консультант
    CHAT_TAROT_NUMERICAL: 'chat-tarot-numerical',                             // Нумерологический Таро-консультант
    CHAT_TAROT_ASTRO: 'chat-tarot-astro',                                     // Астрологический Таро-консультант
    CHAT_TAROT_THERAPY: 'chat-tarot-therapy',                                 // Терапевтический Таро-консультант
    CHAT_TAROT_SPIRIT: 'chat-tarot-spirit',                                   // Таро-консультант по духовным вопросам
    CHAT_TAROT_INTUITIVE: 'chat-tarot-intuitive',                             // Интуитивный Таро-консультант
    CHAT_TAROT_PSYCHIC: 'chat-tarot-psychic',                                 // Психический Таро-консультант
    
    CHAT_HOROSCOPE: 'chat-horoscope',                                         // Гороскоп общего профиля
    CHAT_HOROSCOPE_REGULAR: 'chat-horoscope-regular',                         // Традиционный гороскоп
    CHAT_HOROSCOPE_EASTERN: 'chat-horoscope-eastern',                         // Восточный гороскоп
    CHAT_HOROSCOPE_BUSINESS: 'chat-horoscope-business',                       // Бизнес-гороскоп
    CHAT_HOROSCOPE_COMPATIBILITY: 'chat-horoscope-compatibility',             // Гороскоп совместимости
    CHAT_HOROSCOPE_LOVE: 'chat-horoscope-love',                               // Гороскоп любви
    CHAT_HOROSCOPE_SEX: 'chat-horoscope-sex',                                 // Сексуальный гороскоп
    CHAT_HOROSCOPE_HEALTH: 'chat-horoscope-health',                           // Гороскоп здоровья
    CHAT_HOROSCOPE_LUNAR: 'chat-horoscope-lunar',                             // Лунный гороскоп
    CHAT_HOROSCOPE_SOLAR: 'chat-horoscope-solar',                             // Солнечный гороскоп
    CHAT_HOROSCOPE_CELESTIAL: 'chat-horoscope-celestial',                     // Небесный гороскоп (анализ планетных положений)
    CHAT_HOROSCOPE_PSYCHOLOGICAL: 'chat-horoscope-psychological',             // Психологический гороскоп
    CHAT_HOROSCOPE_DRACONIC: 'chat-horoscope-draconic',                       // Драконический гороскоп (кармический)
    CHAT_HOROSCOPE_HOURLY: 'chat-horoscope-hourly',                           // Часовой гороскоп
    CHAT_HOROSCOPE_WEEKLY: 'chat-horoscope-weekly',                           // Еженедельный гороскоп
    CHAT_HOROSCOPE_YEARLY: 'chat-horoscope-yearly',                           // Ежегодный гороскоп
        
    CHAT_ASTROLOGY_NATAL: 'chat-astrology-natal',                             // Астролог по натальной карте
    CHAT_ASTROLOGY_HORO: 'chat-astrology-horo',                               // Астрологический гороскоп
    CHAT_ASTROLOGY_SYNASTRY: 'chat-astrology-synastry',                       // Астролог по синастрии (совместимость партнеров)
    CHAT_ASTROLOGY_SOLAR_RETURN: 'chat-astrology-solar-return',               // Астролог по солнечным возвращениям
    CHAT_ASTROLOGY_LUNAR_CYCLE: 'chat-astrology-lunar-cycle',                 // Астролог по лунным циклам
    CHAT_ASTROLOGY_TRANSITS: 'chat-astrology-transits',                       // Астролог по транзитам планет
    CHAT_ASTROLOGY_PROGRESSIONS: 'chat-astrology-progressions',               // Астролог по прогрессиям
    CHAT_ASTROLOGY_KARMIC_ASTROLOGY: 'chat-astrology-karmic',                 // Астролог по кармической астрологии
    CHAT_ASTROLOGY_MEDICAL_ASTROLOGY: 'chat-astrology-medical',               // Астролог по медицинской астрологии
    CHAT_ASTROLOGY_VEDIC: 'chat-astrology-vedic',                             // Ведический астролог
        
    CHAT_TALISMAN_CALPACA: 'chat-talisman-calpaca',                           // Талисман Калпака
    CHAT_TALISMAN_ROSA: 'chat-talisman-rosa',                                 // Талисман Роза
    CHAT_TALISMAN_MANDALA: 'chat-talisman-mandala',                           // Талисман Мандала
    CHAT_TALISMAN_BACA: 'chat-talisman-baca',                                 // Талисман Бака
    CHAT_TALISMAN_KUITA: 'chat-talisman-kuita',                               // Талисман Куита
    CHAT_TALISMAN_TRINKETS: 'chat-talisman-trinkets',                         // Амулеты

    CHAT_UNKNOWN: 'chat-unknown',                                             // Консультант
};

export const genderTypes = {
    MALE: 'male',
    FEMALE: 'female',
    OTHER: 'other'
};

export const countries = {
    COUNTRY_AF: 'af',     // Afghanistan
    COUNTRY_AL: 'al',     // Albania
    COUNTRY_DZ: 'dz',     // Algeria
    COUNTRY_AD: 'ad',     // Andorra
    COUNTRY_AO: 'ao',     // Angola
    COUNTRY_AG: 'ag',     // Antigua and Barbuda
    COUNTRY_AR: 'ar',     // Argentina
    COUNTRY_AM: 'am',     // Armenia
    COUNTRY_AU: 'au',     // Australia
    COUNTRY_AT: 'at',     // Austria
    COUNTRY_AZ: 'az',     // Azerbaijan
    COUNTRY_BS: 'bs',     // Bahamas
    COUNTRY_BH: 'bh',     // Bahrain
    COUNTRY_BD: 'bd',     // Bangladesh
    COUNTRY_BB: 'bb',     // Barbados
    COUNTRY_BY: 'by',     // Belarus
    COUNTRY_BE: 'be',     // Belgium
    COUNTRY_BZ: 'bz',     // Belize
    COUNTRY_BJ: 'bj',     // Benin
    COUNTRY_BT: 'bt',     // Bhutan
    COUNTRY_BO: 'bo',     // Bolivia
    COUNTRY_BA: 'ba',     // Bosnia and Herzegovina
    COUNTRY_BW: 'bw',     // Botswana
    COUNTRY_BR: 'br',     // Brazil
    COUNTRY_BN: 'bn',     // Brunei
    COUNTRY_BG: 'bg',     // Bulgaria
    COUNTRY_BF: 'bf',     // Burkina Faso
    COUNTRY_BI: 'bi',     // Burundi
    COUNTRY_KH: 'kh',     // Cambodia
    COUNTRY_CM: 'cm',     // Cameroon
    COUNTRY_CA: 'ca',     // Canada
    COUNTRY_CV: 'cv',     // Cape Verde
    COUNTRY_CF: 'cf',     // Central African Republic
    COUNTRY_TD: 'td',     // Chad
    COUNTRY_CL: 'cl',     // Chile
    COUNTRY_CN: 'cn',     // China
    COUNTRY_CO: 'co',     // Colombia
    COUNTRY_KM: 'km',     // Comoros
    COUNTRY_CG: 'cg',     // Congo
    COUNTRY_CD: 'cd',     // Democratic Republic of the Congo
    COUNTRY_CR: 'cr',     // Costa Rica
    COUNTRY_HR: 'hr',     // Croatia
    COUNTRY_CU: 'cu',     // Cuba
    COUNTRY_CY: 'cy',     // Cyprus
    COUNTRY_CZ: 'cz',     // Czech Republic
    COUNTRY_DK: 'dk',     // Denmark
    COUNTRY_DJ: 'dj',     // Djibouti
    COUNTRY_DM: 'dm',     // Dominica
    COUNTRY_DO: 'do',     // Dominican Republic
    COUNTRY_EC: 'ec',     // Ecuador
    COUNTRY_EG: 'eg',     // Egypt
    COUNTRY_SV: 'sv',     // El Salvador
    COUNTRY_GQ: 'gq',     // Equatorial Guinea
    COUNTRY_ER: 'er',     // Eritrea
    COUNTRY_EE: 'ee',     // Estonia
    COUNTRY_SZ: 'sz',     // Eswatini
    COUNTRY_ET: 'et',     // Ethiopia
    COUNTRY_FJ: 'fj',     // Fiji
    COUNTRY_FI: 'fi',     // Finland
    COUNTRY_FR: 'fr',     // France
    COUNTRY_GA: 'ga',     // Gabon
    COUNTRY_GM: 'gm',     // Gambia
    COUNTRY_GE: 'ge',     // Georgia
    COUNTRY_DE: 'de',     // Germany
    COUNTRY_GH: 'gh',     // Ghana
    COUNTRY_GR: 'gr',     // Greece
    COUNTRY_GD: 'gd',     // Grenada
    COUNTRY_GT: 'gt',     // Guatemala
    COUNTRY_GN: 'gn',     // Guinea
    COUNTRY_GW: 'gw',     // Guinea-Bissau
    COUNTRY_GY: 'gy',     // Guyana
    COUNTRY_HT: 'ht',     // Haiti
    COUNTRY_HN: 'hn',     // Honduras
    COUNTRY_HU: 'hu',     // Hungary
    COUNTRY_IS: 'is',     // Iceland
    COUNTRY_IN: 'in',     // India
    COUNTRY_ID: 'id',     // Indonesia
    COUNTRY_IR: 'ir',     // Iran
    COUNTRY_IQ: 'iq',     // Iraq
    COUNTRY_IE: 'ie',     // Ireland
    COUNTRY_IL: 'il',     // Israel
    COUNTRY_IT: 'it',     // Italy
    COUNTRY_JM: 'jm',     // Jamaica
    COUNTRY_JP: 'jp',     // Japan
    COUNTRY_JO: 'jo',     // Jordan
    COUNTRY_KZ: 'kz',     // Kazakhstan
    COUNTRY_KE: 'ke',     // Kenya
    COUNTRY_KI: 'ki',     // Kiribati
    COUNTRY_KP: 'kp',     // North Korea
    COUNTRY_KR: 'kr',     // South Korea
    COUNTRY_KW: 'kw',     // Kuwait
    COUNTRY_KG: 'kg',     // Kyrgyzstan
    COUNTRY_LA: 'la',     // Laos
    COUNTRY_LV: 'lv',     // Latvia
    COUNTRY_LB: 'lb',     // Lebanon
    COUNTRY_LS: 'ls',     // Lesotho
    COUNTRY_LR: 'lr',     // Liberia
    COUNTRY_LY: 'ly',     // Libya
    COUNTRY_LI: 'li',     // Liechtenstein
    COUNTRY_LT: 'lt',     // Lithuania
    COUNTRY_LU: 'lu',     // Luxembourg
    COUNTRY_MG: 'mg',     // Madagascar
    COUNTRY_MW: 'mw',     // Malawi
    COUNTRY_MY: 'my',     // Malaysia
    COUNTRY_MV: 'mv',     // Maldives
    COUNTRY_ML: 'ml',     // Mali
    COUNTRY_MT: 'mt',     // Malta
    COUNTRY_MH: 'mh',     // Marshall Islands
    COUNTRY_MR: 'mr',     // Mauritania
    COUNTRY_MU: 'mu',     // Mauritius
    COUNTRY_MX: 'mx',     // Mexico
    COUNTRY_FM: 'fm',     // Micronesia
    COUNTRY_MD: 'md',     // Moldova
    COUNTRY_MC: 'mc',     // Monaco
    COUNTRY_MN: 'mn',     // Mongolia
    COUNTRY_ME: 'me',     // Montenegro
    COUNTRY_MA: 'ma',     // Morocco
    COUNTRY_MZ: 'mz',     // Mozambique
    COUNTRY_MM: 'mm',     // Myanmar
    COUNTRY_NA: 'na',     // Namibia
    COUNTRY_NR: 'nr',     // Nauru
    COUNTRY_NP: 'np',     // Nepal
    COUNTRY_NL: 'nl',     // Netherlands
    COUNTRY_NZ: 'nz',     // New Zealand
    COUNTRY_NI: 'ni',     // Nicaragua
    COUNTRY_NE: 'ne',     // Niger
    COUNTRY_NG: 'ng',     // Nigeria
    COUNTRY_NO: 'no',     // Norway
    COUNTRY_OM: 'om',     // Oman
    COUNTRY_PK: 'pk',     // Pakistan
    COUNTRY_PW: 'pw',     // Palau
    COUNTRY_PA: 'pa',     // Panama
    COUNTRY_PG: 'pg',     // Papua New Guinea
    COUNTRY_PY: 'py',     // Paraguay
    COUNTRY_PE: 'pe',     // Peru
    COUNTRY_PH: 'ph',     // Philippines
    COUNTRY_PL: 'pl',     // Poland
    COUNTRY_PT: 'pt',     // Portugal
    COUNTRY_QA: 'qa',     // Qatar
    COUNTRY_RO: 'ro',     // Romania
    COUNTRY_RU: 'ru',     // Russia
    COUNTRY_RW: 'rw',     // Rwanda
    COUNTRY_KN: 'kn',     // Saint Kitts and Nevis
    COUNTRY_LC: 'lc',     // Saint Lucia
    COUNTRY_VC: 'vc',     // Saint Vincent and the Grenadines
    COUNTRY_WS: 'ws',     // Samoa
    COUNTRY_SM: 'sm',     // San Marino
    COUNTRY_ST: 'st',     // Sao Tome and Principe
    COUNTRY_SA: 'sa',     // Saudi Arabia
    COUNTRY_SN: 'sn',     // Senegal
    COUNTRY_RS: 'rs',     // Serbia
    COUNTRY_SC: 'sc',     // Seychelles
    COUNTRY_SL: 'sl',     // Sierra Leone
    COUNTRY_SG: 'sg',     // Singapore
    COUNTRY_SK: 'sk',     // Slovakia
    COUNTRY_SI: 'si',     // Slovenia
    COUNTRY_SB: 'sb',     // Solomon Islands
    COUNTRY_SO: 'so',     // Somalia
    COUNTRY_ZA: 'za',     // South Africa
    COUNTRY_SS: 'ss',     // South Sudan
    COUNTRY_ES: 'es',     // Spain
    COUNTRY_LK: 'lk',     // Sri Lanka
    COUNTRY_SD: 'sd',     // Sudan
    COUNTRY_SR: 'sr',     // Suriname
    COUNTRY_SE: 'se',     // Sweden
    COUNTRY_CH: 'ch',     // Switzerland
    COUNTRY_SY: 'sy',     // Syria
    COUNTRY_TW: 'tw',     // Taiwan
    COUNTRY_TJ: 'tj',     // Tajikistan
    COUNTRY_TZ: 'tz',     // Tanzania
    COUNTRY_TH: 'th',     // Thailand
    COUNTRY_TL: 'tl',     // Timor-Leste
    COUNTRY_TG: 'tg',     // Togo
    COUNTRY_TO: 'to',     // Tonga
    COUNTRY_TT: 'tt',     // Trinidad and Tobago
    COUNTRY_TN: 'tn',     // Tunisia
    COUNTRY_TR: 'tr',     // Turkey
    COUNTRY_TM: 'tm',     // Turkmenistan
    COUNTRY_TV: 'tv',     // Tuvalu
    COUNTRY_UG: 'ug',     // Uganda
    COUNTRY_UA: 'ua',     // Ukraine
    COUNTRY_AE: 'ae',     // United Arab Emirates
    COUNTRY_GB: 'gb',     // United Kingdom
    COUNTRY_US: 'us',     // United States
    COUNTRY_UY: 'uy',     // Uruguay
    COUNTRY_UZ: 'uz',     // Uzbekistan
    COUNTRY_VU: 'vu',     // Vanuatu
    COUNTRY_VE: 've',     // Venezuela
    COUNTRY_VN: 'vn',     // Vietnam
    COUNTRY_YE: 'ye',     // Yemen
    COUNTRY_ZM: 'zm',     // Zambia
    COUNTRY_ZW: 'zw'      // Zimbabwe
};

export const languages = {
    LANGUAGE_AF: 'af',       // Afrikaans
    LANGUAGE_AM: 'am',       // Amharic
    LANGUAGE_AR: 'ar',       // Arabic
    LANGUAGE_AZ: 'az',       // Azerbaijani
    LANGUAGE_BE: 'be',       // Belarusian
    LANGUAGE_BG: 'bg',       // Bulgarian
    LANGUAGE_BN: 'bn',       // Bengali
    LANGUAGE_CA: 'ca',       // Catalan
    LANGUAGE_CS: 'cs',       // Czech
    LANGUAGE_DA: 'da',       // Danish
    LANGUAGE_DE: 'de',       // German
    LANGUAGE_DZ: 'dz',       // Dzongkha
    LANGUAGE_EL: 'el',       // Greek
    LANGUAGE_EN: 'en',       // English (US)
    LANGUAGE_EN_GB: 'en-GB', // English (UK)
    LANGUAGE_ES: 'es',       // Spanish
    LANGUAGE_ET: 'et',       // Estonian
    LANGUAGE_FA: 'fa',       // Persian
    LANGUAGE_FA_IR: 'fa-IR', // Persian (Iran)
    LANGUAGE_FI: 'fi',       // Finnish
    LANGUAGE_FR: 'fr',       // French
    LANGUAGE_FR_CA: 'fr-CA', // French (Canada)
    LANGUAGE_FR_CH: 'fr-CH', // French (Switzerland)
    LANGUAGE_HE: 'he',       // Hebrew
    LANGUAGE_HI: 'hi',       // Hindi
    LANGUAGE_HR: 'hr',       // Croatian
    LANGUAGE_HU: 'hu',       // Hungarian
    LANGUAGE_HY: 'hy',       // Armenian
    LANGUAGE_ID: 'id',       // Indonesian
    LANGUAGE_IS: 'is',       // Icelandic
    LANGUAGE_IT: 'it',       // Italian
    LANGUAGE_JA: 'ja',       // Japanese
    LANGUAGE_KA: 'ka',       // Georgian
    LANGUAGE_KG: 'kg',       // Kongo
    LANGUAGE_KK: 'kk',       // Kazakh
    LANGUAGE_KM: 'km',       // Khmer
    LANGUAGE_KO: 'ko',       // Korean
    LANGUAGE_KY: 'ky',       // Kyrgyz
    LANGUAGE_LA: 'la',       // Latin
    LANGUAGE_LT: 'lt',       // Lithuanian
    LANGUAGE_LV: 'lv',       // Latvian
    LANGUAGE_MK: 'mk',       // Macedonian
    LANGUAGE_ML: 'ml',       // Malayalam
    LANGUAGE_MN: 'mn',       // Mongolian
    LANGUAGE_MS: 'ms',       // Malay
    LANGUAGE_MT: 'mt',       // Maltese
    LANGUAGE_NE: 'ne',       // Nepali
    LANGUAGE_NL: 'nl',       // Dutch
    LANGUAGE_NO: 'no',       // Norwegian
    LANGUAGE_PL: 'pl',       // Polish
    LANGUAGE_PT: 'pt',       // Portuguese
    LANGUAGE_PT_BR: 'pt-BR', // Portuguese (Brazil)
    LANGUAGE_RO: 'ro',       // Romanian
    LANGUAGE_RU: 'ru',       // Russian
    LANGUAGE_RW: 'rw',       // Kinyarwanda
    LANGUAGE_SD: 'sd',       // Sindhi
    LANGUAGE_SI: 'si',       // Sinhala
    LANGUAGE_SK: 'sk',       // Slovak
    LANGUAGE_SL: 'sl',       // Slovenian
    LANGUAGE_SO: 'so',       // Somali
    LANGUAGE_SQ: 'sq',       // Albanian
    LANGUAGE_SR: 'sr',       // Serbian
    LANGUAGE_SV: 'sv',       // Swedish
    LANGUAGE_SW: 'sw',       // Swahili
    LANGUAGE_TA: 'ta',       // Tamil
    LANGUAGE_TE: 'te',       // Telugu
    LANGUAGE_TH: 'th',       // Thai
    LANGUAGE_TL: 'tl',       // Tagalog
    LANGUAGE_TR: 'tr',       // Turkish
    LANGUAGE_UK: 'uk',       // Ukrainian
    LANGUAGE_UR: 'ur',       // Urdu
    LANGUAGE_VI: 'vi',       // Vietnamese
    LANGUAGE_ZH: 'zh',       // Chinese
    LANGUAGE_ZH_CN: 'zh-CN', // Chinese (Simplified)
    LANGUAGE_ZH_TW: 'zh-TW', // Chinese (Traditional)
    LANGUAGE_ZU: 'zu'        // Zulu
};

export const AppActions = {
    ACTION_LOGIN: 'login',
    ACTION_SIGNUP: 'signup',
    ACTION_LOGOUT: 'logout',
    ACTION_NONE: 'none',
};

export const AppService = {
    SERVICE_NONE: { KEY: 'none', TITLE: 'None' },
    SERVICE_DOCTOR: { KEY: 'doctor', TITLE: 'Health Advisor' },
    SERVICE_HEALTH: { KEY: 'health', TITLE: 'Health Advisor' },
    SERVICE_BLOG: { KEY: 'blog', TITLE: 'Blog Post Writer' },
    SERVICE_WRITER: { KEY: 'writer', TITLE: 'AI Universal Writer' },
    SERVICE_ARTICLE: { KEY: 'article', TITLE: 'Article Writer' },
    SERVICE_ANY: { KEY: 'any', TITLE: 'Wixee' }
}

export const headerTypes = {
    STANDARD: 'standard',
    OFFICE: 'office',
    INITIAL: 'initial',
    ONETITLE: 'onetitle',
};

export const footerTypes = {
    STANDARD: 'standard',
    CONSULTATION: 'consultation',
};


export const AppFooterElements = {
    FOOTER_ELEMENT_HOME: 'home',
    FOOTER_ELEMENT_CONSULTATIONS: 'consultation',
    FOOTER_ELEMENT_DOCUMENTS: 'documents',
    FOOTER_ELEMENT_NOTIFICATIONS: 'notifications',
    FOOTER_ELEMENT_PROFILE: 'profile',
}

// Screen configurations based on the location path
export const AppRoutes = {

    ROUTE_WELCOME: { path: '/', header: false, footer: false },
    ROUTE_SPLASH1: { path: '/splash1', header: false, footer: false },
    ROUTE_SPLASH2: { path: '/splash2', header: false, footer: false },
    ROUTE_SPLASH3: { path: '/splash3', header: false, footer: false },
    ROUTE_SPLASH4: { path: '/splash4', header: false, footer: false },

    ROUTE_LOGIN: { path: '/login', header: false, footer: false },
    ROUTE_LOGIN_BY_EMAIL: { path: '/loginbyemail', header: false, footer: false },
    ROUTE_GOOGLELOGIN: { path: '/googlelogin', header: false, footer: false },

    ROUTE_HOME: { path: '/home', header: false, footer: true, footerItem: AppFooterElements.FOOTER_ELEMENT_HOME, footerType: footerTypes.STANDARD },
    ROUTE_SELECT_OFFICE: { path: '/select/office', header: false, footerItem: AppFooterElements.FOOTER_ELEMENT_HOME, footer: true, footerType: footerTypes.STANDARD },

    ROUTE_PROFILE: { path: '/profile', header: false, footer: true, footerItem: AppFooterElements.FOOTER_ELEMENT_PROFILE, footerType: footerTypes.STANDARD },
    ROUTE_SELECT_PROFILE: { path: '/select/profile', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_PROFILE, headerTitle: 'profile.title', headerType: headerTypes.ONETITLE, footerType: footerTypes.STANDARD },
    ROUTE_SET_PROFILE_CONTEXT: { path: '/set/profile', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_PROFILE, headerTitle: 'profile.title', headerType: headerTypes.ONETITLE, footerType: footerTypes.STANDARD },
    ROUTE_PROFILE_EDIT: { path: '/profile/edit', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_PROFILE, headerTitle: 'profile.title', headerType: headerTypes.ONETITLE, footerType: footerTypes.STANDARD },

    ROUTE_OFFICES: { path: '/offices', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_PROFILE, headerTitle: 'offices.title', headerType: headerTypes.ONETITLE, footerType: footerTypes.STANDARD },
    ROUTE_SETTINGS: { path: '/settings', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_PROFILE, headerTitle: 'settings.title', headerType: headerTypes.ONETITLE, footerType: footerTypes.STANDARD },
    ROUTE_BILLING: { path: '/billing', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_PROFILE, headerTitle: 'billing.title', headerType: headerTypes.ONETITLE, footerType: footerTypes.STANDARD },

    ROUTE_DOCUMENTS: { path: '/documents', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_DOCUMENTS, headerTitle: 'documents.title', headerType: headerTypes.STANDARD, footerType: footerTypes.STANDARD },
    ROUTE_CALENDAR: { path: '/calendar', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_NOTIFICATIONS, headerTitle: 'calendar.title', headerType: headerTypes.STANDARD, footerType: footerTypes.STANDARD },
    ROUTE_NOTIFICATIONS: { path: '/notifications', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_NOTIFICATIONS, headerTitle: 'notifications.title', headerType: headerTypes.STANDARD, footerType: footerTypes.STANDARD },
    ROUTE_CONSULTATIONS: { path: '/consultations', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_CONSULTATIONS, headerTitle: 'consultations.title', headerType: headerTypes.STANDARD, footerType: footerTypes.STANDARD },
    ROUTE_SELECT_CONSULTANT: { path: '/consultant', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_CONSULTATIONS, headerTitle: 'consultations.title', headerType: headerTypes.STANDARD, footerType: footerTypes.STANDARD },
    ROUTE_CONSULTATION_ID: { path: '/consultation', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_CONSULTATIONS, headerTitle: 'consultation.title', headerType: headerTypes.STANDARD, footerType: footerTypes.CONSULTATION },
    ROUTE_CONSULTATION_DETAILS: { path: '/consultation/details', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_CONSULTATIONS, headerTitle: 'consultationDetails.title', headerType: headerTypes.STANDARD, footerType: footerTypes.STANDARD },
    ROUTE_DOCUMENT_DETAILS: { path: '/document/details', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_DOCUMENTS, headerTitle: 'documentDetails.title', headerType: headerTypes.STANDARD, footerType: footerTypes.STANDARD },
    ROUTE_DOCUMENT_MANAGE: { path: '/document/manage', header: true, footer: true, backButton: true, footerItem: AppFooterElements.FOOTER_ELEMENT_DOCUMENTS, headerTitle: 'documentManage.title', headerType: headerTypes.STANDARD, footerType: footerTypes.STANDARD },
}