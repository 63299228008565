// src/classes/ConsultationsManager.js
import api from '../classes/API.js';

export default class ConsultationsManager {
    constructor(officeManager, consultantManager) {
        this.officeManager = officeManager;
        this.consultantManager = consultantManager;
        if (!this.officeManager) {
            console.error('Office Manager is not set!');
        }
    }

    async fetchConsultations(userId, currentCountry, profileId = 0) {
        // filter by office
        const office = (this.officeManager)
            ? this.officeManager.getCurrentOffice(currentCountry)
            : null;
        //console.log('office', office.id, office.type);
        const response = await api.getConsultationsByUser(userId, office.id, profileId);
        const data = await response.json();
        return data;
    }

    async getSplittedConsultations(userId, currentCountry, profileId = 0) {
        const data = await this.fetchConsultations(userId, currentCountry, profileId);
        const formattedConsultations = this.formatConsultations(data);
        const sortedAndFilteredConsultations = this.sortAndFilterConsultations(formattedConsultations);
        return sortedAndFilteredConsultations;
    }


    async fetchConsultation(id) {
        const response = await api.getConsultationById(id);
        const data = await response.json();
        return data;
    }

    async getConsultation(id) {
        const data = await this.fetchConsultation(id);
        const formattedConsultations = this.formatConsultations([data]);
        if (Array.isArray(formattedConsultations)) return formattedConsultations[0];
        return null;
    }

    formatConsultations(data) {
        try {
            return data.map(item => ({
                consultant: this.consultantManager.getConsultantByKey(item.consultant[0]),
                description: item.topic, // Adjust based on actual structure
                completion: item.completion,
                id: item.id,
                profileId: item.profile_id,
                total_cost: parseFloat(item.total_cost).toFixed(3),
                date: new Date(item.update_date_time).toISOString(), // Adjust date formatting if necessary
                deleted_date_time: item.deleted_date_time
            }));
        }
        catch (error) { }
        return null;
    }

    sortAndFilterConsultations(consultations) {
        //console.log(consultations);
        const today = new Date();
        const thirtyDaysAgo = new Date(today);
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

        const sortedConsultations = consultations.sort((a, b) => new Date(b.date) - new Date(a.date));
        const deletedConsultations = sortedConsultations.filter(c => c.deleted_date_time);
        const activeConsultations = sortedConsultations.filter(c => !c.deleted_date_time);

        const latestConsultations = activeConsultations.filter(c => new Date(c.date) >= thirtyDaysAgo);
        const lastMonthConsultations = activeConsultations.filter(c => new Date(c.date) < thirtyDaysAgo && new Date(c.date) >= new Date(thirtyDaysAgo.getFullYear(), thirtyDaysAgo.getMonth() - 1, 1));
        const olderConsultations = activeConsultations.filter(c => new Date(c.date) < new Date(thirtyDaysAgo.getFullYear(), thirtyDaysAgo.getMonth() - 1, 1));

        return {
            latestConsultations,
            lastMonthConsultations,
            olderConsultations,
            deletedConsultations
        };
    }

    async deleteConsultation(id) {
        const response = await api.deleteConsultation(id);
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            const error = await response.json();
            throw new Error(error.message);
        }
    }

    async restoreConsultation(id) {
        const response = await api.restoreConsultation(id);
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            const error = await response.json();
            throw new Error(error.message);
        }
    }

    async loadChatMessagesByConsultationId(consId) {
        const response = await api.getConsultationById(consId);
        const data = await response.json();
        return {
            profileId: data.profile_id,
            consultant: this.consultantManager.getConsultantByKey(data.consultant[0]),
            messages: data.consultation,
            topic: data.topic,
            completion: data.completion,
            deleted_date: data.deleted_date_time,
        };
    }

}