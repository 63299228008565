// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { countries, languages } from '../config/AppModes.ts';

// Language resource files
import { ar } from './locales/ar';
import { de } from './locales/de';
import { en } from './locales/en';
import { es } from './locales/es';
import { fr } from './locales/fr';
import { hi } from './locales/hi';
import { nl } from './locales/nl';
import { pt_BR } from './locales/pt-BR';
import { pt } from './locales/pt';
import { ru } from './locales/ru';
import { zh_CN } from './locales/zh-CN';

const getBrowserCountry = () => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    const parts = browserLanguage.split('-');
    const country = parts[parts.length - 1];
    if (country && Object.values(countries).includes(country.toLowerCase())) {
        return country.toLowerCase();
    }
    return countries.COUNTRY_US; // default 'us'
};

export const country = getBrowserCountry();

i18n
    .use(LanguageDetector) // Use the language detector plugin
    .use(initReactI18next) // Pass the i18n instance to react-i18next
    .init({
        resources: {
            [languages.LANGUAGE_AR]:    { translation: ar },
            [languages.LANGUAGE_DE]:    { translation: de },
            [languages.LANGUAGE_EN]:    { translation: en },
            [languages.LANGUAGE_EN_GB]: { translation: en },
            [languages.LANGUAGE_ES]:    { translation: es },
            [languages.LANGUAGE_FR]:    { translation: fr },
            [languages.LANGUAGE_FR_CA]: { translation: fr },
            [languages.LANGUAGE_FR_CH]: { translation: fr },
            [languages.LANGUAGE_HI]:    { translation: hi },
            [languages.LANGUAGE_NL]:    { translation: nl },
            [languages.LANGUAGE_PT]:    { translation: pt },
            [languages.LANGUAGE_PT_BR]: { translation: pt_BR },
            [languages.LANGUAGE_RU]:    { translation: ru },
            [languages.LANGUAGE_ZH]:    { translation: zh_CN },
            [languages.LANGUAGE_ZH_CN]: { translation: zh_CN },
            [languages.LANGUAGE_ZH_TW]: { translation: zh_CN },
        },
        fallbackLng: 'en', // Fallback language
        interpolation: {
            escapeValue: false // React already escapes by default
        },
        detection: {
            // Order and from where user language should be detected
            order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

            // Keys or params to lookup language from
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,

            // Cache user language on
            caches: ['localStorage', 'cookie'],
            excludeCacheFor: ['cimode'], // Languages to not persist (cookie, localStorage)

            // Optional expire and domain for set cookie
            cookieMinutes: 10,
            cookieDomain: 'myDomain',

            // Optional htmlTag with lang attribute, the default is:
            htmlTag: document.documentElement,

            // Only detect languages that are in the supportedLngs
            checkWhitelist: true
        },
        supportedLngs: Object.values(languages),
        debug: true // Enable debug mode to see detection logs in the console
    })
    .then(() => {
        // After initialization, add country information manually
        i18n.country = country;
    });

export default i18n;
