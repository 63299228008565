// src/components/ConsultantManager.js
import { officeTypes, chatModes, genderTypes, countries } from '../config/AppModes.ts';

import allergist_female from '../img/ha-avatar/allergist-female.webp';
// import allergist_male from '../img/ha-avatar/allergist-male.webp';
import anesthesiologist_female from '../img/ha-avatar/anesthesiologist-female.webp';
// import anesthesiologist_male from '../img/ha-avatar/anesthesiologist-male.webp';
// import cardiologist_female from '../img/ha-avatar/cardiologist-female.webp';
import cardiologist_male from '../img/ha-avatar/cardiologist-male.webp';
import coloproctologist_female from '../img/ha-avatar/coloproctologist-female.webp';
// import coloproctologist_male from '../img/ha-avatar/coloproctologist-male.webp';
import cosmetologist_female from '../img/ha-avatar/cosmetologist-female.webp';
// import cosmetologist_male from '../img/ha-avatar/cosmetologist-male.webp';
// import dentist_female from '../img/ha-avatar/dentist-female.webp';
import dentist_male from '../img/ha-avatar/dentist-male.webp';
import dermatologist_female from '../img/ha-avatar/dermatologist-female.webp';
// import dermatologist_male from '../img/ha-avatar/dermatologist-male.webp';
import dietitian_female from '../img/ha-avatar/dietitian-female.webp';
// import dietitian_male from '../img/ha-avatar/dietitian-male.webp';
import endocrinologist_female from '../img/ha-avatar/endocrinologist-female.webp';
// import endocrinologist_male from '../img/ha-avatar/endocrinologist-male.webp';
// import endoscopist_female from '../img/ha-avatar/endoscopist-female.webp';
import endoscopist_male from '../img/ha-avatar/endoscopist-male.webp';
import gastroenterologist_female from '../img/ha-avatar/gastroenterologist-female.webp';
// import gastroenterologist_male from '../img/ha-avatar/gastroenterologist-male.webp';
// import gynecologist_female from '../img/ha-avatar/gynecologist-female.webp';
import gynecologist_male from '../img/ha-avatar/gynecologist-male.webp';
import immunologist_female from '../img/ha-avatar/immunologist-female.webp';
// import immunologist_male from '../img/ha-avatar/immunologist-male.webp';
import infectologist_female from '../img/ha-avatar/infectologist-female.webp';
// import infectologist_male from '../img/ha-avatar/infectologist-male.webp';
// import nephrologist_female from '../img/ha-avatar/nephrologist-female.webp';
import nephrologist_male from '../img/ha-avatar/nephrologist-male.webp';
import neurologist_female from '../img/ha-avatar/neurologist-female.webp';
// import neurologist_male from '../img/ha-avatar/neurologist-male.webp';
import oncologist_female from '../img/ha-avatar/oncologist-female.webp';
// import oncologist_male from '../img/ha-avatar/oncologist-male.webp';
// import ophthalmologist_female from '../img/ha-avatar/ophthalmologist-female.webp';
import ophthalmologist_male from '../img/ha-avatar/ophthalmologist-male.webp';
// import orthopedist_female from '../img/ha-avatar/orthopedist-female.webp';
import orthopedist_male from '../img/ha-avatar/orthopedist-male.webp';
import otolaryngologist_female from '../img/ha-avatar/otolaryngologist-female.webp';
// import otolaryngologist_male from '../img/ha-avatar/otolaryngologist-male.webp';
// import pediatrician_female from '../img/ha-avatar/pediatrician-female.webp';
import pediatrician_male from '../img/ha-avatar/pediatrician-male.webp';
import phlebologist_female from '../img/ha-avatar/phlebologist-female.webp';
// import phlebologist_male from '../img/ha-avatar/phlebologist-male.webp';
// import physiotherapist_female from '../img/ha-avatar/physiotherapist-female.webp';
import physiotherapist_male from '../img/ha-avatar/physiotherapist-male.webp';
// import plastic_surgeon_female from '../img/ha-avatar/plastic-surgeon-female.webp';
import plastic_surgeon_male from '../img/ha-avatar/plastic-surgeon-male.webp';
import psychiatrist_female from '../img/ha-avatar/psychiatrist-female.webp';
// import psychiatrist_male from '../img/ha-avatar/psychiatrist-male.webp';
import psychotherapist_female from '../img/ha-avatar/psychotherapist-female.webp';
// import psychotherapist_male from '../img/ha-avatar/psychotherapist-male.webp';
// import pulmonologist_female from '../img/ha-avatar/pulmonologist-female.webp';
import pulmonologist_male from '../img/ha-avatar/pulmonologist-male.webp';
import receptionist_female_1 from '../img/ha-avatar/receptionist-female-1.webp';
import rheumatologist_female from '../img/ha-avatar/rheumatologist-female.webp';
// import rheumatologist_male from '../img/ha-avatar/rheumatologist-male.webp';
import sexologist_female from '../img/ha-avatar/sexologist-female.webp';
// import sexologist_male from '../img/ha-avatar/sexologist-male.webp';
// import somnologist_female from '../img/ha-avatar/somnologist-female.webp';
import somnologist_male from '../img/ha-avatar/somnologist-male.webp';
// import surgeon_female from '../img/ha-avatar/surgeon-female.webp';
import surgeon_male from '../img/ha-avatar/surgeon-male.webp';
import therapist_female from '../img/ha-avatar/therapist-female.webp';
// import therapist_male from '../img/ha-avatar/therapist-male.webp';
import traumatologist_female from '../img/ha-avatar/traumatologist-female.webp';
// import traumatologist_male from '../img/ha-avatar/traumatologist-male.webp';
// import unknown from '../img/ha-avatar/surgeon-male.webp';
// import urologist_female from '../img/ha-avatar/urologist-female.webp';
import urologist_male from '../img/ha-avatar/urologist-male.webp';
// import vascular_surgeon_female from '../img/ha-avatar/vascular-surgeon-female.webp';
import vascular_surgeon_male from '../img/ha-avatar/vascular-surgeon-male.webp';

import psychologist_male from '../img/psycho-avatar/regular-psychologist.webp';
import family_psychologist_male from '../img/psycho-avatar/family-psychologist.webp';

import tarot_intuitive from '../img/tarot-avatar/intuitive.webp';
import tarot_astrological from '../img/tarot-avatar/astrological.webp';
import tarot_spiritual from '../img/tarot-avatar/spiritual.webp';
import tarot_therapeutic from '../img/tarot-avatar/therapeutic.webp';
import tarot_numerological from '../img/tarot-avatar/numerological.webp';
import tarot_celtic from '../img/tarot-avatar/celtic.webp';
import tarot_oracle from '../img/tarot-avatar/oracle.webp';
import tarot_psychic from '../img/tarot-avatar/psychic.webp';
import tarot_classic from '../img/tarot-avatar/classic.webp';
import tarot_lenormand from '../img/tarot-avatar/lenormand.webp';

import horoscope_chinese from '../img/horoscope-avatar/horoscope-chinese.webp';
import horoscope_compatibility from '../img/horoscope-avatar/horoscope-compatibility.webp';
import horoscope_business from '../img/horoscope-avatar/horoscope-business.webp';
import horoscope_lunar from '../img/horoscope-avatar/horoscope-lunar.webp';
import horoscope_zodiac from '../img/horoscope-avatar/horoscope-zodiac.webp';

import ASTROLOGY_natal_chart from '../img/astrology-avatar/natal-chart.webp';
import ASTROLOGY_horary_chart from '../img/astrology-avatar/horary-chart.webp';

import talisman_baca from '../img/talisman-avatar/talisman-baca.webp';
import talisman_calpaca from '../img/talisman-avatar/talisman-calpaca.webp';
import talisman_mandala from '../img/talisman-avatar/talisman-mandala_dos_orixas.webp';
import talisman_rosa from '../img/talisman-avatar/talisman-rosa_de_los_vientos.webp';
import talisman_trinkets from '../img/talisman-avatar/talisman-trinkets.webp';
import talisman_munekas from '../img/talisman-avatar/talisman-munekas_kuita_penas.webp';

import defaultImage from '../img/default-avatar.webp';
import { tr } from 'date-fns/locale';
import { t } from 'i18next';

const consultants = [
    {
        office: officeTypes.HEALTH,
        consultants: [
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: receptionist_female_1, key: chatModes.CHAT_HEALTH_RECEPTIONIST},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: allergist_female, key: chatModes.CHAT_HEALTH_ALLERGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: anesthesiologist_female, key: chatModes.CHAT_HEALTH_ANESTHESIOLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.MALE, photo: cardiologist_male, key: chatModes.CHAT_HEALTH_CARDIOLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: coloproctologist_female, key: chatModes.CHAT_HEALTH_COLOPROCTOLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: cosmetologist_female, key: chatModes.CHAT_HEALTH_COSMETOLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.MALE, photo: dentist_male, key: chatModes.CHAT_HEALTH_DENTIST},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: dermatologist_female, key: chatModes.CHAT_HEALTH_DERMATOLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: dietitian_female, key: chatModes.CHAT_HEALTH_DIETITIAN},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: endocrinologist_female, key: chatModes.CHAT_HEALTH_ENDOCRINOLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.MALE, photo: endoscopist_male, key: chatModes.CHAT_HEALTH_ENDOSCOPIST},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: gastroenterologist_female, key: chatModes.CHAT_HEALTH_GASTROENTEROLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.MALE, photo: gynecologist_male, key: chatModes.CHAT_HEALTH_GYNECOLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_HEALTH_MAMMOLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: immunologist_female, key: chatModes.CHAT_HEALTH_IMMUNOLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: infectologist_female, key: chatModes.CHAT_HEALTH_INFECTIOLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.MALE, photo: nephrologist_male, key: chatModes.CHAT_HEALTH_NEPHROLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: neurologist_female, key: chatModes.CHAT_HEALTH_NEUROLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: oncologist_female, key: chatModes.CHAT_HEALTH_ONCOLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.MALE, photo: ophthalmologist_male, key: chatModes.CHAT_HEALTH_OPHTHALMOLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.MALE, photo: orthopedist_male, key: chatModes.CHAT_HEALTH_ORTHOPEDIST},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: otolaryngologist_female, key: chatModes.CHAT_HEALTH_OTOLARYNGOLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.MALE, photo: pediatrician_male, key: chatModes.CHAT_HEALTH_PEDIATRICIAN},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: phlebologist_female, key: chatModes.CHAT_HEALTH_PHLEBOLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.MALE, photo: physiotherapist_male, key: chatModes.CHAT_HEALTH_PHYSIOTHERAPIST},
            { office: officeTypes.HEALTH, gender: genderTypes.MALE, photo: plastic_surgeon_male, key: chatModes.CHAT_HEALTH_PLASTIC_SURGEON},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: psychiatrist_female, key: chatModes.CHAT_HEALTH_PSYCHIATRIST},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: psychotherapist_female, key: chatModes.CHAT_HEALTH_PSYCHOTHERAPIST},
            { office: officeTypes.HEALTH, gender: genderTypes.MALE, photo: pulmonologist_male, key: chatModes.CHAT_HEALTH_PULMONOLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_HEALTH_REHABILITOLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: rheumatologist_female, key: chatModes.CHAT_HEALTH_RHEUMATOLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: sexologist_female, key: chatModes.CHAT_HEALTH_SEXOLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.MALE, photo: somnologist_male, key: chatModes.CHAT_HEALTH_SOMNOLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.MALE, photo: surgeon_male, key: chatModes.CHAT_HEALTH_SURGEON},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: therapist_female, key: chatModes.CHAT_HEALTH_THERAPIST},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: traumatologist_female, key: chatModes.CHAT_HEALTH_TRAUMATOLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.MALE, photo: urologist_male, key: chatModes.CHAT_HEALTH_UROLOGIST},
            { office: officeTypes.HEALTH, gender: genderTypes.MALE, photo: vascular_surgeon_male, key: chatModes.CHAT_HEALTH_VASCULAR_SURGEON},
            { office: officeTypes.HEALTH, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_HEALTH_PHARMACIST},
        ]
    },
    {
        office: officeTypes.ALTMED,
        consultants: [
            { office: officeTypes.ALTMED, gender: genderTypes.FEMALE, photo: receptionist_female_1, key: chatModes.CHAT_ALTMED_ACUPUNCTURE},
            { office: officeTypes.ALTMED, gender: genderTypes.FEMALE, photo: allergist_female, key: chatModes.CHAT_ALTMED_HERBAL_MEDICINE},
            { office: officeTypes.ALTMED, gender: genderTypes.FEMALE, photo: anesthesiologist_female, key: chatModes.CHAT_ALTMED_HOMEOPATHY},
            { office: officeTypes.ALTMED, gender: genderTypes.MALE, photo: cardiologist_male, key: chatModes.CHAT_ALTMED_REFLEXOLOGY},
            { office: officeTypes.ALTMED, gender: genderTypes.FEMALE, photo: coloproctologist_female, key: chatModes.CHAT_ALTMED_REIKI},
            { office: officeTypes.ALTMED, gender: genderTypes.FEMALE, photo: cosmetologist_female, key: chatModes.CHAT_ALTMED_CHIROPRACTOR},
            { office: officeTypes.ALTMED, gender: genderTypes.MALE, photo: dentist_male, key: chatModes.CHAT_ALTMED_NATUROPATHY},
            { office: officeTypes.ALTMED, gender: genderTypes.FEMALE, photo: dermatologist_female, key: chatModes.CHAT_ALTMED_AROMATHERAPY},
            { office: officeTypes.ALTMED, gender: genderTypes.FEMALE, photo: dietitian_female, key: chatModes.CHAT_ALTMED_AYURVEDA},
            { office: officeTypes.ALTMED, gender: genderTypes.FEMALE, photo: endocrinologist_female, key: chatModes.CHAT_ALTMED_TCM},
            { office: officeTypes.ALTMED, gender: genderTypes.MALE, photo: endoscopist_male, key: chatModes.CHAT_ALTMED_HYDROTHERAPY},
            { office: officeTypes.ALTMED, gender: genderTypes.FEMALE, photo: gastroenterologist_female, key: chatModes.CHAT_ALTMED_BIOENERGETICS},
            { office: officeTypes.ALTMED, gender: genderTypes.MALE, photo: gynecologist_male, key: chatModes.CHAT_ALTMED_HYPNOTHERAPY},
            { office: officeTypes.ALTMED, gender: genderTypes.FEMALE, photo: immunologist_female, key: chatModes.CHAT_ALTMED_CRANIOSACRAL},
            { office: officeTypes.ALTMED, gender: genderTypes.FEMALE, photo: infectologist_female, key: chatModes.CHAT_ALTMED_SOUND_THERAPY},
            { office: officeTypes.ALTMED, gender: genderTypes.MALE, photo: nephrologist_male, key: chatModes.CHAT_ALTMED_THERMOTHERAPY},
            { office: officeTypes.ALTMED, gender: genderTypes.FEMALE, photo: neurologist_female, key: chatModes.CHAT_ALTMED_FITOTHERAPY},
        ]
    },
    {
        office: officeTypes.PSYCHO,
        consultants: [
            { office: officeTypes.PSYCHO, gender: genderTypes.FEMALE, photo: receptionist_female_1, key: chatModes.CHAT_PSYCHO_REGULAR},
            { office: officeTypes.PSYCHO, gender: genderTypes.FEMALE, photo: allergist_female, key: chatModes.CHAT_PSYCHO_FAMILY},
            { office: officeTypes.PSYCHO, gender: genderTypes.FEMALE, photo: anesthesiologist_female, key: chatModes.CHAT_PSYCHO_COUPLES},
            { office: officeTypes.PSYCHO, gender: genderTypes.MALE, photo: cardiologist_male, key: chatModes.CHAT_PSYCHO_CHILD},
            { office: officeTypes.PSYCHO, gender: genderTypes.FEMALE, photo: coloproctologist_female, key: chatModes.CHAT_PSYCHO_SCHOOL},
            { office: officeTypes.PSYCHO, gender: genderTypes.FEMALE, photo: cosmetologist_female, key: chatModes.CHAT_PSYCHO_COGNITIVE_BEHAVIORAL},
            { office: officeTypes.PSYCHO, gender: genderTypes.MALE, photo: dentist_male, key: chatModes.CHAT_PSYCHO_GESTALT},
            { office: officeTypes.PSYCHO, gender: genderTypes.FEMALE, photo: dermatologist_female, key: chatModes.CHAT_PSYCHO_PSYCHODYNAMIC},
            { office: officeTypes.PSYCHO, gender: genderTypes.FEMALE, photo: dietitian_female, key: chatModes.CHAT_PSYCHO_CLINICAL},
            { office: officeTypes.PSYCHO, gender: genderTypes.FEMALE, photo: endocrinologist_female, key: chatModes.CHAT_PSYCHO_SPORTS},
            { office: officeTypes.PSYCHO, gender: genderTypes.MALE, photo: endoscopist_male, key: chatModes.CHAT_PSYCHO_ORGANIZATIONAL},
            { office: officeTypes.PSYCHO, gender: genderTypes.FEMALE, photo: gastroenterologist_female, key: chatModes.CHAT_PSYCHO_TRAUMA},
            { office: officeTypes.PSYCHO, gender: genderTypes.MALE, photo: gynecologist_male, key: chatModes.CHAT_PSYCHO_NEUROPSYCHOLOGIST},
            { office: officeTypes.PSYCHO, gender: genderTypes.FEMALE, photo: immunologist_female, key: chatModes.CHAT_PSYCHO_EATING_DISORDER},
            { office: officeTypes.PSYCHO, gender: genderTypes.FEMALE, photo: infectologist_female, key: chatModes.CHAT_PSYCHO_NARCOLOGIST},
            { office: officeTypes.PSYCHO, gender: genderTypes.MALE, photo: nephrologist_male, key: chatModes.CHAT_PSYCHO_ADDICTION},
            { office: officeTypes.PSYCHO, gender: genderTypes.FEMALE, photo: neurologist_female, key: chatModes.CHAT_PSYCHO_GRIEF},
            { office: officeTypes.PSYCHO, gender: genderTypes.FEMALE, photo: oncologist_female, key: chatModes.CHAT_PSYCHO_WORKPLACE},
            { office: officeTypes.PSYCHO, gender: genderTypes.MALE, photo: ophthalmologist_male, key: chatModes.CHAT_PSYCHO_SEXUALITY},
            { office: officeTypes.PSYCHO, gender: genderTypes.MALE, photo: orthopedist_male, key: chatModes.CHAT_PSYCHO_LGBTQ},
        ]
    },
    {
        office: officeTypes.VET,
        consultants: [
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: receptionist_female_1, key: chatModes.CHAT_VET_RECEPTIONIST},
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: allergist_female, key: chatModes.CHAT_VET_ALLERGIST},
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: anesthesiologist_female, key: chatModes.CHAT_VET_ANESTHESIOLOGIST},
            { office: officeTypes.VET, gender: genderTypes.MALE, photo: cardiologist_male, key: chatModes.CHAT_VET_CARDIOLOGIST},
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: coloproctologist_female, key: chatModes.CHAT_VET_COLOPROCTOLOGIST},
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: cosmetologist_female, key: chatModes.CHAT_VET_COSMETOLOGIST},
            { office: officeTypes.VET, gender: genderTypes.MALE, photo: dentist_male, key: chatModes.CHAT_VET_DENTIST},
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: dermatologist_female, key: chatModes.CHAT_VET_DERMATOLOGIST},
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: dietitian_female, key: chatModes.CHAT_VET_DIETITIAN},
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: endocrinologist_female, key: chatModes.CHAT_VET_ENDOCRINOLOGIST},
            { office: officeTypes.VET, gender: genderTypes.MALE, photo: endoscopist_male, key: chatModes.CHAT_VET_ENDOSCOPIST},
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: gastroenterologist_female, key: chatModes.CHAT_VET_GASTROENTEROLOGIST},
            { office: officeTypes.VET, gender: genderTypes.MALE, photo: gynecologist_male, key: chatModes.CHAT_VET_GYNECOLOGIST},
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: immunologist_female, key: chatModes.CHAT_VET_IMMUNOLOGIST},
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: infectologist_female, key: chatModes.CHAT_VET_INFECTIOLOGIST},
            { office: officeTypes.VET, gender: genderTypes.MALE, photo: nephrologist_male, key: chatModes.CHAT_VET_NEPHROLOGIST},
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: neurologist_female, key: chatModes.CHAT_VET_NEUROLOGIST},
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: oncologist_female, key: chatModes.CHAT_VET_ONCOLOGIST},
            { office: officeTypes.VET, gender: genderTypes.MALE, photo: ophthalmologist_male, key: chatModes.CHAT_VET_OPHTHALMOLOGIST},
            { office: officeTypes.VET, gender: genderTypes.MALE, photo: orthopedist_male, key: chatModes.CHAT_VET_ORTHOPEDIST},
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: otolaryngologist_female, key: chatModes.CHAT_VET_OTOLARYNGOLOGIST},
            { office: officeTypes.VET, gender: genderTypes.MALE, photo: pediatrician_male, key: chatModes.CHAT_VET_PEDIATRICIAN},
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: phlebologist_female, key: chatModes.CHAT_VET_PHLEBOLOGIST},
            { office: officeTypes.VET, gender: genderTypes.MALE, photo: physiotherapist_male, key: chatModes.CHAT_VET_PHYSIOTHERAPIST},
            { office: officeTypes.VET, gender: genderTypes.MALE, photo: plastic_surgeon_male, key: chatModes.CHAT_VET_PLASTIC_SURGEON},
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: psychiatrist_female, key: chatModes.CHAT_VET_PSYCHIATRIST},
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: psychotherapist_female, key: chatModes.CHAT_VET_PSYCHOTHERAPIST},
            { office: officeTypes.VET, gender: genderTypes.MALE, photo: pulmonologist_male, key: chatModes.CHAT_VET_PULMONOLOGIST},
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: rheumatologist_female, key: chatModes.CHAT_VET_RHEUMATOLOGIST},
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: sexologist_female, key: chatModes.CHAT_VET_SEXOLOGIST},
            { office: officeTypes.VET, gender: genderTypes.MALE, photo: somnologist_male, key: chatModes.CHAT_VET_SOMNOLOGIST},
            { office: officeTypes.VET, gender: genderTypes.MALE, photo: surgeon_male, key: chatModes.CHAT_VET_SURGEON},
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: therapist_female, key: chatModes.CHAT_VET_THERAPIST},
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: traumatologist_female, key: chatModes.CHAT_VET_TRAUMATOLOGIST},
            { office: officeTypes.VET, gender: genderTypes.MALE, photo: urologist_male, key: chatModes.CHAT_VET_UROLOGIST},
            { office: officeTypes.VET, gender: genderTypes.FEMALE, photo: vascular_surgeon_male, key: chatModes.CHAT_VET_VASCULAR_SURGEON},
        ]
    },
    {
        office: officeTypes.TEACHER,
        consultants: [
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_LANGUAGE},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_LITERATURE},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_MATH},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_GEOMETRY},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_PHYSICS},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_ASTRONOMY},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_CHEMISTRY},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_ART},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_MUSIC},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_PHYSICAL_EDUCATION},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_TECHNOLOGY},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_COMPUTER_SCIENCE},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_DATA_SCIENCE},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_PROGRAMMING},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_GRAPHIC_DESIGN},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_LAW},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_CIVICS},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_HISTORY},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_GEOGRAPHY},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_BIOLOGY},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_ENVIRONMENTAL_SCIENCE},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_RELIGION},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_PHILOSOPHY},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_ECONOMICS},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_BUSINESS},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_MARKETING},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_SOCIOLOGY},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_PSYCHOLOGY},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_MEDICINE},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_DRAMA},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_ORATORY},
            { office: officeTypes.TEACHER, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_TEACHER_FILMMAKING},
        ]
    },
    {
        office: officeTypes.BUSINESS,
        consultants: [
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_STRATEGY},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_DEVELOPMENT},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_STARTUP},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_INVESTMENT},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_FINANCE},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_MARKETING},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_SALES},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_HR},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_OPERATIONS},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_TECHNOLOGY},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_IT},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_LEGAL},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_ACCOUNTING},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_TAX},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_TRAVEL},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_DOCUMENT_MANAGEMENT},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_SUPPLY_CHAIN},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_PROJECT_MANAGEMENT},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_CHANGE_MANAGEMENT},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_RISK_MANAGEMENT},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_SUSTAINABILITY},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_INNOVATION},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_EXPORT_IMPORT},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_FRANCHISING},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_MERGE_ACQUISITION},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_CUSTOMER_SERVICE},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_COMPLIANCE},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_VALUATION},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_COACH},
            { office: officeTypes.BUSINESS, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_BUSINESS_PSYCHOLOGIST},
        ]
    },
    {
        office: officeTypes.LEGAL,
        consultants: [
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_GENERAL},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_CONTRACTS},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_NOTARY},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_CORPORATE},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_TAX},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_LABOR},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_INTELLECTUAL_PROPERTY},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_LITIGATION},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_COURT},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_MERGES_ACQUISITIONS},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_ADMINISTRATIVE},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_ARBITRATION},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_CRIMINAL},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_FAMILY},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_REAL_ESTATE},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_INTERNATIONAL},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_IMMIGRATION},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_CONSUMER_PROTECTION},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_ENVIRONMENTAL},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_DATA_PRIVACY},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_HEALTHCARE},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_BANKRUPTCY},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_COMPLIANCE},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_SPORTS},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_ENTERTAINMENT},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_TECHNOLOGY},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_STARTUP},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_TRUSTS_ESTATES},
            { office: officeTypes.LEGAL, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_LEGAL_SECURITY},
        ]
    },
    {
        office: officeTypes.LIFE,
        consultants: [
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_JOB},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_CAREER_DEVELOPMENT},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_SELF_DEVELOPMENT},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_TRAVEL},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_RELOCATION},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_FINANCIAL},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_INSURANCE},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_RETIREMENT},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_HEALTHY_LIFESTYLE},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_NUTRITION},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_FITNESS},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_YOGA},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_MEMORY_IQ},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_MENTAL_EQ},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_REAL_ESTATE},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_HOME_RENOVATION},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_EDUCATION},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_STUDY_ABROAD},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_MARRIAGE},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_PARENTING},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_SMALL_BUSINESS},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_PERSONAL_SECURITY},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_HOBBIES},
            { office: officeTypes.LIFE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_LIFE_PET_CARE},
        ]
    },
    {
        office: officeTypes.HOUSE,
        consultants: [
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_ELECTRICIAN},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_PLUMBER},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_CLEANER},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_GARDENER},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_SECURITY},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_APPLIANCE_REPAIR},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_CARPENTER},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_PLASTERER},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_PAINTER},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_TILE_SETTER},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_CONSTRUCTOR},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_INTERIOR_DESIGNER},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_LANDSCAPE_ARCHITECT},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_CONTRACTOR},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_MOVING_HELPER},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_FURNITURE_ASSEMBLER},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_DOOR_LOCKS},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_SMART_HOME_SPECIALIST},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_HOME_THEATER_EXPERT},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_SOLAR_PANEL_SPECIALIST},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_ROOFER},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_WINDOW_INSTALLER},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_FLOORING_EXPERT},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_HVAC},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_INSULATION_EXPERT},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_POOL_SPECIALIST},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_SEPTIC_SYSTEM_EXPERT},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_MOLD_REMOVAL_SPECIALIST},
            { office: officeTypes.LIFE, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_HOUSE_PEST_CONTROL_SPECIALIST},
        ]
    },
    {
        office: officeTypes.CAR,
        consultants: [
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_MECHANIC},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_ELECTRICIAN},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_SECURITY},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_ENGINE_SPECIALIST},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_TRANSMISSION_SPECIALIST},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_BRAKE_SPECIALIST},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_TIRES_SPECIALIST},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_SUSPENSION_SPECIALIST},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_AIR_CONDITIONING_SPECIALIST},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_BODYWORK_SPECIALIST},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_PAINT_SPECIALIST},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_WINDSHIELD_REPAIR_SPECIALIST},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_AUDIO_SPECIALIST},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_DIAGNOSTICS_SPECIALIST},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_DETAILER},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_TUNING_SPECIALIST},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_EXHAUST_SYSTEM_SPECIALIST},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_CLUTCH_SPECIALIST},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_FUEL_SYSTEM_SPECIALIST},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_GLASS_TINTING_SPECIALIST},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_RADIATOR_SPECIALIST},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_BATTERY_SPECIALIST},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_ALIGNMENT_SPECIALIST},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_INTERIOR_REPAIR_SPECIALIST},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_LOCKSMITH},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_HYBRID_SPECIALIST},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_LAWYER},
            { office: officeTypes.CAR, gender: genderTypes.MALE, photo: defaultImage, key: chatModes.CHAT_CAR_SALES_SPECIALIST},
        ]
    },
    {
        office: officeTypes.RELIGION,
        consultants: [
            { office: officeTypes.RELIGION, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_RELIGION_SPIRITUAL_MENTOR},
            { office: officeTypes.RELIGION, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_RELIGION_SPIRITUAL_CARE},
            { office: officeTypes.RELIGION, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_RELIGION_RELIGIOUS_COUNSELOR},
            { office: officeTypes.RELIGION, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_RELIGION_INTERFAITH_CONSULTANT},
            { office: officeTypes.RELIGION, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_RELIGION_PRIEST},
            { office: officeTypes.RELIGION, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_RELIGION_CHAPLAIN},
            { office: officeTypes.RELIGION, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_RELIGION_IMAM},
            { office: officeTypes.RELIGION, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_RELIGION_RABBI},
            { office: officeTypes.RELIGION, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_RELIGION_BUDDHIST_MONK},
            { office: officeTypes.RELIGION, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_RELIGION_YOGA_TEACHER},
            { office: officeTypes.RELIGION, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_RELIGION_TANTRA_TEACHER},
            { office: officeTypes.RELIGION, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_RELIGION_VEDIC_PRIEST},
            { office: officeTypes.RELIGION, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_RELIGION_MEDITATION_GUIDE},
            { office: officeTypes.RELIGION, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_RELIGION_SCHOLAR_OF_RELIGIOUS_TEXTS},
            { office: officeTypes.RELIGION, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_RELIGION_THEOLOGIST},
            { office: officeTypes.RELIGION, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_RELIGION_ATHEIST_COUNSELOR},
            { office: officeTypes.RELIGION, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_RELIGION_PHILOSOPHER},
            { office: officeTypes.RELIGION, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_RELIGION_ETHICS_SPECIALIST},
        ]
    },
    {
        office: officeTypes.ESOTERIC,
        consultants: [
            { office: officeTypes.ESOTERIC, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_ESOTERIC_NUMEROLOGY},
            { office: officeTypes.ESOTERIC, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_ESOTERIC_BIORHYTHMS},
            { office: officeTypes.ESOTERIC, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_ESOTERIC_PALMISTRY},
            { office: officeTypes.ESOTERIC, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_ESOTERIC_AURA_READING},
            { office: officeTypes.ESOTERIC, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_ESOTERIC_CRYSTALS},
            { office: officeTypes.ESOTERIC, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_ESOTERIC_RUNES},
            { office: officeTypes.ESOTERIC, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_ESOTERIC_I_CHING},
            { office: officeTypes.ESOTERIC, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_ESOTERIC_PSYCHIC},
            { office: officeTypes.ESOTERIC, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_ESOTERIC_AKASHIC_RECORDS},
            { office: officeTypes.ESOTERIC, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_ESOTERIC_MEDIUM},
            { office: officeTypes.ESOTERIC, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_ESOTERIC_SHAMAN},
            { office: officeTypes.ESOTERIC, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_ESOTERIC_PENDULUM_DOWSING},
            { office: officeTypes.ESOTERIC, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_ESOTERIC_HERBALISM},
        ]
    },
    {
        office: officeTypes.TAROT,
        consultants: [
            { office: officeTypes.TAROT, gender: genderTypes.FEMALE, photo: tarot_classic, key: chatModes.CHAT_TAROT_TRADITIONAL},
            { office: officeTypes.TAROT, gender: genderTypes.FEMALE, photo: tarot_lenormand, key: chatModes.CHAT_TAROT_LENORMAND},
            { office: officeTypes.TAROT, gender: genderTypes.FEMALE, photo: tarot_oracle, key: chatModes.CHAT_TAROT_ORACLE},
            { office: officeTypes.TAROT, gender: genderTypes.FEMALE, photo: tarot_celtic, key: chatModes.CHAT_TAROT_CELTIC},
            { office: officeTypes.TAROT, gender: genderTypes.FEMALE, photo: tarot_numerological, key: chatModes.CHAT_TAROT_NUMERICAL},
            { office: officeTypes.TAROT, gender: genderTypes.FEMALE, photo: tarot_astrological, key: chatModes.CHAT_TAROT_ASTRO},
            { office: officeTypes.TAROT, gender: genderTypes.FEMALE, photo: tarot_therapeutic, key: chatModes.CHAT_TAROT_THERAPY},
            { office: officeTypes.TAROT, gender: genderTypes.FEMALE, photo: tarot_spiritual, key: chatModes.CHAT_TAROT_SPIRIT},
            { office: officeTypes.TAROT, gender: genderTypes.FEMALE, photo: tarot_intuitive, key: chatModes.CHAT_TAROT_INTUITIVE},
            { office: officeTypes.TAROT, gender: genderTypes.FEMALE, photo: tarot_psychic, key: chatModes.CHAT_TAROT_PSYCHIC},
        ]
    },
    {
        office: officeTypes.HOROSCOPE,
        consultants: [
            { office: officeTypes.HOROSCOPE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_HOROSCOPE},
            { office: officeTypes.HOROSCOPE, gender: genderTypes.FEMALE, photo: horoscope_zodiac, key: chatModes.CHAT_HOROSCOPE_REGULAR},
            { office: officeTypes.HOROSCOPE, gender: genderTypes.FEMALE, photo: horoscope_chinese, key: chatModes.CHAT_HOROSCOPE_EASTERN},
            { office: officeTypes.HOROSCOPE, gender: genderTypes.FEMALE, photo: horoscope_business, key: chatModes.CHAT_HOROSCOPE_BUSINESS},
            { office: officeTypes.HOROSCOPE, gender: genderTypes.FEMALE, photo: horoscope_compatibility, key: chatModes.CHAT_HOROSCOPE_COMPATIBILITY},
            { office: officeTypes.HOROSCOPE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_HOROSCOPE_LOVE},
            { office: officeTypes.HOROSCOPE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_HOROSCOPE_SEX},
            { office: officeTypes.HOROSCOPE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_HOROSCOPE_HEALTH},
            { office: officeTypes.HOROSCOPE, gender: genderTypes.FEMALE, photo: horoscope_lunar, key: chatModes.CHAT_HOROSCOPE_LUNAR},
            { office: officeTypes.HOROSCOPE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_HOROSCOPE_SOLAR},
            { office: officeTypes.HOROSCOPE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_HOROSCOPE_CELESTIAL},
            { office: officeTypes.HOROSCOPE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_HOROSCOPE_PSYCHOLOGICAL},
            { office: officeTypes.HOROSCOPE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_HOROSCOPE_DRACONIC},
            { office: officeTypes.HOROSCOPE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_HOROSCOPE_HOURLY},
            { office: officeTypes.HOROSCOPE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_HOROSCOPE_WEEKLY},
            { office: officeTypes.HOROSCOPE, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_HOROSCOPE_YEARLY},
        ]
    },
    {
        office: officeTypes.ASTROLOGY,
        consultants: [
            { office: officeTypes.ASTROLOGY, gender: genderTypes.FEMALE, photo: ASTROLOGY_natal_chart, key: chatModes.CHAT_ASTROLOGY_NATAL},
            { office: officeTypes.ASTROLOGY, gender: genderTypes.FEMALE, photo: ASTROLOGY_horary_chart, key: chatModes.CHAT_ASTROLOGY_HORO},
            { office: officeTypes.ASTROLOGY, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_ASTROLOGY_SYNASTRY},
            { office: officeTypes.ASTROLOGY, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_ASTROLOGY_SOLAR_RETURN},
            { office: officeTypes.ASTROLOGY, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_ASTROLOGY_LUNAR_CYCLE},
            { office: officeTypes.ASTROLOGY, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_ASTROLOGY_TRANSITS},
            { office: officeTypes.ASTROLOGY, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_ASTROLOGY_PROGRESSIONS},
            { office: officeTypes.ASTROLOGY, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_ASTROLOGY_KARMIC_ASTROLOGY},
            { office: officeTypes.ASTROLOGY, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_ASTROLOGY_MEDICAL_ASTROLOGY},
            { office: officeTypes.ASTROLOGY, gender: genderTypes.FEMALE, photo: defaultImage, key: chatModes.CHAT_ASTROLOGY_VEDIC},
        ]
    },
    {
        office: officeTypes.TALISMAN,
        consultants: [
            { office: officeTypes.TALISMAN, gender: genderTypes.FEMALE, photo: talisman_calpaca, key: chatModes.CHAT_TALISMAN_CALPACA},
            { office: officeTypes.TALISMAN, gender: genderTypes.FEMALE, photo: talisman_rosa, key: chatModes.CHAT_TALISMAN_ROSA},
            { office: officeTypes.TALISMAN, gender: genderTypes.FEMALE, photo: talisman_mandala, key: chatModes.CHAT_TALISMAN_MANDALA},
            { office: officeTypes.TALISMAN, gender: genderTypes.FEMALE, photo: talisman_baca, key: chatModes.CHAT_TALISMAN_BACA},
            { office: officeTypes.TALISMAN, gender: genderTypes.FEMALE, photo: talisman_munekas, key: chatModes.CHAT_TALISMAN_KUITA},
            { office: officeTypes.TALISMAN, gender: genderTypes.FEMALE, photo: talisman_trinkets, key: chatModes.CHAT_TALISMAN_TRINKETS},
        ]
    },
];

const localStorageKey = 'currentConsultant';

class ConsultantManager {

    constructor() {
        this.selectedConsultant = this.getConsultantByKey(localStorage.getItem(localStorageKey)) || consultants[0].consultants[0];
    }

    getConsultants(officeType) {
        //console.log('getConsultants', officeType);
        const office = consultants.find(c => c.office === officeType);
        return office ? office.consultants : consultants[0].consultants;
    }

    getConsultantByKey(key) {
        for (const office of consultants) {
            const consultant = office.consultants.find(c => c.key === key);
            if (consultant) {
                return consultant;
            }
        }
        return null; // or you could throw an error if preferred
    }

    setSelectedConsultant(consultant) {
        this.selectedConsultant = consultant;
        localStorage.setItem(localStorageKey, consultant.key);
    }


    getSelectedConsultant() {
        console.log('getSelectedConsultant', this.selectedConsultant);
        return this.selectedConsultant;
    }
}

export default ConsultantManager;